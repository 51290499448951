import React, {useState, useEffect, useRef} from "react";
import {
    Breadcrumb,
    Form,
    Input,
    Select,
    Button,
    DatePicker, Table, Pagination, Drawer, message
} from "antd";
import ReviewModel from "../../models/article/review";
import {decodeContent} from "../../untils/commHelper";
import Service from 'xgplayer-service';
import Player from 'xgplayer';
import { GetH5Host } from "../../conf/app";

const { RangePicker } = DatePicker;
const { Column } = Table;
const {Option}=Select


function Review(props){
    const formRef = React.createRef();
    const [form,setForm]=useState({});
    const [drawerVisible,setDrawerVisible]=useState(false);
    const [categorysVisible, setCategorysVisible] = useState(false);
    const [loading, setLoading] = useState(false);
    const [moments,setMoments]=useState();
    const [ visible, setVisible ] = useState(false);
    const [ content, setContent ] = useState({
        title: '',
        tiem: '',
        source: '',
    });
    // const role=useRef(localStorage.getItem("role"));

    const {
        list,
        page,
        article,
        totalCount,
        categorys,
        search,
        getArticle,
        mechanismList,
        getMechanismList,
        getPlayerToken,
    }=ReviewModel()

    async function searchList(page,val){
        if(moments){
            val.start_time=Math.floor(moments[0]._d.getTime()/1000);
            val.end_time=Math.floor(moments[1]._d.getTime()/1000);
        }
        setForm(val);
        setLoading(true);
        await search(page,val);
        setLoading(false)
    }

    function showDrawer(title, source, videoID, time) {
        setContent({ time, title, source });
        getPlayerToken(videoID).then((playToken) => {
          Service.url(playToken, '//vod.volcengineapi.com').then((res) => {
            console.log(res);
            let player = new Player({
                id: 'mse',
                url: res && Array.isArray(res.PlayInfoList) && res.PlayInfoList[0] && res.PlayInfoList[0].MainPlayUrl,
                poster: res.PosterUrl,
            });
          }, (err) => {});
        })
        setVisible(true);
      }
    
      function closeDrawer() {
        setVisible(false);
      }

    function copy(value) {
        let scrollTop=window.pageYOffset;
        let transfer = document.createElement('input');
        document.body.appendChild(transfer);
        transfer.value = value;  // 这里表示想要复制的内容
        transfer.focus();
        transfer.select();
        if (document.execCommand('copy')) {
            document.execCommand('copy');
            message.success("已成功复制文章链接至粘贴板")
        }else{
            message.error("复制失败，当前浏览器不支持")
        }
        transfer.blur();
        document.body.removeChild(transfer);
        window.scrollTo(0,scrollTop)

    }

    useEffect(()=>{
        document.title = "待审核资讯管理"
        getMechanismList()
        searchList(1,form);
    },[])

    return (
        <div>
            <Breadcrumb style={{ padding: "16px", background: "#F5F5F5" }}>
                <Breadcrumb.Item>资讯管理</Breadcrumb.Item>
                <Breadcrumb.Item>待审核</Breadcrumb.Item>
            </Breadcrumb>
            <div>
                <div style={{padding:"30px"}}>
                    <Form ref={formRef} layout="inline" onFinish={(val)=>{searchList(1,val)}}>
                        <Form.Item
                            label="资讯ID"
                            name="id"
                            style={{width: "240px",marginBottom:"10px"}}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            label="资讯标题"
                            name="title"
                            style={{width: "240px",marginBottom:"10px"}}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            style={{width:"240px",marginBottom:"10px"}}
                            label="资讯频道"
                            name="cate"
                        >
                            <Select
                                placeholder="请选择"
                                onChange={(e) => {
                                    if (e == "health_life") {
                                        setCategorysVisible(true);
                                    } else {
                                        setCategorysVisible(false);
                                    }
                                }}
                            >
                                {/* <Option value="all">全部</Option> */}
                                <Option value="health_talk">健康百科-健康大咖谈</Option>
                                <Option value="health_life">健康百科-健康科普</Option>
                                <Option value="local_dynamic">地方动态</Option>
                                <Option value="index_carousel">首页-头图</Option>
                                <Option value="official_information">权威信息-官方资讯</Option>
                                <Option value="china_article">重大行动</Option>
                            </Select>
                        </Form.Item>

                        {categorysVisible && (
                            <Form.Item style={{width: "240px",marginLeft: "-16px"}} name="category_two">
                                <Select
                                    placeholder="请选择文章分类"
                                    style={{width: "180px"}}
                                >
                                    {/*<Option key={"op_00001"} value="0">*/}
                                    {/*    全部*/}
                                    {/*</Option>*/}

                                    {categorys.map((citem, cindex) => {
                                        return (
                                            <Option key={"op_" + cindex} value={citem.value}>
                                                {citem.lable}
                                            </Option>
                                        );
                                    })}

                                    <Option key={"op_100"} value="100">
                                        其他
                                    </Option>
                                </Select>
                            </Form.Item>
                        )}
                        {/*<Form.Item*/}
                        {/*    style={{width:"240px",marginBottom:"10px"}}*/}
                        {/*    label="资讯分类"*/}
                        {/*    name="category_two"*/}
                        {/*>*/}
                        {/*    <Select>*/}

                        {/*    </Select>*/}
                        {/*</Form.Item>*/}
                        <Form.Item
                            style={{width:"240px",marginBottom:"10px"}}
                            label="所属机构"
                            name="mechanism_id"
                        >
                            <Select>
                                {
                                    mechanismList.map(item=>{
                                        return <Option key={item.id} value={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item
                            style={{marginBottom:"10px"}}
                            label="提交时间"
                            name="time"
                            value={moments}
                        >
                            <div>
                                <RangePicker onChange={(dates)=>{
                                    // console.log(dates);
                                    setMoments(dates);
                                }}/>
                            </div>
                        </Form.Item>
                        <Form.Item
                            style={{width:"240px",marginBottom:"10px"}}
                            label="提交人"
                            name="commit_user"
                        >
                            <Input />
                        </Form.Item>
                        <Form.Item
                              name="article_type"
                              label="资讯类型"
                              style={{width: "240px",marginBottom:"10px"}}
                            >
                                <Select placeholder="请选择">
                                    <Option value="0">全部</Option>
                                    <Option value="1">图文</Option>
                                    <Option value="2">文本</Option>
                                    {/* <Option value="3">文件</Option> */}
                                    <Option value="4">视频</Option>
                                </Select>
                            </Form.Item>
                        <Form.Item>
                            <div>
                                <Button type="primary" htmlType="submit">
                                    查询
                                </Button>
                            </div>
                        </Form.Item>
                        <Form.Item>
                            <div>
                                <Button onClick={()=>{
                                    setMoments(null);
                                    setCategorysVisible(false);
                                    formRef.current.resetFields();
                                }}>
                                    重置查询条件
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
                <div style={{paddingBottom:"30px"}}>
                    <Table dataSource={list} pagination={false} bordered={true} scroll={{x:1500,y:"50vh"}} loading={loading}>
                        <Column
                            align="center"
                            title="资讯ID"
                            dataIndex="id"
                            // fixed="left"
                        />
                        <Column
                            align="center"
                            title="资讯标题"
                            dataIndex="title"
                            // fixed="left"
                        />
                        <Column
                            align="center"
                            title="资讯类型"
                            dataIndex="article_type"
                            render={type=>{
                                switch (type){
                                    case 1:
                                        return <div>图文</div>
                                    case 2:
                                        return <div>文本</div>
                                    case 3:
                                        return <div>文件</div>
                                    case 4:
                                        return <div>视频</div>
                                    default:
                                        return <div>--</div>
                                }
                            }}
                        />
                        <Column
                            align="center"
                            title="资讯频道"
                            width={220}
                            dataIndex="cate"
                            render={(cate)=>{
                                switch(cate){
                                    case "health_talk":
                                        return <div>健康百科—健康大咖谈</div>
                                    case "health_life":
                                        return <div>健康百科—健康科普</div>
                                    case "local_dynamic":
                                        return <div>地方动态</div>
                                    case "index_carousel":
                                        return <div>首页-头图</div>
                                    case "official_information":
                                        return <div>权威信息-官方资讯</div>
                                    case "china_article":
                                        return <div>重大行动</div>
                                    default:
                                        return <div>--</div>
                                }
                            }}
                        />
                        <Column
                            align="center"
                            title="资讯分类"
                            dataIndex="category_two"
                            render={(cate_two)=>{
                                if(cate_two===0){
                                    return <div>--</div>
                                }else{
                                    let text="";
                                    categorys.forEach(item=>{
                                        if(cate_two==parseInt(item.value)){
                                            text=item.lable;
                                        }
                                    })
                                    return <div>{text}</div>
                                }
                            }}
                        />
                        <Column
                            align="center"
                            title="所属机构"
                            dataIndex="mechanism_name"
                        />
                        <Column
                            align="center"
                            title="提交人"
                            dataIndex="commit_user_name"
                        />
                        <Column
                            align="center"
                            title="提交时间"
                            dataIndex="check_status_time"
                        />
                        <Column
                            align="center"
                            title="操作"
                            fixed="right"
                            width={250}
                            render={(index,record)=>{
                                return(
                                    <div style={{color:"rgb(135,199,241)"}}>
                                        <span style={{marginRight:"15px",cursor:"pointer"}} onClick={()=>{
                                            if (record.cate === 'health_talk') {
                                                props.history.push(`/main/add/video/${record.id}/check`);
                                            } else {
                                                props.history.push(`/main/check/${record.id}`)
                                            }
                                        }}>审核</span>
                                        <span style={{marginRight:"15px",cursor:"pointer"}} onClick={()=>{
                                            if (record.cate === 'health_talk') {
                                                showDrawer(record.title, record.article_source, record.video_id, record.check_status_time);
                                            } else {
                                                getArticle(record.id)
                                                setDrawerVisible(true)
                                            }
                                        }}>预览</span>
                                        <span style={{cursor:"pointer"}} onClick={()=>{
                                            let timestamp=parseInt(new Date().getTime()/1000);
                                            let timeBase64=btoa(encodeURI(timestamp.toString()))
                                            const ShareHost = GetH5Host();
                                            const href = `${ShareHost}/preview/${record.id}?sign=${timeBase64}`
                                            copy(href);
                                        }}>分享资讯</span>
                                    </div>
                                )
                            }}
                        />
                    </Table>
                    <Pagination
                        pageSizeOptions={["10"]}
                        pageSize={10}
                        defaultCurrent={1}
                        current={page}
                        total={totalCount}
                        showTotal={total => `共 ${total} 条`}
                        style={{textAlign:"right",paddingRight:"30px",marginTop:"20px"}}
                        onChange={(e) => {
                            searchList(e, form);
                        }}
                    />
                </div>
            </div>
            <Drawer
                destroyOnClose={true}
                key={"hhhss"}
                title={
                    <span>
              <b>文章预览</b>
            </span>
                }
                width={1080}
                onClose={() => {
                    setDrawerVisible(false);
                }}
                visible={drawerVisible}
            >
                <div className="preview-box" style={{padding: "16px 40px"}}>
                    <div
                        className="preview-title"
                        style={{
                            fontWeight: "700",
                            fontSize: "20px",
                            padding: "16px 0",
                            textAlign: "center",
                        }}
                    >
                        {article?article.title:""}
                    </div>
                    <div
                        style={{marginTop: "16px"}}
                        className="preview-content"
                        dangerouslySetInnerHTML={{
                            __html: decodeContent(article?article.content:""),
                        }}
                    >

                    </div>
                </div>
            </Drawer>
            <Drawer
                destroyOnClose={true}
                title={
                <span>
                    <b>文章预览</b>
                </span>
                }
                width={1080}
                onClose={closeDrawer}
                visible={visible}
            >
                <div className="preview-box" style={{padding: "16px 40px"}}>
                <div id="mse"></div>
                <p className="video-title">{content.title}</p>
                <p className="video-content">{content.source}</p>
                <p className="video-content">{content.time}</p>
                </div>
            </Drawer>
        </div>
    )
}

export default Review