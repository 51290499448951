import React, { useEffect, useState } from "react";
import { SylEditor } from '@syllepsis/access-react';
import { SylEditorConfig, SylEventChannel } from "../../untils/SylEditorConfig";

function Index() {
  return (
    <div>
      <SylEditor
        placeholder="请输入内容"
        getEditor={(editor) => {
          editor.on(SylEventChannel.LocalEvent.TEXT_CHANGE, () => {
            console.log("内容发生变化，可通过调用adapter.getHTML()获取内容");
          });
        }}
        plugins={SylEditorConfig.Plugins}
        module={SylEditorConfig.Modules}
      />
    </div>
  );
}

export default Index;
