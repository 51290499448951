import React, { useState } from "react";
import { createModel } from "hox";
import { reqestApi } from "../../untils/requestHelper";
import { message } from "antd";

function useReview(){
    const [page, setPage] = useState(1);
    const [list, setList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [article,setArticle]=useState();
    const [mechanismList,setMechanismList]=useState([]);

    const getMechanismList=async ()=>{
        let ret=await reqestApi("/api/mis/mechanism/list");
        if(ret.data.code===0){
            setMechanismList(ret.data.data);
        }
    }

    const categorys = [
        { lable: "健康知识普及行动", value: "1" },
        { lable: "合理膳食行动", value: "2" },
        { lable: "全民健身行动", value: "3" },
        { lable: "控烟行动", value: "4" },
        { lable: "心理健康促进行动", value: "5" },
        { lable: "健康环境促进行动", value: "6" },

        { lable: "妇幼健康促进行动", value: "7" },
        { lable: "中小学健康促进行动", value: "8" },
        { lable: "职业健康保护行动", value: "9" },
        { lable: "老年健康促进行动", value: "10" },

        { lable: "心脑血管疾病防治行动", value: "11" },
        { lable: "癌症防治行动", value: "12" },
        { lable: "慢性呼吸系统疾病防治行动", value: "13" },
        { lable: "糖尿病防治行动", value: "14" },
        { lable: "传染病及地方病防控行动", value: "15" },
    ];

    //文章审核列表搜索
    const search = async (page, searchParams) => {
        console.log(searchParams.time)
        let params = {};
        params.page = page;
        for(let key in searchParams){
            if(searchParams[key]||searchParams[key]===0){
                params[key]=searchParams[key];
            }
        }

        let ret = await reqestApi("/api/mis/article/checklist", "GET", params);
        if (ret.data.code === 0) {
            setList(ret.data.data);
            setTotalCount(ret.data.totalCount);
            setPage(page);
        }
    };

    //文章预览
    const getArticle=async (id)=>{
        let ret=await reqestApi(`/api/mis/article/detail/${id}`,"GET");
        if(ret.data.code===0){
            setArticle(ret.data.data);
        }else{
            message.error("获取文章失败")
        }
    }

    const getPlayerToken = async (vid) => {
        const res = await reqestApi(`/api/mis/video/play_token?vid=${vid}`, 'GET');
        if (res.status === 200 && res.data) {
            const { data } = res.data;
            const { token } = data || {};
            return token;
        }
      }

    return{
        list,
        page,
        setPage,
        totalCount,
        article,
        search,
        getArticle,
        categorys,
        mechanismList,
        getMechanismList,
        getPlayerToken,
    }
}

export default createModel(useReview);