import React, {useEffect, useState} from "react"
import {findCityName, getProvinces} from "../../untils/citydata";
import {
    Row,
    Col,
    Table,
    Button,
    Space,
    Spin,
    Pagination,
    Breadcrumb,
    Drawer,
    Modal,
    Tag,
    Form,
    Input,
    Popover,
    Radio,
    Select, DatePicker, message
} from "antd";

import ListModel from "../../models/platform/list"
import {ExclamationCircleOutlined} from "@ant-design/icons";

const {Column} = Table;
const {confirm} = Modal;
const {Option} = Select;
const {RangePicker} = DatePicker;

function HealthPlatform(props) {
    const mode = {
        NEW: 0,
        EDIT: 1,
        CHECK: 2,
        VIEW: 3
    }

    const [moments, setMoments] = useState();
    const [check_status, setStatus] = useState(0);
    const [params, setParams] = useState({});
    const formRef = React.createRef();

    const {
        list,
        page,
        totalCount,
        search,
        offline,
        commit,
        deleteCase,
        getInfo,
        fixedWidth,
        loading
    } = ListModel();

    useEffect(() => {
        document.title = "创新案例管理"
        search(1, params, check_status);
    }, [])

    function copy(value) {
        let scrollTop = window.pageYOffset;
        let transfer = document.createElement('input');
        document.body.appendChild(transfer);
        transfer.value = value;  // 这里表示想要复制的内容
        transfer.focus();
        transfer.select();
        if (document.execCommand('copy')) {
            document.execCommand('copy');
            message.success("已成功复制文章链接至粘贴板")
        } else {
            message.error("复制失败，当前浏览器不支持")
        }
        transfer.blur();
        document.body.removeChild(transfer);
        window.scrollTo(0, scrollTop)

    }

    return (
        <div>
            <Breadcrumb style={{padding: "16px", background: "#F5F5F5"}}>
                <Breadcrumb.Item>创新案例管理</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{padding: "32px 16px 8px 16px"}}>
                <Form
                    ref={formRef}
                    layout="inline"
                    onFinish={(val, status) => {
                        // console.log(val);
                        if (moments) {
                            val.start_time = Math.floor(moments[0]._d.getTime() / 1000);
                            val.end_time = Math.floor(moments[1]._d.getTime() / 1000);
                        }
                        setParams(val);
                        search(1, val, status ? status : check_status);
                    }}
                >
                    <Form.Item name="title" label="标题" style={{width: "240px", marginBottom: "10px"}}>
                        <Input autocomplete="off"/>
                    </Form.Item>
                    <Form.Item name="province_id" label="地方" style={{width: "240px", marginBottom: "10px"}}>
                        <Select>
                            <Option value={0}>全部</Option>
                            {
                                getProvinces().map(item => {
                                    return <Option key={item.value} value={item.value}>{item.label}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="commit_user" label="提交人" style={{width: "240px", marginBottom: "10px"}}>
                        <Input autocomplete="off"/>
                    </Form.Item>
                    <Form.Item
                        style={{marginBottom: "10px"}}
                        label="提交时间"
                        name="time"
                        value={moments}
                    >
                        <div>
                            <RangePicker onChange={(dates) => {
                                // console.log(dates);
                                setMoments(dates);
                            }}/>
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" type="primary">
                            查找
                        </Button>
                    </Form.Item>
                </Form>
                <div style={{marginTop: "20px"}}>
                    <Radio.Group value={check_status} onChange={(e) => {
                        setStatus(e.target.value);
                        formRef.current.submit(e.target.value)
                    }}>
                        <Radio.Button value={0}>全部</Radio.Button>
                        <Radio.Button value={9}>草稿</Radio.Button>
                        <Radio.Button value={1}>待审核</Radio.Button>
                        <Radio.Button value={3}>驳回</Radio.Button>
                        <Radio.Button value={4}>上线</Radio.Button>
                    </Radio.Group>
                    <Button
                        style={{marginLeft: "40px"}}
                        type="primary"
                        onClick={() => {
                            props.history.push({
                                pathname: "/main/case",
                                state: {mode: mode.NEW, caseInfo: JSON.stringify({})}
                            })
                        }}
                    >
                        新建
                    </Button>
                </div>
            </div>
            <div style={{marginTop: "30px"}}>
                <Table
                    dataSource={list}
                    scroll={{x: 1230 + fixedWidth, y: "50vh"}}
                    pagination={false}
                    bordered={true}
                    loading={loading}
                >
                    <Column
                        align="center"
                        title="序号"
                        dataIndex="index"
                        width="80px"
                        // fixed="left"
                    />
                    <Column
                        align="center"
                        title="标题"
                        dataIndex="title"
                        width="280px"
                        // fixed="left"
                    />
                    <Column
                        align="center"
                        title="案例分类"
                        dataIndex="exchange_cate"
                        width="180px"
                        render={(id) => {
                            switch (id) {
                                case 2:
                                    return <p>十五项行动</p>
                                case 3:
                                    return <p>综合</p>
                                default:
                                    return <p>--</p>
                            }
                        }}
                        // fixed="left"
                    />
                    <Column
                        align="center"
                        title="地方"
                        dataIndex="province_id"
                        width="180px"
                        render={(id) => {
                            return findCityName(id);
                        }}
                        // fixed="left"
                    />
                    <Column
                        align="center"
                        title="提交人"
                        dataIndex="commit_user_name"
                        width="180px"
                        // fixed="left"
                    />
                    <Column
                        align="center"
                        title="审核人"
                        dataIndex="check_user_name"
                        width="180px"
                        render={(name) => {
                            return name ? <p>{name}</p> : <p>--</p>
                        }}
                        // fixed="left"
                    />
                    <Column
                        align="center"
                        title="提交时间"
                        dataIndex="check_status_time"
                        width="180px"
                        render={(time, record) => {
                            return record.check_status === 1 ? <div>{time}</div> : <div>--</div>
                        }}
                        // fixed="left"
                    />
                    <Column
                        align="center"
                        title="状态"
                        dataIndex="check_status"
                        width="180px"
                        render={(status) => {
                            switch (status) {
                                case 9:
                                    return <p>草稿</p>
                                case 1:
                                    return <p>待审核</p>
                                case 2:
                                    return <p>审核通过</p>
                                case 3:
                                    return <p>驳回</p>
                                case 4:
                                    return <p>上线</p>
                                case 5:
                                    return <p>删除</p>
                                default:
                                    return <p>--</p>
                            }
                        }}
                        // fixed="left"
                    />
                    <Column
                        align="center"
                        title="操作"
                        dataIndex="mode"
                        width={fixedWidth}
                        fixed="right"
                        render={(item, record) => {
                            return (
                                <div
                                    style={{width: "100%", display: "flex", justifyContent: "space-around"}}
                                >
                                    {record.optionList.indexOf("编辑") > -1 && <Button
                                        onClick={async () => {
                                            const caseInfo = await getInfo(record.id);
                                            if (caseInfo) {
                                                props.history.push({
                                                    pathname: "/main/case",
                                                    state: {mode: mode.EDIT, caseInfo: JSON.stringify(caseInfo)}
                                                })
                                            }
                                        }}
                                    >
                                        编辑
                                    </Button>}
                                    {record.optionList.indexOf("查看") > -1 && <Button
                                        onClick={async () => {
                                            const caseInfo = await getInfo(record.id);
                                            props.history.push({
                                                pathname: "/main/case",
                                                state: {mode: mode.VIEW, caseInfo: JSON.stringify(caseInfo)}
                                            })
                                        }}
                                    >
                                        查看
                                    </Button>}
                                    {record.optionList.indexOf("审核") > -1 && <Button
                                        onClick={async () => {
                                            const caseInfo = await getInfo(record.id);
                                            props.history.push({
                                                pathname: "/main/case",
                                                state: {mode: mode.CHECK, caseInfo: JSON.stringify(caseInfo)}
                                            })
                                        }}
                                    >
                                        审核
                                    </Button>}
                                    {record.optionList.indexOf("删除") > -1 && <Button
                                        onClick={() => {
                                            confirm({
                                                title: "提示",
                                                icon: <ExclamationCircleOutlined/>,
                                                content: "确定要删除该案例么？",
                                                async onOk() {
                                                    if (await deleteCase(record.id)) {
                                                        search(page, params, check_status);
                                                    } else {
                                                        message.error("删除失败");
                                                    }
                                                },
                                                onCancel() {
                                                    console.log("Cancel");
                                                },
                                            });
                                        }}
                                    >
                                        删除
                                    </Button>}
                                    {record.optionList.indexOf("下线") > -1 && <Button
                                        onClick={() => {
                                            confirm({
                                                title: "提示",
                                                icon: <ExclamationCircleOutlined/>,
                                                content: "确定要下线该案例么？",
                                                async onOk() {
                                                    if (await offline(record.id)) {
                                                        search(page, params, check_status);
                                                    } else {
                                                        message.error("下线失败");
                                                    }
                                                },
                                                onCancel() {
                                                    console.log("Cancel");
                                                },
                                            });
                                        }}
                                    >
                                        下线
                                    </Button>}
                                    {record.optionList.indexOf("提交") > -1 && <Button
                                        onClick={() => {
                                            confirm({
                                                title: "提示",
                                                icon: <ExclamationCircleOutlined/>,
                                                content: "确定要提交该案例么？",
                                                async onOk() {
                                                    if (await commit(record.id)) {
                                                        search(page, params, check_status);
                                                    } else {
                                                        message.error("提交失败");
                                                    }
                                                },
                                                onCancel() {
                                                    console.log("Cancel");
                                                },
                                            });
                                        }}
                                    >
                                        提交
                                    </Button>}
                                    <Button
                                        onClick={() => {
                                            let timestamp = parseInt(new Date().getTime() / 1000);
                                            let timeBase64 = btoa(encodeURI(timestamp.toString()));
                                            let href = `https://www.jkzgxd.cn/preview/${record.id}?sign=${timeBase64}`
                                            copy(href);
                                        }}
                                    >
                                        分享案例
                                    </Button>
                                </div>
                            )
                        }}
                        // fixed="left"
                    />
                </Table>
                {totalCount > 0 && (
                    <Row>
                        <Col xs={0} ms={24} md={24} lg={24} xl={24} xxl={24}>
                            <div className="tab-pages">
                                <Pagination
                                    pageSizeOptions={["10"]}
                                    pageSize={10}
                                    defaultCurrent={1}
                                    current={page}
                                    total={totalCount}
                                    onChange={(e) => {
                                        // setPage(e);
                                        search(e, params, check_status);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    )
}

export default HealthPlatform;