import React, { useState } from "react";
import { createModel } from "hox";
import { reqestApi } from "../../untils/requestHelper";
import { message } from "antd";

function useList() {
  const [page, setPage] = useState([]);
  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [fixedWidth, setFixedWidth] = useState(0);
  const [loading, setLoading] = useState(false);
  const [article, setArticle] = useState({
    id: 0,
    title: "",
    cate: "",
    img_url: null,
    abstract: null,
    article_type: 2,
    key_words: [],
    article_source: "",
    author: null,
    public_date: "",
    content: "",
    category_one: null,
    category_two: null,
  });
  const [mechanismList,setMechanismList]=useState([]);

  const getMechanismList=async ()=>{
    let ret=await reqestApi("/api/mis/mechanism/list");
    if(ret.data.code===0){
      setMechanismList(ret.data.data);
    }
  }

  function getOptions(articleStatus){
    let role=localStorage.getItem("role");
    if(role==2){
      switch (articleStatus){
        case 9:
          return ["编辑","预览","删除","提交"];
        case 3:
          return ["编辑","预览","删除","提交"];
        default:
          return ["预览"];
      }
    }else{
      switch (articleStatus){
        case 1:
          return ["预览","审核","删除"];
        case 4:
          return ["预览","下线","删除"];
        default:
          return ["编辑","提交","预览","删除"];
      }
    }
  }

  const categorys = [
    { lable: "健康知识普及行动", value: "1" },
    { lable: "合理膳食行动", value: "2" },
    { lable: "全民健身行动", value: "3" },
    { lable: "控烟行动", value: "4" },
    { lable: "心理健康促进行动", value: "5" },
    { lable: "健康环境促进行动", value: "6" },

    { lable: "妇幼健康促进行动", value: "7" },
    { lable: "中小学健康促进行动", value: "8" },
    { lable: "职业健康保护行动", value: "9" },
    { lable: "老年健康促进行动", value: "10" },

    { lable: "心脑血管疾病防治行动", value: "11" },
    { lable: "癌症防治行动", value: "12" },
    { lable: "慢性呼吸系统疾病防治行动", value: "13" },
    { lable: "糖尿病防治行动", value: "14" },
    { lable: "传染病及地方病防控行动", value: "15" },
  ];

  const getArticle = async (id) => {
    let tmpUrl = "/api/mis/article/detail/" + id;
    let ret = await reqestApi(tmpUrl, "GET", {});
    if (ret.data.code == 0) {
      let mode = ret.data.data;
      console.log("MODE:", mode);
      setArticle(ret.data.data);
    }
    //console.log("RET:", ret)
  };

  const search = async (page, searchParams) => {
    let params = {};
    params.page = page;
    for(let key in searchParams){
      if(searchParams[key]){
        params[key]=searchParams[key]
      }
    }

    localStorage.setItem("paramstr", JSON.stringify(params));
    setLoading(true);
    let ret = await reqestApi("/api/mis/article/search", "GET", params);
    setLoading(false);
    if (ret.data.code == 0) {
      let arr = ret.data.data.map((item) => {
        let newItem = item;
        newItem.key = item.id;
        newItem.mode = item;
        newItem.optionList = getOptions(item.check_status)
        return newItem;
      });
      let maxWidth = 0;
      arr.forEach(item => {
        maxWidth = item.optionList.length>maxWidth?item.optionList.length:maxWidth;
      })
      setFixedWidth(maxWidth*64+144)
      setList(ret.data.data);
      setTotalCount(ret.data.totalCount);
      setPage(page);
    }
    console.log("MIS后台：", ret);
  };
  const onDelete = async (id, page) => {
    let tmpUrl = "/api/mis/article/delete/" + id;
    let ret = await reqestApi(tmpUrl, "GET", {});
    if (ret.data.code == 0) {
      message.success("操作成功！", 1.5, () => {
        search(page);
      });
    }
  };

  const offline = async (id, page) => {
    let tmpUrl = "/api/mis/article/downline/" + id;
    let ret = await reqestApi(tmpUrl, "GET", {});
    if (ret.data.code == 0) {
      message.success("操作成功！", 1.5, () => {
        search(page);
      });
    }
  };

  const online = async (id, page) => {
    let tmpUrl = "/api/mis/article/online/" + id;
    let ret = await reqestApi(tmpUrl, "GET", {});
    if (ret.data.code == 0) {
      message.success("操作成功！", 1.5, () => {
        search(page);
      });
    }
  };

  const commit = async (id, page) => {
    let tmpUrl = "/api/mis/article/commitcheck/" + id;
    let ret = await reqestApi(tmpUrl, "GET", {});
    if (ret.data.code == 0) {
      message.success("操作成功！", 1.5, () => {
        search(page);
      });
    }
  };

  const getPlayerToken = async (vid) => {
    const res = await reqestApi(`/api/mis/video/play_token?vid=${vid}`, 'GET');
    if (res.status === 200 && res.data) {
        const { data } = res.data;
        const { token } = data || {};
        return token;
    }
  }

  return {
    article,
    list,
    totalCount,
    categorys,
    page,
    search,
    getArticle,
    onDelete,
    online,
    commit,
    offline,
    fixedWidth,
    mechanismList,
    getMechanismList,
    loading,
    getPlayerToken,
  };
}

export default createModel(useList);
