import React, {useState, useEffect, useRef} from "react";
import {
    Form,
    Input,
    Select,
    Button,
    Space,
    Upload,
    Breadcrumb,
    message,
    Drawer,
    DatePicker,
    Tag,
    Modal, Radio,
    Checkbox
} from "antd";
import {PlusCircleFilled, PlusCircleOutlined} from '@ant-design/icons';

import {getProvinces} from "../../untils/citydata";
import './detail.css'
import {GetUploadUrl} from "../../conf/app";
import {getToken} from "../../untils/tokenHelper";
import modal from "../../models/hospital/detail"

const {Option} = Select
const {TextArea} = Input
const {Search} = Input

function HospitalCheck(props) {
    const mode = {
        CHECK: 2,
        VIEW: 3
    }
    const currentMode = props.location.state.mode;

    const infoForm = useRef();
    const peopleForm = useRef();

    const infoData = ["hospital_name", "hospital_rank", "province", "qualification", "core_subject_num", "departments", "hospital_info"];
    const peopleData = ["check_status"];

    const [detail] = useState(JSON.parse(props.location.state.info))
    const [hospitalNum, setHospitalNum] = useState(detail.hospital_addr.split(',').length === 1 ? 1 : 2);
    const [hospitalData, changeHospitalData] = useState(() => {
        if(hospitalNum === 1){
            return [{id: 1, content: ""}, {id: 2, content: ""}]
        }else{
            const res = detail.hospital_addr.split(',').map((item, index) => {
                return {
                    id: index+1,
                    content: item,
                }
            })
            return res
        }
    })
    const [logoUrl, setLogoUrl] = useState("");
    const [logoList, setLogoList] = useState([])
    const [qrList, setQrList] = useState([]);
    const [qrUrl, setQrUrl] = useState("");
    const [capability, setCapability] = useState({
        cape1: detail.capability.split(',')[0],
        cape2: detail.capability.split(',')[1],
        cape3: detail.capability.split(',')[2],
    })
    const [checkRes, setCheckRes] = useState("");
    const [isChecked, setIsChecked] = useState(true);

    const {
        commit,
        save,
    } = modal()

    const formItemLayout = {
        labelCol: {span: 7},
        wrapperCol: {span: 17},
    };

    const domEvents = {
        submit: async () => {
            const params = getParams(false)
            if(params){
                if(await commit(detail.id, params)){
                    props.history.push("/main/internethospital");
                }
            }
        },
        save: () => {
            const params = getParams(true);
            if(params){
                save(detail.id, params);
            }
        }
    }

    function getParams(ifSave) {
        if(!isChecked) {
            message.warn("国家临床重点专科数必须为整数");
            return;
        }
        const resParams = {};
        const infoRes = infoForm.current.getFieldsValue(infoData);
        for (let key in infoRes) {
            if (infoRes[key] === "") {
                message.warn("表单不能留空");
                return;
            } else {
                resParams[key] = infoRes[key];
            }
        }
        if(!ifSave){
            const peopleRes = peopleForm.current.getFieldsValue(peopleData);
            for (let key in peopleRes) {
                if (!peopleRes[key]) {
                    message.warn("表单不能留空");
                    return;
                } else {
                    resParams[key] = peopleRes[key];
                }
            }
            const fail_reason = peopleForm.current.getFieldValue("fail_reason");
            if(!fail_reason && resParams.check_status === 3){
                message.warn("表单不能留空");
                return;
            }else{
                resParams.fail_reason = fail_reason;
            }
        }
        if(logoUrl){
            resParams.logo = logoUrl;
        } else {
            message.warn("表单不能留空");
            return;
        }
        if(qrUrl){
            resParams.qrcode_img = qrUrl;
        } else {
            message.warn("表单不能留空");
            return;
        }
        const capabilityList = [];
        if(capability.cape1){
            capabilityList.push(capability.cape1)
        }
        if(capability.cape2){
            capabilityList.push(capability.cape2)
        }
        if(capability.cape3){
            capabilityList.push(capability.cape3)
        }
        if(capabilityList.length){
            resParams.capability = capabilityList.join(",");
        }else{
            message.warn("表单不能留空");
            return;
        }
        if(hospitalNum === 1){
            //单院区
            const address = infoForm.current.getFieldValue("hospital_addr")
            if(address){
                resParams.hospital_addr = address;
            }else{
                message.warn("表单不能留空");
                return;
            }
        }else{
            //多院区
            const address = [];
            hospitalData.forEach(item => {
                if(item.content){
                    address.push(item.content)
                }
            })
            if(address.length){
                resParams.hospital_addr = address.join(",");
            }else{
                message.warn("表单不能留空");
                return;
            }
        }
        return resParams;
    }

    useEffect(() => {
        setLogoUrl(detail.logo);
        setLogoList([
            {
                uid: "-1",
                name: "image",
                status: "done",
                url: detail.logo,
            },
        ]);
        setQrUrl(detail.qrcode_img);
        setQrList([
            {
                uid: "-1",
                name: "image",
                status: "done",
                url: detail.qrcode_img,
            },
        ]);
    }, [])

    return (
        <div>
            <Breadcrumb style={{padding: "16px", background: "#F5F5F5"}}>
                <Breadcrumb.Item>医院黄页管理</Breadcrumb.Item>
                <Breadcrumb.Item>入驻医院审核</Breadcrumb.Item>
            </Breadcrumb>
            <div className="hospital-check">
                <div style={{marginBottom: 16}}>
                    <div className="title">
                        申请医院信息
                    </div>
                    <Form
                        ref={infoForm}
                        {...formItemLayout}
                        onFinish={""}
                    >
                        <Form.Item
                            label="医院名称"
                            name="hospital_name"
                            initialValue={detail.hospital_name}
                        >
                            <Input
                                autocomplete="off"
                                placeholder="请输入医院名称"
                                disabled={currentMode === mode.VIEW}
                            />
                        </Form.Item>
                        <Form.Item
                            name="hospital_rank"
                            label="医院等级"
                            initialValue={detail.hospital_rank}
                        >
                            <Select
                                placeholder="请选择医院等级"
                                disabled={currentMode === mode.VIEW}
                            >
                                <Option value={2}>
                                    三甲医院
                                </Option>
                                <Option value={3}>
                                    三级医院
                                </Option>
                                <Option value={4}>
                                    二甲医院
                                </Option>
                                <Option value={1}>
                                    其他医院
                                </Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="province"
                            label="所在地区"
                            initialValue={detail.province}
                        >
                            <Select
                                placeholder="请选择所在地区"
                                disabled={currentMode === mode.VIEW}
                            >
                                {getProvinces().map((litem) => {
                                    return (
                                        <Option value={litem.value} key={litem.value}>
                                            {litem.label}
                                        </Option>
                                    );
                                })}
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="qualification"
                            label="资质"
                            initialValue={detail.qualification}
                        >
                            <Select
                                placeholder="请选择医院资质"
                                disabled={currentMode === mode.VIEW}
                            >
                                <Option value={1}>
                                    互联网医院
                                </Option>
                                <Option value={2}>
                                    互联网诊疗
                                </Option>
                                <Option value={0}>
                                    无
                                </Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            name="core_subject_num"
                            label="国家临床重点专科数"
                            initialValue={detail.core_subject_num}
                        >
                            <Input onBlur={(e) => {
                                if(!Number(e.target.value.trim()) && Number(e.target.value.trim()) !== 0){
                                    setIsChecked(false);
                                    message.warn("国家临床重点专科数必须为整数");
                                }else{
                                    setIsChecked(true);
                                }
                            }} disabled={currentMode === mode.VIEW} placeholder="请输入国家临床重点专科数" autocomplete="off"/>
                        </Form.Item>
                        <Form.Item
                            name="departments"
                            label="特色科室"
                            initialValue={detail.departments}
                        >
                            <Input disabled={currentMode === mode.VIEW} placeholder="请输入特色科室" autocomplete="off"/>
                        </Form.Item>
                        <Form.Item
                            label="互联网医院特色标签"
                        >
                            <div className="label-box">
                                <Input maxLength={10} disabled={currentMode === mode.VIEW} value={capability.cape1} onChange={(e) => {
                                    setCapability({
                                        ...capability,
                                        cape1: e.target.value
                                    })
                                }} className="label-input" placeholder="简介1（10字以内）" autocomplete="off"/>
                                <Input maxLength={10}  disabled={currentMode === mode.VIEW} value={capability.cape2} onChange={(e) => {
                                    setCapability({
                                        ...capability,
                                        cape2: e.target.value
                                    })
                                }} className="label-input" placeholder="简介2（10字以内）" autocomplete="off"/>
                                <Input maxLength={10}  disabled={currentMode === mode.VIEW} value={capability.cape3} onChange={(e) => {
                                    setCapability({
                                        ...capability,
                                        cape3: e.target.value
                                    })
                                }} className="label-input" placeholder="简介3（10字以内）" autocomplete="off"/>
                            </div>
                        </Form.Item>
                        <Form.Item
                            name="hospital_info"
                            label="医院简介"
                            initialValue={detail.hospital_info}
                        >
                            <TextArea disabled={currentMode === mode.VIEW} rows={4} placeholder="请输入医院简介，1000字以内" maxLength={1000}/>
                        </Form.Item>
                        <Form.Item
                            label="院区数量"
                        >
                            <Radio.Group disabled={currentMode === mode.VIEW} value={hospitalNum} onChange={(e) => {
                                setHospitalNum(e.target.value)
                            }}>
                                <Radio value={1}>单院区</Radio>
                                <Radio value={2}>多院区</Radio>
                            </Radio.Group>
                        </Form.Item>
                        {hospitalNum === 1 ? <Form.Item
                                name="hospital_addr"
                                label="医院地址"
                                initialValue={detail.hospital_addr.split(",").length > 1 ? "" : detail.hospital_addr}
                            >
                                <Input disabled={currentMode === mode.VIEW} placeholder="请输入医院地址" autocomplete="off"/>
                            </Form.Item> :
                            <div>
                                {
                                    hospitalData.map((item, index) => {
                                        return (item ? <Form.Item
                                            name={index}
                                            key={item.id}
                                            label={`院区${hospitalData.filter(item => item !== "").map(item => item.id).indexOf(item.id) + 1}名称`}
                                            initialValue={item.content}
                                        >
                                            <Search disabled={currentMode === mode.VIEW} value={item.content} onChange={(e) => {
                                                const newList = [...hospitalData];
                                                newList.forEach((newItem, newIndex) => {
                                                    if (newIndex === index) {
                                                        newItem.content = e.target.value;
                                                    }
                                                })
                                                changeHospitalData(newList)
                                            }} enterButton="删除" placeholder="请输入院区名称" onSearch={() => {
                                                const newList = [...hospitalData];
                                                newList.splice(index, 1, "");
                                                if (newList.filter(item => item !== "").length < 2) {
                                                    message.error("多院区院区数量不得少于2")
                                                } else {
                                                    changeHospitalData(newList);
                                                }
                                            }} autocomplete="off"/>
                                        </Form.Item> : <div></div>)
                                    })
                                }
                                <div style={{display: currentMode === mode.VIEW ? "none" : "block"}} className="add-more-btn" onClick={() => {
                                    const newList = [...hospitalData];
                                    newList.push({
                                        id: newList.length + 1
                                    })
                                    changeHospitalData(newList)
                                }}>
                                    <PlusCircleFilled style={{marginRight: 6}}/>
                                    添加新院区
                                </div>
                            </div>
                        }
                        <Form.Item
                            label="医院logo"
                        >
                            <div style={{position: "relative"}}>

                            </div>
                            <Upload
                                name="file"
                                disabled={currentMode === mode.VIEW}
                                listType="picture-card"
                                className="avatar-uploader"
                                fileList={logoList}
                                headers={{token: getToken()}}
                                action={GetUploadUrl()}
                                onChange={(e) => {
                                    try {
                                        let ret = e.fileList[0].response;
                                        if (ret.code === 0) {
                                            setLogoList(e.fileList)
                                            setLogoUrl(ret.data);
                                        } else {
                                            message.error("图片上传失败");
                                        }
                                    } catch (ex) {
                                        // message.error("图片上传失败")
                                    }
                                }}
                                onRemove={(e) => {
                                    setLogoUrl("");
                                    setLogoList([])
                                }}
                            >
                                {logoList.length === 0 && <div>
                                    <PlusCircleOutlined/>
                                    <div className="upload-text">上传</div>
                                </div>}
                            </Upload>
                            <span className="upload-warn">
                                <div>图片文件大小不超过1Mb，长宽比为1:1</div>
                                <div>长宽尺寸不低于300px</div>
                            </span>
                        </Form.Item>
                        <Form.Item
                            label="互联网医院二维码"
                        >
                            <div style={{position: "relative"}}>
                                <Upload
                                    name="file"
                                    disabled={currentMode === mode.VIEW}
                                    listType="picture-card"
                                    className="avatar-uploader"
                                    fileList={qrList}
                                    headers={{token: getToken()}}
                                    action={GetUploadUrl()}
                                    onChange={(e) => {
                                        try {
                                            let ret = e.fileList[0].response;
                                            if (ret.code === 0) {
                                                setQrList(e.fileList)
                                                setQrUrl(ret.data);
                                            } else {
                                                message.error("图片上传失败");
                                            }
                                        } catch (ex) {
                                            // message.error("图片上传失败")
                                        }
                                    }}
                                    onRemove={(e) => {
                                        setQrUrl("");
                                        setQrList([])
                                    }}
                                >
                                    {qrList.length === 0 && <div>
                                        <PlusCircleOutlined/>
                                        <div className="upload-text">上传</div>
                                    </div>}
                                </Upload>
                                <span className="upload-warn">
                                    <div>图片文件大小不超过1Mb，长宽比为1:1</div>
                                    <div>长宽尺寸不低于300px</div>
                                </span>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
                <div>
                    <div className="title">申请人信息</div>
                    <Form
                        ref={peopleForm}
                        {...formItemLayout}
                        onFinish={""}
                    >
                        <Form.Item
                            label="真实姓名"
                            name="commit_name"
                            initialValue={detail.commit_name}
                        >
                            <Input
                                disabled
                                autocomplete="off"
                                placeholder="请输入真实姓名"
                                // disabled={currentMode === mode.VIEW || currentMode === mode.CHECK}
                            />
                        </Form.Item>
                        <Form.Item
                            label="单位名称"
                            name="company_name"
                            initialValue={detail.company_name}
                        >
                            <Input
                                disabled
                                autocomplete="off"
                                placeholder="默认为医院名称，可编辑"
                                // disabled={currentMode === mode.VIEW || currentMode === mode.CHECK}
                            />
                        </Form.Item>
                        <Form.Item
                            label="职务"
                            name="position"
                            initialValue={detail.position}
                        >
                            <Input
                                disabled
                                autocomplete="off"
                                placeholder="请输入职务名称"
                                // disabled={currentMode === mode.VIEW || currentMode === mode.CHECK}
                            />
                        </Form.Item>
                        <Form.Item
                            label="手机号"
                            name="phone_num"
                            initialValue={detail.phone_num}
                        >
                            <Input
                                disabled
                                autocomplete="off"
                                placeholder="请输入手机号"
                                // disabled={currentMode === mode.VIEW || currentMode === mode.CHECK}
                            />
                        </Form.Item>
                        <Form.Item
                            label="证明附件"
                        >
                            <img style={{maxWidth: 495}} src={detail.certificate_img} alt=""/>
                        </Form.Item>
                        {currentMode !== mode.VIEW && <Form.Item
                            label="审核结果"
                            name="check_status"
                        >
                            <Radio.Group value={checkRes} onChange={(e) => {
                                setCheckRes(e.target.value)
                            }}>
                                <Radio value={2}>审核通过</Radio>
                                <Radio value={3}>审核驳回</Radio>
                            </Radio.Group>
                        </Form.Item>}
                        {currentMode !== mode.VIEW && <Form.Item
                            label="审核意见"
                            name="fail_reason"
                        >
                            <Input.TextArea rows={4} maxLength={1000}/>
                        </Form.Item>}
                    </Form>
                </div>
                <div className="btn-box">
                    <Button onClick={() => {props.history.goBack()}}>取消</Button>
                    {currentMode === mode.CHECK && <Button onClick={() => {
                        domEvents.save()
                    }}>保存修改</Button>}
                    {currentMode === mode.CHECK && <Button onClick={() => {
                        domEvents.submit()
                    }} type="primary">确认</Button>}
                </div>
            </div>
        </div>
    )
}

export default HospitalCheck