import React, {useState, useEffect} from "react";
import {
  Row,
  Col,
  Table,
  Button,
  Space,
  Spin,
  Pagination,
  Breadcrumb,
  Drawer,
  Modal,
  Tag,
  Form,
  Input,
  Popover,
  Select, DatePicker, message
} from "antd";
import Service from 'xgplayer-service';
import Player from 'xgplayer';
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {decodeContent} from "../../untils/commHelper";
import ListModel from "../../models/article/list";
import {findCityName, getProvinces} from "../../untils/citydata";
import {ArticleKind, AllArticleKind} from "../../untils/enum";
import { GetH5Host } from "../../conf/app";
import "../../static/css/pages/comm.css";
import "../../static/css/pages/article/list.css"
import "react-quill/dist/quill.snow.css";

const {Column} = Table;
const {confirm} = Modal;
const {Option} = Select;
const {RangePicker} = DatePicker;

function List(props) {
  const [visible, setVisible] = useState(false);
  const [role, setRole] = useState("");
  const [searchParams, setSearchParams] = useState();
  // const [page, setPage] = useState(1);
  const [categorysVisible, setCategorysVisible] = useState(false);
  const [moments, setMoments] = useState();
  const [drawerVisible, setDrawerVisible] = useState(false);
  const [content, setContent] = useState({
    title: '',
    tiem: '',
    source: '',
  });

  const {
    article,
    list,
    page,
    totalCount,
    categorys,
    onDelete,
    online,
    offline,
    search,
    getArticle,
    fixedWidth,
    commit,
    mechanismList,
    getMechanismList,
    loading,
    getPlayerToken,
  } = ListModel();

  // function getOptions(articleStatus){
  //     let role=localStorage.getItem("role");
  //     if(role==2){
  //         switch (articleStatus){
  //             case 9:
  //                 return ["编辑","预览","删除","提交"];
  //             case 3:
  //                 return ["编辑","预览","删除","提交"];
  //             default:
  //                 return ["预览"];
  //         }
  //     }else{
  //         switch (articleStatus){
  //             case 1:
  //                 return ["预览","审核","删除"];
  //             case 4:
  //                 return ["预览","下线","删除"];
  //             default:
  //                 return ["编辑","提交","预览","删除"];
  //         }
  //     }
  // }

  function showDrawer(title, source, videoID, time) {
    setContent({time, title, source});
    getPlayerToken(videoID).then((playToken) => {
      Service.url(playToken, '//vod.volcengineapi.com').then((res) => {
        console.log(res);
        let player = new Player({
          id: 'mse',
          url: res && Array.isArray(res.PlayInfoList) && res.PlayInfoList[0] && res.PlayInfoList[0].MainPlayUrl,
          poster: res.PosterUrl,
        });
      }, (err) => {
      });
    })
    setDrawerVisible(true);
  }

  function closeDrawer() {
    setDrawerVisible(false);
  }

  function copy(value) {
    let scrollTop = window.pageYOffset;
    let transfer = document.createElement('input');
    document.body.appendChild(transfer);
    transfer.value = value;  // 这里表示想要复制的内容
    transfer.focus();
    transfer.select();
    if (document.execCommand('copy')) {
      document.execCommand('copy');
      message.success("已成功复制文章链接至粘贴板")
    } else {
      message.error("复制失败，当前浏览器不支持")
    }
    transfer.blur();
    document.body.removeChild(transfer);
    window.scrollTo(0, scrollTop)

  }

  useEffect(() => {
    getMechanismList()
    let user_role = JSON.parse(localStorage.getItem("jkb_user")).user_role;
    setRole(user_role);
    window.document.title = "资讯列表";
    let tag = props.match.params.tag;
    if (tag && tag === "1") {
      let tmp = JSON.parse(localStorage.getItem("edit_back"));
      search(tmp.page, 10, tmp.searchParams);
      // setPage(tmp.page);
    } else {
      search(props.location.state ? props.location.state.page : 1, 10);
    }
  }, []);

  const formItemLayout = {
    labelCol: {span: 8},
    wrapperCol: {span: 12},
  };

  return (
    <>
      <div>
        <Breadcrumb style={{padding: "16px", background: "#F5F5F5"}}>
          <Breadcrumb.Item>资讯管理</Breadcrumb.Item>
          <Breadcrumb.Item>资讯列表</Breadcrumb.Item>
        </Breadcrumb>
        <div>
          <div style={{padding: "32px 16px 8px 16px"}}>
            <Form
              layout="inline"
              onFinish={(vals) => {
                let params = {};
                if (vals.id) {
                  params.id = vals.id;
                }
                if (vals.title) {
                  params.title = vals.title;
                }
                if (vals.cate && vals.cate != "all") {
                  params.cate = vals.cate;
                }
                if (vals.check_status != "all") {
                  params.check_status = vals.check_status;
                }

                if (vals.category_two > 0) {
                  params.category_two = vals.category_two;
                }

                if (vals.province_id > 0) {
                  params.province_id = vals.province_id;
                }
                if (vals.mechanism_id > 0) {
                  params.mechanism_id = vals.mechanism_id;
                }
                if (vals.commit_user) {
                  params.commit_user = vals.commit_user;
                }
                if (vals.article_type) {
                  params.article_type = vals.article_type;
                }
                if (moments) {
                  params.start_time = Math.floor(moments[0]._d.getTime() / 1000);
                  params.end_time = Math.floor(moments[1]._d.getTime() / 1000);
                }
                setSearchParams(params);
                search(1, params);
              }}
            >
              <Form.Item name="id" label="资讯ID" style={{width: "240px", marginBottom: "10px"}}>
                <Input/>
              </Form.Item>
              <Form.Item
                name="title"
                label="资讯标题"
                style={{width: "240px"}}
              >
                <Input/>
              </Form.Item>

              <Form.Item name="province_id" label="所属省份" style={{width: "240px", marginBottom: "10px"}}>
                <Select>
                  <Option value={0}>全部</Option>
                  {
                    getProvinces().map(item => {
                      return <Option key={item.value} value={item.value}>{item.label}</Option>
                    })
                  }
                </Select>
              </Form.Item>

              <Form.Item name="mechanism_id" label="提交机构" style={{width: "240px", marginBottom: "10px"}}>
                <Select>
                  <Option value={0}>全部</Option>
                  {
                    mechanismList.map(item => {
                      return <Option key={item.id} value={item.id}>{item.name}</Option>
                    })
                  }
                </Select>
              </Form.Item>

              <Form.Item name="commit_user" label="提交人" style={{width: "240px", marginBottom: "10px"}}>
                <Input/>
              </Form.Item>

              <Form.Item
                style={{marginBottom: "10px"}}
                label="发布时间"
                name="time"
                value={moments}
              >
                <div>
                  <RangePicker onChange={(dates) => {
                    // console.log(dates);
                    setMoments(dates);
                  }}/>
                </div>
              </Form.Item>

              <Form.Item
                name="cate"
                label="资讯频道"
                style={{width: "240px", marginBottom: "10px"}}
              >
                <Select
                  placeholder="请选择"
                  onChange={(e) => {
                    if (e == "health_life") {
                      setCategorysVisible(true);
                    } else {
                      setCategorysVisible(false);
                    }
                  }}
                >
                  <Option value="all">全部</Option>
                  <Option value="health_talk">健康百科-健康大咖谈</Option>
                  <Option value="health_life">健康百科-健康科普</Option>
                  <Option value="local_dynamic">地方动态</Option>
                  <Option value="index_carousel">首页-头图</Option>
                  <Option value="official_information">权威信息-官方资讯</Option>
                  <Option value="china_article">重大行动</Option>
                </Select>
              </Form.Item>

              {categorysVisible && (
                <Form.Item style={{width: "240px", marginLeft: "-16px"}} name="category_two">
                  <Select
                    placeholder="请选择资讯分类"
                    style={{width: "180px"}}
                  >
                    <Option key={"op_00001"} value="0">
                      全部
                    </Option>

                    {categorys.map((citem, cindex) => {
                      return (
                        <Option key={"op_" + cindex} value={citem.value}>
                          {citem.lable}
                        </Option>
                      );
                    })}

                    <Option key={"op_100"} value="100">
                      其他
                    </Option>
                  </Select>
                </Form.Item>
              )}

              <Form.Item
                name="check_status"
                label="资讯状态"
                style={{width: "240px", marginBottom: "10px"}}
              >
                <Select placeholder="请选择">
                  <Option value="all">全部</Option>
                  <Option value="9">草稿</Option>
                  <Option value="1">待审核</Option>
                  <Option value="3">驳回</Option>
                  <Option value="4">上线</Option>
                </Select>
              </Form.Item>
              <Form.Item
                name="article_type"
                label="资讯类型"
                style={{width: "240px", marginBottom: "10px"}}
              >
                <Select placeholder="请选择">
                  <Option value="0">全部</Option>
                  <Option value="1">图文</Option>
                  <Option value="2">文本</Option>
                  {/* <Option value="3">文件</Option> */}
                  <Option value="4">视频</Option>
                </Select>
              </Form.Item>
              <Form.Item>
                <Button htmlType="submit" type="primary">
                  查找
                </Button>
              </Form.Item>
            </Form>
          </div>
          <Row style={{marginTop: "24px"}}>
            <Col xs={24} sm={24}>
              <Spin spinning={false} tip="加载中...">
                <Table dataSource={list} scroll={{x: 1310 + fixedWidth, y: "50vh"}} pagination={false} bordered={true}
                       loading={loading}>
                  <Column align="center" title="资讯ID" width={110} dataIndex="id"/>
                  <Column
                    align="center"
                    title="标题"
                    dataIndex="title"
                    width="280px"
                    // fixed="left"
                  />
                  <Column
                    align="center"
                    title="资讯类型"
                    dataIndex="article_type"
                    width="100px"
                    // fixed="left"
                    render={(type) => {
                      switch (type) {
                        case 1:
                          return <div>图文</div>;
                        case 2:
                          return <div>文本</div>;
                        case 3:
                          return <div>文件</div>;
                        case 4:
                          return <div>视频</div>;
                        default:
                          return <div>--</div>;
                      }
                    }}
                  />
                  <Column
                    align="center"
                    title="资讯频道"
                    dataIndex="cate"
                    width={210}
                    render={(cate) => {
                      return ArticleKind[cate];
                    }}
                  />
                  <Column
                    align="center"
                    title="资讯分类"
                    width={110}
                    dataIndex="category_two"
                    render={(category_two) => {
                      return AllArticleKind[category_two];
                    }}
                  />
                  <Column
                    align="center"
                    title="所属省份"
                    width={110}
                    dataIndex="province_id"
                    render={(id) => {
                      return findCityName(id);
                    }}
                  />
                  <Column
                    align="center"
                    title="提交机构"
                    width={110}
                    dataIndex="mechanism_name"
                  />
                  <Column
                    align="center"
                    title="提交人"
                    width={110}
                    dataIndex="commit_user_name"
                  />
                  <Column
                    align="center"
                    title="发布时间"
                    width={110}
                    dataIndex="public_time"
                  />
                  <Column
                    align="center"
                    title="资讯状态"
                    width={110}
                    dataIndex="check_status"
                    render={(status) => {
                      switch (status) {
                        case 9:
                          return <div>草稿</div>
                        case 1:
                          return <div>待审核</div>
                        case 2:
                          return <div>审核通过</div>
                        case 3:
                          return <div>驳回</div>
                        case 4:
                          return <div>上线</div>
                        case 5:
                          return <div>删除</div>
                        default:
                          return <div>--</div>
                      }
                    }}
                  />
                  <Column
                    align="center"
                    title="审核意见"
                    width={150}
                    dataIndex="fail_reason"
                    render={(reason) => {

                      if (!reason || reason.length === 0) {
                        return <p>--</p>
                      } else if (reason.length <= 7) {
                        return <p>{reason}</p>
                      } else {
                        return (
                          <Popover content={<p style={{maxWidth: 200, wordBreak: "break-word"}}>
                            {reason}
                          </p>} title={null}>
                            <p style={{
                              margin: "0 auto",
                              textAlign: "center",
                              width: "120px",
                              overflow: "hidden",
                              whiteSpace: "nowrap",
                              textOverflow: "ellipsis"
                            }}>{reason}</p>
                          </Popover>
                        )
                      }
                    }}
                  />
                  <Column
                    align="center"
                    fixed="right"
                    width={fixedWidth}
                    className="fixed-column"
                    title="操作"
                    dataIndex="mode"
                    render={(mode, record) => {
                      // const optionList=getOptions(record.check_status);
                      return (
                        <Space>
                          {record.optionList.indexOf("编辑") > -1 && <Button
                            onClick={() => {
                              let params = {};
                              params.searchParams = searchParams;
                              params.page = page;

                              localStorage.setItem(
                                "edit_back",
                                JSON.stringify(params)
                              );
                              if (mode.cate === 'health_talk') {
                                props.history.push(`/main/add/video/${mode.id}`);
                              } else {
                                props.history.push({pathname: `/main/edit/${mode.id}`, state: {page: page}})
                              }
                            }}
                          >
                            编辑
                          </Button>}
                          {record.optionList.indexOf("提交") > -1 && <Button
                            onClick={() => {
                              confirm({
                                title: "提示",
                                icon: <ExclamationCircleOutlined/>,
                                content: "确定要提交该资讯么？",
                                onOk() {
                                  commit(mode.id, page);
                                },
                                onCancel() {
                                  console.log("Cancel");
                                },
                              });
                            }}
                          >
                            提交
                          </Button>}
                          {record.optionList.indexOf("审核") > -1 && <Button
                            onClick={() => {
                              if (mode.cate === 'health_talk') {
                                props.history.push(`/main/add/video/${mode.id}/check`);
                              } else {
                                props.history.push({pathname: `/main/check/${mode.id}`, state: {page: page}});
                              }
                            }}
                          >
                            审核
                          </Button>}
                          {record.optionList.indexOf("预览") > -1 && <Button
                            onClick={() => {
                              if (mode.cate === 'health_talk') {
                                showDrawer(mode.title, mode.article_source, mode.video_id, mode.public_time);
                              } else {
                                getArticle(mode.id);
                                setVisible(true);
                              }
                            }}
                          >
                            预览
                          </Button>}
                          {record.optionList.indexOf("上线") > -1 && <Button
                            onClick={() => {
                              confirm({
                                title: "提示",
                                icon: <ExclamationCircleOutlined/>,
                                content: "确定要上线该资讯么？",
                                onOk() {
                                  online(mode.id, page);
                                },
                                onCancel() {
                                  console.log("Cancel");
                                },
                              });
                            }}
                          >
                            上线
                          </Button>}
                          {record.optionList.indexOf("下线") > -1 && <Button
                            onClick={() => {
                              confirm({
                                title: "提示",
                                icon: <ExclamationCircleOutlined/>,
                                content: "确定要下线该资讯么？",
                                onOk() {
                                  offline(mode.id, page);
                                },
                                onCancel() {
                                  console.log("Cancel");
                                },
                              });
                            }}
                          >
                            下线
                          </Button>}

                          {record.optionList.indexOf("删除") > -1 && <Button
                            onClick={() => {
                              confirm({
                                title: "提示",
                                icon: <ExclamationCircleOutlined/>,
                                content: "确定要删除该资讯么？",
                                onOk() {
                                  onDelete(mode.id, page);
                                },
                                onCancel() {
                                  console.log("Cancel");
                                },
                              });
                            }}
                          >
                            删除
                          </Button>}
                          <Button onClick={() => {
                            const timestamp = parseInt(new Date().getTime() / 1000);
                            const timeBase64 = btoa(encodeURI(timestamp.toString()))
                            const ShareHost = GetH5Host();
                            const href = `${ShareHost}/preview/${mode.id}?sign=${timeBase64}`
                            copy(href);
                          }}>
                            分享资讯
                          </Button>
                        </Space>
                      );
                    }}
                  />
                </Table>
                {totalCount > 0 && (
                  <Row>
                    <Col xs={0} ms={24} md={24} lg={24} xl={24} xxl={24}>
                      <div className="tab-pages">
                        <Pagination
                          pageSizeOptions={["10"]}
                          pageSize={10}
                          defaultCurrent={1}
                          current={page}
                          total={totalCount}
                          onChange={(e) => {
                            // setPage(e);
                            search(e, searchParams);
                          }}
                        />
                      </div>
                    </Col>
                  </Row>
                )}
              </Spin>
            </Col>
          </Row>
        </div>

        <Drawer
          destroyOnClose={true}
          key={"hhhss"}
          title={
            <span>
              <b>资讯预览</b>
            </span>
          }
          width={1080}
          onClose={() => {
            setVisible(false);
          }}
          visible={visible}
        >
          <div className="preview-box" style={{padding: "16px 40px"}}>
            <div
              className="preview-title"
              style={{
                fontWeight: "700",
                fontSize: "20px",
                padding: "16px 0",
                textAlign: "center",
              }}
            >
              {article.title}
            </div>
            <div
              style={{marginTop: "16px"}}
              className="preview-content"
              dangerouslySetInnerHTML={{
                __html: decodeContent(article.content),
              }}
            ></div>
          </div>
        </Drawer>
        <Drawer
          destroyOnClose={true}
          title={
            <span>
                        <b>文章预览</b>
                    </span>
          }
          width={1080}
          onClose={closeDrawer}
          visible={drawerVisible}
        >
          <div className="preview-box" style={{padding: "16px 40px"}}>
            <div id="mse"></div>
            <p className="video-title">{content.title}</p>
            <p className="video-content">{content.source}</p>
            <p className="video-content">{content.time}</p>
          </div>
        </Drawer>
      </div>
    </>
  );
}

export default List;
