import React, {useState, useEffect, useRef} from "react";
import {Breadcrumb, Form, Input, Select, Button, Radio} from "antd";

import '../../static/css/pages/article/video.css'

const { Option } = Select;

function CheckVideo() {
  const [passValue, setPassValue] = useState(true);

  const formItemLayout = {
    labelCol: {span: 4},
    wrapperCol: {span: 18},
  };

  return (
    <div className="new-video-container">
      <Breadcrumb style={{padding: "16px", background: "#F5F5F5"}}>
        <Breadcrumb.Item>资讯管理</Breadcrumb.Item>
        <Breadcrumb.Item>新建视频</Breadcrumb.Item>
      </Breadcrumb>
      <div className="content-box">
        <Form
          {...formItemLayout}
          onFinish={async (vals) => {

          }}
        >
          <Form.Item
            label="标题"
            name="title"
            rules={[
              {
                required: true,
                message: "请填写标题",
                type: "string",
              },
              {
                max: 50,
                message: "标题不能超过50个字",
              },
            ]}
          >
            <Input autocomplete="off"/>
          </Form.Item>
          <Form.Item
            name="article_type"
            label="文章类型"
            rules={[
              {
                required: true,
                message: "请选择频道",
                type: "string",
              },
            ]}
          >
            <Select
              placeholder="请选择"
              style={{width: "240px"}}
            >
              <Option value="1">健康百科-健康科普</Option>
              <Option value="2">健康百科-健康大咖谈</Option>
            </Select>
          </Form.Item>
          <Form.Item
            label="视频来源机构"
            name="source"
            rules={[
              {
                required: true,
                message: "请填写视频来源机构",
                type: "string",
              },
              {
                max: 60,
                message: "视频来源机构不能多于60个字",
              },
            ]}
          >
            <Input autocomplete="off"/>
          </Form.Item>
          <Form.Item
            label="视频简介"
            rules={[
              {
                max: 100,
                message: "视频简介不能多于100个字",
                type: "string",
              },
            ]}
            name="abstract"
          >
            <Input.TextArea style={{height: "80px"}}/>
          </Form.Item>
          <Form.Item
            label="审核结果"
            rules={[
              {
                required: true,
                message: "请选择审核结果",
              },
            ]}
            name="result"
          >
            <Radio.Group onChange={(e) => {
              setPassValue(e.target.value === 1);
            }}>
              <Radio value={1}>审核通过</Radio>
              <Radio value={2}>审核驳回</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label="审核意见"
            rules={[
              {
                required: !passValue,
                message: "请输入审核意见",
                type: "string",
              },
            ]}
            name="feedback"
          >
            <Input.TextArea style={{height: "80px"}}/>
          </Form.Item>
        </Form>
        <div className="button-box">
          <Button type="primary">提交审核</Button>
          <Button>保存修改</Button>
          <Button>取消</Button>
        </div>
      </div>
    </div>
  )
}

export default CheckVideo;