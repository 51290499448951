import React, {useEffect, useState} from "react";
import {createModel} from "hox";
import {reqestApi} from "../../untils/requestHelper";
import {message} from "antd";

function useDetail() {
    const commit = async (id, params) => {
        let res = await reqestApi(`/api/mis/nethosp/check/${id}`, "POST", params);
        if(res.data && res.data.code === 0){
            message.success("提交成功");
            return true
        } else {
            message.error("提交失败");
            return false
        }
    }

    const save = async (id, params) => {
        let res = await reqestApi(`/api/mis/nethosp/store/${id}`, "POST", params);
        if(res.data && res.data.code === 0){
            message.success("保存成功");
        } else {
        }
    }

    return {
        commit,
        save,
    }
}

export default createModel(useDetail)