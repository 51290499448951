import React, { useState } from "react";
import { createModel } from "hox";
import { reqestApi } from "../../untils/requestHelper";
import { message } from "antd";

function useAddPeople(){
    const [roleList,setRoleList]=useState([]);

    //获取对应的角色列表
    const getRoleList=async (id)=>{
        let params={
            mecheanism_id:parseInt(id),
        }
        let ret=await reqestApi("/api/mis/user/getrole","GET",params);
        if(ret.data.code===0){
            setRoleList(ret.data.data);
        }else{
            message.error("获取角色列表失败");
        }
    }

    //创建/编辑
    const editPeople=async (param)=>{
        let params = {};

        for(let key in param){
            if(param[key]||param[key]===0){
                params[key]=param[key];
            }
        }
        let ret=await reqestApi("/api/mis/user/store","POST",params);
        if(ret.data.code===0){
            return 0;
        }else{
            if(ret.data.code == "60001"){
                message.error(ret.data.msg);
            }else{
                message.error("操作失败")
            }
        }
    }

    return {
        roleList,
        editPeople,
        getRoleList
    }
}

export default createModel(useAddPeople);