import React from "react"
import "core-js"
import "@babel/polyfill";
import ReactDOM from "react-dom"
import AppRouter from "./router/index"
//import "antd/dist/antd.css";
ReactDOM.render(
  <div>
    <AppRouter />
  </div>,
  document.getElementById("root")
)
