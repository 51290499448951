import React, {useEffect, useState} from "react"
import {
    Breadcrumb,
    Form,
    Input,
    Select,
    DatePicker,
    Button,
    Radio,
    Table,
    message,
    Drawer,
    Modal,
    Row,
    Col, Pagination
} from "antd";

import ListModal from "../../models/covid19/news"
import {ExclamationCircleOutlined} from "@ant-design/icons";
import {decodeContent} from "../../untils/commHelper";
import { GetH5Host } from "../../conf/app";

const {Option} = Select
const {RangePicker} = DatePicker
const {Column} = Table
const {confirm} = Modal;

function CovidNews(props) {
    const mode = {
        NEW: 0,
        EDIT: 1,
        CHECK: 2,
        VIEW: 3
    }
    const formRef = React.createRef();

    const [visible, setVisible] = useState(false);
    const [moments, setMoments] = useState();
    const [params, setParams] = useState({});
    const [check_status, setStatus] = useState(0);

    const {
        article,
        list,
        page,
        totalCount,
        search,
        offline,
        commit,
        deleteCase,
        getInfo,
        // fixedWidth,
        loading
    } = ListModal()

    useEffect(() => {
        document.title = "疫苗资讯管理"
        search(1, params, check_status);
    }, [])

    function copy(value) {
        let scrollTop = window.pageYOffset;
        let transfer = document.createElement('input');
        document.body.appendChild(transfer);
        transfer.value = value;  // 这里表示想要复制的内容
        transfer.focus();
        transfer.select();
        if (document.execCommand('copy')) {
            document.execCommand('copy');
            message.success("已成功复制文章链接至粘贴板")
        } else {
            message.error("复制失败，当前浏览器不支持")
        }
        transfer.blur();
        document.body.removeChild(transfer);
        window.scrollTo(0, scrollTop)

    }

    return (
        <div>
            <Breadcrumb style={{padding: "16px", background: "#F5F5F5"}}>
                <Breadcrumb.Item>新冠疫苗专题管理</Breadcrumb.Item>
                <Breadcrumb.Item>疫苗资讯管理</Breadcrumb.Item>
            </Breadcrumb>
            <div>
                <div style={{padding: "32px 16px 8px 16px"}}>
                    <Form
                        ref={formRef}
                        layout="inline"
                        onFinish={(val, status) => {
                            if (moments) {
                                val.start_time = Math.floor(moments[0]._d.getTime() / 1000);
                                val.end_time = Math.floor(moments[1]._d.getTime() / 1000);
                            }
                            setParams(val);
                            search(1, val, status ? status : check_status);
                        }}
                    >
                        <Form.Item name="title" label="标题" style={{width: "240px", marginBottom: "10px"}}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="cate" label="位置" style={{width: "240px", marginBottom: "10px"}}>
                            <Select>
                                <Option value={2}>疫苗专题头图</Option>
                                <Option value={1}>资讯列表</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="commit_user" label="提交人" style={{width: "240px", marginBottom: "10px"}}>
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            style={{marginBottom: "10px"}}
                            label="发布时间"
                            name="time"
                            value={moments}
                        >
                            <div>
                                <RangePicker onChange={(dates) => {
                                    setMoments(dates);
                                }}/>
                            </div>
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" type="primary">
                                查找
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <div>
                                <Button onClick={() => {
                                    formRef.current.resetFields();
                                }}>
                                    重置查询条件
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                    <div style={{marginTop: "20px"}}>
                        <Radio.Group value={check_status} onChange={(e) => {
                            setStatus(e.target.value);
                            formRef.current.submit(e.target.value)
                        }}>
                            <Radio.Button value={0}>全部</Radio.Button>
                            <Radio.Button value={9}>草稿</Radio.Button>
                            <Radio.Button value={1}>待审核</Radio.Button>
                            <Radio.Button value={3}>驳回</Radio.Button>
                            <Radio.Button value={4}>上线</Radio.Button>
                        </Radio.Group>
                        <Button
                            style={{marginLeft: "40px"}}
                            type="primary"
                            onClick={() => {
                                props.history.push({
                                    pathname: "/main/newsedit",
                                    state: {mode: mode.NEW, caseInfo: JSON.stringify({})}
                                })
                            }}
                        >
                            新建
                        </Button>
                    </div>
                </div>
                <div style={{marginTop: "30px"}}>
                    <Table
                        scroll={{ x: 1300 }}
                        dataSource={list}
                        // scroll={{x: 1230 + fixedWidth, y: "50vh"}}
                        pagination={false}
                        bordered={true}
                        loading={loading}
                    >
                        <Column
                            align="center"
                            title="序号"
                            dataIndex="index"
                            width="80px"
                        />
                        <Column
                            align="center"
                            title="标题"
                            dataIndex="title"
                            width="280px"
                        />
                        <Column
                            align="center"
                            title="位置"
                            dataIndex="cate"
                            width="280px"
                            render={data => {
                                return <div>{data === 2 ? "疫苗专题头图" : "资讯列表"}</div>
                            }}
                        />
                        <Column
                            align="center"
                            title="提交人"
                            dataIndex="commit_user_name"
                            width="280px"
                        />
                        <Column
                            align="center"
                            title="提交时间"
                            dataIndex="created_at"
                            width="280px"
                        />
                        <Column
                            align="center"
                            title="资讯状态"
                            dataIndex="check_status"
                            width="280px"
                            render={(status) => {
                                switch (status) {
                                    case 9:
                                        return <div>草稿</div>
                                    case 1:
                                        return <div>待审核</div>
                                    case 2:
                                        return <div>审核通过</div>
                                    case 3:
                                        return <div>驳回</div>
                                    case 4:
                                        return <div>上线</div>
                                    case 5:
                                        return <div>删除</div>
                                    default:
                                        return <div>--</div>
                                }
                            }}
                        />
                        <Column
                            align="center"
                            title="操作"
                            dataIndex="mode"
                            width={400}
                            // fixed="right"
                            render={(item, record) => {
                                return (
                                    <div
                                        style={{width: "100%", display: "flex", justifyContent: "space-around"}}
                                    >
                                        {record.optionList.indexOf("编辑") > -1 && <Button
                                            onClick={async () => {
                                                const caseInfo = await getInfo(record.id);
                                                if (caseInfo) {
                                                    props.history.push({
                                                        pathname: "/main/newsedit",
                                                        state: {mode: mode.EDIT, caseInfo: JSON.stringify(caseInfo)}
                                                    })
                                                }
                                            }}
                                        >
                                            编辑
                                        </Button>}
                                        {record.optionList.indexOf("审核") > -1 && <Button
                                            onClick={async () => {
                                                const caseInfo = await getInfo(record.id);
                                                props.history.push({
                                                    pathname: "/main/newsedit",
                                                    state: {mode: mode.CHECK, caseInfo: JSON.stringify(caseInfo)}
                                                })
                                            }}
                                        >
                                            审核
                                        </Button>}
                                        {record.optionList.indexOf("下线") > -1 && <Button
                                            type="primary"
                                            onClick={() => {
                                                confirm({
                                                    title: "提示",
                                                    icon: <ExclamationCircleOutlined/>,
                                                    content: "确定要下线该案例么？",
                                                    async onOk() {
                                                        if (await offline(record.id)) {
                                                            search(page, params, check_status);
                                                        } else {
                                                            message.error("下线失败");
                                                        }
                                                    },
                                                    onCancel() {
                                                        console.log("Cancel");
                                                    },
                                                });
                                            }}
                                        >
                                            下线
                                        </Button>}
                                        {record.optionList.indexOf("提交") > -1 && <Button
                                            onClick={() => {
                                                confirm({
                                                    title: "提示",
                                                    icon: <ExclamationCircleOutlined/>,
                                                    content: "确定要提交该案例么？",
                                                    async onOk() {
                                                        if (await commit(record.id)) {
                                                            search(page, params, check_status);
                                                        } else {
                                                            message.error("提交失败");
                                                        }
                                                    },
                                                    onCancel() {
                                                        console.log("Cancel");
                                                    },
                                                });
                                            }}
                                        >
                                            提交
                                        </Button>}
                                        {record.optionList.indexOf("删除") > -1 && <Button
                                            type="primary"
                                            danger
                                            onClick={() => {
                                                confirm({
                                                    title: "提示",
                                                    icon: <ExclamationCircleOutlined/>,
                                                    content: "确定要删除该案例么？",
                                                    async onOk() {
                                                        if (await deleteCase(record.id)) {
                                                            search(page, params, check_status);
                                                        } else {
                                                            message.error("删除失败");
                                                        }
                                                    },
                                                    onCancel() {
                                                        console.log("Cancel");
                                                    },
                                                });
                                            }}
                                        >
                                            删除
                                        </Button>}
                                        <Button
                                            onClick={async () => {
                                                getInfo(record.id);
                                                setVisible(true)
                                            }}
                                        >
                                            预览
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                let timestamp = parseInt(new Date().getTime() / 1000);
                                                let timeBase64 = btoa(encodeURI(timestamp.toString()));
                                                const ShareHost = GetH5Host();
                                                const href = `${ShareHost}/covid19news/preview/${record.id}?sign=${timeBase64}`
                                                copy(href);
                                            }}
                                        >
                                            分享资讯
                                        </Button>
                                    </div>
                                )
                            }}
                            // fixed="left"
                        />
                    </Table>
                    {totalCount > 0 && (
                        <Row>
                            <Col xs={0} ms={24} md={24} lg={24} xl={24} xxl={24}>
                                <div className="tab-pages">
                                    <Pagination
                                        pageSizeOptions={["10"]}
                                        pageSize={10}
                                        defaultCurrent={1}
                                        current={page}
                                        total={totalCount}
                                        onChange={(e) => {
                                            // setPage(e);
                                            search(e, params, check_status);
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    )}
                </div>
            </div>
            <Drawer
                destroyOnClose={true}
                key={"hhhss"}
                title={
                    <span>
                        <b>资讯预览</b>
                    </span>
                }
                width={1080}
                onClose={() => {
                    setVisible(false);
                }}
                visible={visible}
            >
                <div className="preview-box" style={{padding: "16px 40px"}}>
                    <div
                        className="preview-title"
                        style={{
                            fontWeight: "700",
                            fontSize: "20px",
                            padding: "16px 0",
                            textAlign: "center",
                        }}
                    >
                        {article.title}
                    </div>
                    <div
                        style={{marginTop: "16px"}}
                        className="preview-content"
                        dangerouslySetInnerHTML={{
                            __html: decodeContent(article.content),
                        }}
                    ></div>
                </div>
            </Drawer>
        </div>
    )
}

export default CovidNews