import { useState } from "react"
import { createModel } from "hox"
import { reqestApi } from "../untils/requestHelper"
import {message} from "antd";
function useLayout() {
  const [userInfo, setUserInfo] = useState({})
  const [menuList,setMenuList]=useState([]);

  const logout = async (props) => {
    localStorage.removeItem("authorization")
    localStorage.removeItem("jkb_user")
    props.history.push("/login")
  }

  const getRoute=async ()=>{
    const ret=await reqestApi("/api/mis/user/menu","GET");
    if(ret.data.code===0){
      setMenuList(ret.data.data);
    }
  }

  const init = async (props) => {
    let str = localStorage.getItem("authorization")
    if (str && str.indexOf("Bearer") != -1) {
      let userStr = localStorage.getItem("jkb_user")
      if (userStr) {
        setUserInfo(JSON.parse(userStr))
      }
    } else {
      props.history.push("/login")
    }
  }

  const changePassword=async (param)=>{
    let ret=await reqestApi("/api/mis/user/modifypassword","POST",param);
    if(ret.data.code===0){
      message.success("修改密码成功，请重新登录")
      return 0;
    }else{
      message.error(ret.data.msg);
      return -1;
    }
  }

  return {
    init,
    logout,
    userInfo,
    menuList,
    getRoute,
    changePassword
  }
}

export default createModel(useLayout)
