import React, {useState, useEffect, useRef} from "react";
import {
    Breadcrumb,
    Form,
    Input,
    Select,
    Button, message,
} from "antd";
import AddPeopleModel from "../../models/system/addPeople"

const { Option } = Select;

function AddPeople(props){
    const [mechanism,setMechanism]=useState(props.location.state.info?JSON.parse(props.location.state.info).mechanism_id:"")
    const [role,setRole]=useState(props.location.state.info?JSON.parse(props.location.state.info).role_id:"");
    const ifSelf=useRef(false);
    const {
        roleList,
        editPeople,
        getRoleList,
    }=AddPeopleModel()

    useEffect(()=>{
        const mobile=props.location.state.info?JSON.parse(props.location.state.info).mobile:"";
        const originMobile=JSON.parse(localStorage.getItem("jkb_user")).mobile;
        ifSelf.current=mobile===originMobile;
        if(mechanism){
            getRoleList(mechanism);
        }
    },[]);

    const formItemLayout = {
        labelCol: {span: 6},
        wrapperCol: {span: 16},
    };

    return(
        <div>
            <Breadcrumb style={{ padding: "16px", background: "#F5F5F5" }}>
                <Breadcrumb.Item>系统管理</Breadcrumb.Item>
                <Breadcrumb.Item>人员管理</Breadcrumb.Item>
                <Breadcrumb.Item>{props.location.state.info?"编辑人员":"新增人员"}</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{padding:"30px 0 0 150px"}}>
                <Form
                    style={{width:"600px"}}
                    name="basic"
                    {...formItemLayout}
                    onFinish={async (val)=>{
                        if(!mechanism||!role){
                            message.warn("请选择人员机构及角色");
                            return;
                        }
                        let id=props.location.state.info?JSON.parse(props.location.state.info).user_id:"";
                        if(id){
                            val.user_id=id;
                        }
                        val.mechanism_id=mechanism;
                        val.role_id=role;
                        if(await editPeople(val)===0){
                            if(ifSelf.current){
                                props.history.push('/login');
                                message.success("操作成功，请重新登陆")
                            }else{
                                props.history.push({pathname:"/main/people",state:{page:props.location.state.page?props.location.state.page:1}});
                                message.success("操作成功")
                            }
                        }
                    }}
                >
                    <Form.Item
                        label="人员手机号"
                        name="mobile"
                        initialValue={props.location.state.info?JSON.parse(props.location.state.info).mobile:""}
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="人员姓名"
                        name="name"
                        initialValue={props.location.state.info?JSON.parse(props.location.state.info).user_name:""}
                        rules={[{ required: true }]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="人员机构与角色"
                        name="info"
                        // rules={[{ required: true }]}
                    >
                        <Select
                            value={mechanism}
                            onChange={(id)=>{
                                if(id){
                                    setMechanism(id);
                                    getRoleList(id);
                                    setRole("")
                                }
                            }}
                            style={{width:"180px",marginRight:"20px"}}
                        >
                            {
                                JSON.parse(props.location.state.list).map(item=>{
                                    return <Option key={item.id} value={item.id}>{item.name}</Option>
                                })
                            }
                        </Select>
                        <Select style={{width:"200px"}} disabled={!mechanism} value={role} onChange={(val)=>{
                            setRole(val)
                        }}>
                            {
                                roleList.map(item=>{
                                    return <Option key={item.role_id} value={item.role_id}>{item.role_name}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>

                    {!props.location.state.info&&<Form.Item
                        label="设置初始密码"
                        name="password"
                        rules={[{ required: props.location.state.info?false:true }]}
                    >
                        <Input/>
                    </Form.Item>}


                    <Form.Item
                        style={{textAlign:"right"}}
                    >
                        <Button style={{marginRight:"20px"}} type="primary" htmlType="submit">
                            保存
                        </Button>
                        <Button onClick={()=>{
                            props.history.goBack(-1);
                        }}>
                            取消
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default AddPeople;