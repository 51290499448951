export const StatueEnum = {
  0: "待付款",
  1: "支付完成",
  2: "订单完成",
  3: "接诊中",
  4: "订单退款中",
  5: "订单完成退款",
  6: "订单取消",
  7: "拒绝接诊",
};

export const ArticleKind = {
  health_talk: "健康百科-健康大咖谈",
  health_life: "健康百科—健康科普",
  local_dynamic: "地方动态",
  index_carousel: "首页—头图",
  official_information: "权威信息—官方资讯",
  china_article: "重大行动",
};

export const AllArticleKind = {
  0: "--",
  1: "健康知识普及行动",
  2: "合理膳食行动",
  3: "全民健身行动",
  4: "控烟行动",
  5: "心理健康促进行动",
  6: "健康环境促进行动",
  7: "妇幼健康促进行动",
  8: "中小学健康促进行动",
  9: "职业健康保护行动",
  10: "老年健康促进行动",
  11: "心脑血管疾病防治行动",
  12: "癌症防治行动",
  13: "慢性呼吸系统疾病防治行动",
  14: "糖尿病防治行动",
  15: "传染病及地方病防控行动",
};
