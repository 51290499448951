export const GetApiHost = () => {
  let href = window.location.href;
  if (href.indexOf("localhost") != -1) {
    return "";
  }
  if (href.indexOf('manage-test.jkzgxd.cn') !== -1) {
    return "https://api-test.jkzgxd.cn";
  }
  return "https://api.jkzgxd.cn"; //"https://api.jkzgxd.cn"; //https://api-test.jkzgxd.cn/
};

export const GetUploadUrl = () => {
  let href = window.location.href;
  if (href.indexOf('manage-test.jkzgxd.cn') !== -1 || href.indexOf('manage.jkzgxd.cn') !== -1) {
    return "https://healthchina-doctor-mis-api.xiaohe-jiankang.com/pub/uploadimage";
  }
  return "/pub/uploadimage"; // 开发环境时图片上传代理
  // return "https://healthchina-doctor-mis-api.xiaohe-jiankang.com/pub/uploadimage"; //"http://10.227.77.173:6789/pub/uploadimage"; //
};

/**
 * 
 * 返回H5的host以供分享
 * @returns H5 Host
 */
export const GetH5Host = () => {
  let href = window.location.href;
  if (process.env.NODE_ENV === 'development' || href.indexOf('manage-test.jkzgxd.cn') !== -1) {
    return "https://m-test.jkzgxd.cn";
  }
  return "https://m.jkzgxd.cn";
};