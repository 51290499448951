import React, { useState } from "react";
import { createModel } from "hox";
import { reqestApi } from "../../untils/requestHelper";
import { message } from "antd";

function useOperateLog(){
    const [page, setPage] = useState(1);
    const [list, setList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const search = async (params,page) => {
        params.pagenum = page;
        params.pagesize = 10;
        setLoading(true)
        let ret = await reqestApi("/api/mis/operation/search", "GET", params);
        setLoading(false)
        if(ret.data.code===0){
            setList(ret.data.data);
            setTotalCount(ret.data.totalCount);
            setPage(page);
        }else{
            message.error("查询错误")
        }
    }


    return {
        page,
        list,
        totalCount,
        search,
        loading
    }
}

export default createModel(useOperateLog);