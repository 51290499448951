import React, {useState, useEffect, useRef} from "react";
import {
    Breadcrumb,
    Form,
    Input,
    Select,
    Button,
    DatePicker, Table, Pagination, Drawer, message, Row, Col
} from "antd";

import operateModel from "../../models/system/operateLog"
import {load} from "dotenv";

const { RangePicker } = DatePicker;
const { Column } = Table;
const {Option}=Select

function OperateLog(){
    const formRef = React.createRef();
    const [moments,setMoments]=useState();
    const [searchParams, setSearchParams] = useState({});

    const {
        page,
        list,
        totalCount,
        search,
        loading
    } = operateModel()

    useEffect(() => {
        document.title = "操作日志管理"
        search(searchParams, 1)
    }, [])

    return(
        <div>
            <Breadcrumb style={{ padding: "16px", background: "#F5F5F5" }}>
                <Breadcrumb.Item>系统管理</Breadcrumb.Item>
                <Breadcrumb.Item>操作日志</Breadcrumb.Item>
            </Breadcrumb>
            <div>
                <div style={{padding:"30px"}}>
                    <Form ref={formRef} layout="inline" onFinish={(val)=>{
                        let params = {};
                        if(val.name){
                            params.name = val.name;
                        }
                        if(val.type||val.type==0){
                            params.type = val.type;
                        }
                        if(moments){
                            params.start_time=Math.floor(moments[0]._d.getTime()/1000);
                            params.end_time=Math.floor(moments[1]._d.getTime()/1000);
                        }
                        setSearchParams(params);
                        search(params, 1);
                    }}>
                        <Form.Item
                            label="操作人"
                            name="name"
                            style={{width: "240px",marginBottom:"10px"}}
                        >
                            <Input/>
                        </Form.Item>
                        <Form.Item
                            style={{width:"240px",marginBottom:"10px"}}
                            label="操作模块"
                            name="type"
                        >
                            <Select
                                placeholder="请选择"
                            >
                                <Option value="0">系统管理</Option>
                                <Option value="1">资讯管理</Option>
                                <Option value="2">登录</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item
                            style={{marginBottom:"10px"}}
                            label="提交时间"
                            name="time"
                            value={moments}
                        >
                            <div>
                                <RangePicker onChange={(dates)=>{
                                    // console.log(dates);
                                    setMoments(dates);
                                }}/>
                            </div>
                        </Form.Item>
                        <Form.Item>
                            <div>
                                <Button type="primary" htmlType="submit">
                                    查询
                                </Button>
                            </div>
                        </Form.Item>
                        <Form.Item>
                            <div>
                                <Button onClick={()=>{
                                    setMoments(null);
                                    formRef.current.resetFields();
                                }}>
                                    重置查询条件
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
            </div>
            <div>
                <Table dataSource={list} scroll={{y:"50vh"}} pagination={false} bordered={true} loading={loading}>
                    <Column align="center" title="操作时间" dataIndex="created_at" />
                    <Column
                        align="center"
                        title="操作模块"
                        dataIndex="type"
                        render= {
                            (type) => {
                                switch (type){
                                    case 0:
                                        return <p style={{marginBottom:0}}>系统管理</p>
                                    case 1:
                                        return <p style={{marginBottom:0}}>资讯管理</p>
                                    case 2:
                                        return <p style={{marginBottom:0}}>登录</p>
                                    default:
                                        return <p style={{marginBottom:0}}>--</p>
                                }
                            }
                        }
                    />
                    <Column
                        align="center"
                        title="操作人"
                        dataIndex="name"
                    />
                    <Column
                        align="center"
                        title="操作人手机号"
                        dataIndex="mobile"
                    />
                    <Column
                        align="center"
                        title="操作内容"
                        dataIndex="content"
                    />
                </Table>
                {totalCount > 0 && (
                    <Row>
                        <Col xs={0} ms={24} md={24} lg={24} xl={24} xxl={24}>
                            <div className="tab-pages">
                                <Pagination
                                    pageSizeOptions={["10"]}
                                    pageSize={10}
                                    defaultCurrent={1}
                                    current={page}
                                    total={totalCount}
                                    onChange={(e) => {
                                        search(searchParams, e);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    )
}

export default OperateLog