import React, {useEffect, useState} from "react";
import {createModel} from "hox";
import {reqestApi} from "../../untils/requestHelper";
import {message} from "antd";

function usePov() {
    const [fileList, setFileList] = useState(() => []);
    const [imgUrl, setImgUrl] = useState();

    const save = async (params, id) => {
        const ret = await reqestApi(`/api/mis/vaccine_pov/store/${id?id:""}`, "POST", params);
        if(ret.data && ret.data.code === 0){
            message.success("保存成功");
            return ret.data.data.id
        }else{
            message.error("保存失败");
            return false;
        }
    }

    return{
        save,
        fileList,
        setFileList,
        imgUrl,
        setImgUrl,
    }
}

export default createModel(usePov)
