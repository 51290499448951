import React, {useState} from "react";
import {createModel} from "hox";
import {reqestApi} from "../../untils/requestHelper";
import {message} from "antd";

function useList() {
    const [page, setPage] = useState([]);
    const [list, setList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);

    const search = async (page, searchParams) => {
        let params = {};
        params.page = page;
        for (let key in searchParams) {
            if (searchParams[key]) {
                params[key] = searchParams[key]
            }
        }
        setLoading(true);
        let ret = await reqestApi("/api/mis/vaccine_pov/search", "GET", params);
        setLoading(false)
        if (ret.data.code === 0) {
            setPage(page);
            setTotalCount(ret.data.totalCount)
            ret.data.data.forEach((item, index) => {
                item.key = item.id;
                item.index = (page - 1) * 10 + index + 1;
            })
            setList(ret.data.data)
        }
    }

    const offline = async (id) => {
        const ret = await reqestApi(`/api/mis/vaccine_pov/downline/${id}`,"GET");
        return !!(ret.data && ret.data.code === 0);
    }

    const online = async (id) => {
        const ret = await reqestApi(`/api/mis/vaccine_pov/online/${id}`,"GET");
        return !!(ret.data && ret.data.code === 0);
    }

    const deleteCase = async (id) => {
        const ret = await reqestApi(`/api/mis/vaccine_pov/delete/${id}`,"GET");
        return !!(ret.data && ret.data.code === 0);
    }

    const getInfo = async (id) => {
        let ret = await reqestApi(`/api/mis/vaccine_pov/detail/${id}`, "GET");
        if (ret.data && ret.data.code === 0) {
            return ret.data.data;
        } else {
            message.error("获取案例内容失败！");
            return false;
        }
    }

    return {
        page,
        list,
        totalCount,
        search,
        offline,
        online,
        deleteCase,
        getInfo,
        loading
    }
}

export default createModel(useList)