import React, {useEffect} from "react"
import {Row, Col, Input, Form, Checkbox, Button, Layout} from "antd"
import { UserOutlined,ShoppingOutlined  } from '@ant-design/icons';
import loginModel from "../models/login"

import "../static/css/pages/login.css"
import "../static/css/pages/comm.css"
import logo from "../static/images/login-logo.png"

const {Footer} = Layout

function Login(props) {
    const {login} = loginModel()
    const [form] = Form.useForm()
    useEffect(() => {
        window.document.title = "登录"
    }, [])
    const tailLayout = {
        wrapperCol: {offset: 8, span: 16},
    }
    return (
        <div className="login-main">

            <img className="logo" src={logo} alt=""/>

            <div className="login-container">
                <p className="title">用户登录</p>
                <Form
                    form={form}
                    onFinish={(vals) => {
                        login(vals.phone, vals.password, props)
                    }}
                    initialValues={{remember: true}}
                >
                    <Form.Item
                        name="phone"
                        rules={[{required: true, message: "请输入手机号"}]}
                    >
                        <Input
                            bordered={false}
                            prefix={<UserOutlined />}
                            placeholder="手机号"
                            className="normal-height"
                        />
                    </Form.Item>

                    <Form.Item
                        name="password"
                        rules={[{required: true, message: "请输入密码"}]}
                    >
                        <div style={{display: "flex", justifyContent: "space-between"}}>
                            <Input
                                bordered={false}
                                prefix={<ShoppingOutlined/>}
                                placeholder="密码"
                                type="password"
                                className="normal-height"
                            />
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            style={{width: "100%"}}
                            type="primary"
                            htmlType="submit"
                            className="normal-height"
                        >
                            登录
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}

export default Login
