import React, {useState} from "react"
import {createModel} from "hox"
import {reqestApi} from "../untils/requestHelper"
import {message} from "antd"

function useLogin() {
    const login = async (phone, password, props) => {
        let params = {}
        params.mobile = phone //"13412345679" //
        params.password = password //"zaq1xsw2" //
        let ret = await reqestApi("/api/login", "POST", params)
        if (ret.data.code == 0) {
            let data = ret.data.data
            let str = data.token_type + " " + data.access_token;
            localStorage.setItem("authorization", str);
            localStorage.setItem("jkb_user", JSON.stringify(data.user_info));
            localStorage.setItem("mechanism", data.user_info.user_mechanism);
            localStorage.setItem("role", data.user_info.permission_id);
            if (data.user_info.user_role === "案例编辑员") {
                props.history.push("/main/healthplatform")
            } else if (data.user_info.user_role === "疫苗资讯编辑员") {
                props.history.push("/main/covidnews")
            } else if (data.user_info.user_role === "POV编辑员") {
                props.history.push("/main/povlist")
            } else {
                props.history.push("/main/list")
            }
        } else {
            message.error(ret.data.message ? ret.data.message : "账号密码错误！")
        }

        // if (ret.data.code == 0) {
        //   let user = ret.data.data
        //   localStorage.setItem("qn_user", JSON.stringify(user))
        //   localStorage.setItem("authorization", JSON.stringify(user))
        //   props.history.push("/main/list")
        // } else {
        //   message.warning(ret.data.msg)
        // }
    }

    return {
        login,
    }
}

export default createModel(useLogin)
