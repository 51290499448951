import React, { useEffect, useState, useRef } from "react";
// 引入编辑器组件
import BraftEditor from "braft-editor";
// 引入编辑器样式
import "braft-editor/dist/index.css";
import { ContentUtils } from "braft-utils";
import { FlagOutlined } from "@ant-design/icons";

function Edit() {
  const maoRef = useRef();
  const pargRef = useRef();
  const [editData, setEditData] = useState(() => {
    return BraftEditor.createEditorState(null);
  });
  const [anchor, setAnchor] = useState("");
  const [parag, setParag] = useState("");
  const extendControls = [
    "separator",
    {
      key: "my-modal",
      type: "modal",
      title: "添加锚点", // 指定鼠标悬停提示文案
      className: "my-modal", // 指定触发按钮的样式名
      html:
        '<img style="height:20px" src="https://imgjiankangbao.lvsongguo.com/2020-09-07/13:57:05/98765045.png" />', // 指定在按钮中渲染的html字符串
      text: "锚点", // 指定按钮文字，此处可传入jsx，若已指定html，则text不会显示
      onClick: () => {}, // 指定触发按钮点击后的回调函数
      modal: {
        id: "my-modal", // 必选属性，传入一个唯一字符串即可
        title: "添加锚点", // 指定弹窗组件的顶部标题
        className: "my-modal", // 指定弹窗组件样式名
        width: 500, // 指定弹窗组件的宽度
        height: 300, // 指定弹窗组件的高度
        showFooter: true, // 指定是否显示弹窗组件底栏
        showCancel: true, // 指定是否显示取消按钮
        showConfirm: true, // 指定是否显示确认按钮
        confirmable: true, // 指定确认按钮是否可用
        showClose: true, // 指定是否显示右上角关闭按钮
        closeOnBlur: true, // 指定是否在点击蒙层后关闭弹窗(v2.1.24)
        closeOnConfirm: true, // 指定是否在点击确认按钮后关闭弹窗(v2.1.26)
        closeOnCancel: true, // 指定是否在点击取消按钮后关闭弹窗(v2.1.26)
        cancelText: "取消", // 指定取消按钮文字
        confirmText: "确定", // 指定确认按钮文字
        bottomText: null, // 指定弹窗组件底栏左侧的文字，可传入jsx
        onConfirm: () => {
          insertText();
        }, // 指定点击确认按钮后的回调函数
        onCancel: () => {}, // 指定点击取消按钮后的回调函数
        onClose: () => {}, // 指定弹窗被关闭后的回调函数
        onBlur: () => {}, // 指定蒙层被点击时的回调函数
        children: (
          <div style={{ height: "180px", width: "400px", padding: "20px" }}>
            <div>
              段落名称：
              <input ref={pargRef} style={{ width: "240px" }} />
            </div>

            <div style={{ marginTop: "16px" }}>
              锚点名称：
              <input ref={maoRef} style={{ width: "240px" }} />
            </div>
          </div>
        ), // 指定弹窗组件的内容组件
      },
    },
  ];

  const insertText = () => {
    let maoName = maoRef.current.value;
    let pargName = pargRef.current.value;
    let htm = `${editData.toHTML()} <p id="${maoName}" > <span style="font-size:18px"><b>${pargName}</b> </span> </p>`;
    setEditData(BraftEditor.createEditorState(htm));
  };

  return (
    <div className="my-component">
      <BraftEditor
        value={editData}
        onChange={(html) => {
          setEditData(html);
        }}
        onSave={() => {
          const context = editData.toHTML();
          console.log("文本：", context);
        }}
        extendControls={extendControls}
      />
    </div>
  );
}
export default Edit;
