import React, {useEffect, useState} from "react";
import {createModel} from "hox";
import {reqestApi} from "../../untils/requestHelper";
import {message} from "antd";

function useDetail() {
    const [isUpload, changeIsUpload] = useState(() => true);
    const [fileList, setFileList] = useState(() => []);
    const [fileListWap, setFileListWap] = useState(() => []);
    const [imgRules, changeImgRules] = useState(() => []);
    const [imgUrlWap, setImgUrlWap] = useState();
    const [imgUrl, setImgUrl] = useState();

    const onChangeFileList = async ({fileList: newFileList}, isWap) => {
        if (isWap) {
            setFileListWap(newFileList);
        } else {
            setFileList(newFileList);
        }
    };

    const onChangeImgUrl = async (url, isWap) => {
        if (isWap) {
            setImgUrlWap(url);
        } else {
            setImgUrl(url);
        }
    };

    const save = async (params, id) => {
        const ret = await reqestApi(`/api/mis/exchange/store/${id?id:""}`, "POST", params);
        if(ret.data && ret.data.code === 0){
            return ret.data.data.id
        }else{
            message.error("保存失败！")
            return false;
        }
    }

    const commit = async (id) => {
        const ret = await reqestApi(`/api/mis/exchange/commitcheck/${id}`, "GET");
        if(ret.data && ret.data.code === 0){
            // message.success("提交成功！");
            return true
        }else{
            message.error("提交失败！");
            return false
        }
    }

    const check = async (id, params) => {
        const ret = await reqestApi(`/api/mis/exchange/check/${id}`, "POST", params);
        if(ret.data && ret.data.code === 0){
            message.success("提交审核成功！");
            return true
        }else{
            message.error("提交审核失败！");
            return false
        }
    }

    return{
        isUpload,
        save,
        commit,
        check,
        changeIsUpload,
        fileList,
        setFileList,
        onChangeFileList,
        imgRules,
        changeImgRules,
        onChangeImgUrl,
        imgUrl,
        setImgUrl,
        imgUrlWap,
        setImgUrlWap,
    }
}

export default createModel(useDetail)