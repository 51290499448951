import { GetApiHost } from "../conf/app"

/**字符串按字节数切割 */
export const cutStr = (str, len) => {
  var result = ""
  var strlen = str.length // 字符串长度
  var chrlen = str.replace(/[^x00-\xff]/g, "**").length // 字节长度

  if (chrlen <= len) {
    return str
  }

  for (var i = 0, j = 0; i < strlen; i++) {
    var chr = str.charAt(i)
    if (/[x00-\xff]/.test(chr)) {
      j++ // ascii码为0-255，一个字符就是一个字节的长度
    } else {
      j += 2 // ascii码为0-255以外，一个字符就是两个字节的长度
    }
    if (j <= len) {
      // 当加上当前字符以后，如果总字节长度小于等于L，则将当前字符真实的+在result后
      result += chr
    } else {
      // 反之则说明result已经是不拆分字符的情况下最接近L的值了，直接返回
      return result
    }
  }
}

export const getUUId = function () {
  let UUID = require("uuid")
  return UUID.v1()
}

export const decodeContent = (content) => {
  try {
    if (content) {
      return decodeURI(content)
    }
  } catch (ex) {
    return content
  }
  return content
}

export const getBroswer = () => {
  var sys = {}

  var ua = navigator.userAgent.toLowerCase()

  var s = (s = ua.match(/edge\/([\d.]+)/))
    ? (sys.edge = s[1])
    : (s = ua.match(/rv:([\d.]+)\) like gecko/))
    ? (sys.ie = s[1])
    : (s = ua.match(/msie ([\d.]+)/))
    ? (sys.ie = s[1])
    : (s = ua.match(/firefox\/([\d.]+)/))
    ? (sys.firefox = s[1])
    : (s = ua.match(/chrome\/([\d.]+)/))
    ? (sys.chrome = s[1])
    : (s = ua.match(/opera.([\d.]+)/))
    ? (sys.opera = s[1])
    : (s = ua.match(/version\/([\d.]+).*safari/))
    ? (sys.safari = s[1])
    : 0

  if (sys.edge) return { broswer: "Edge", version: sys.edge }
  if (sys.ie) return { broswer: "IE", version: sys.ie }
  if (sys.firefox) return { broswer: "Firefox", version: sys.firefox }
  if (sys.chrome) return { broswer: "Chrome", version: sys.chrome }
  if (sys.opera) return { broswer: "Opera", version: sys.opera }
  if (sys.safari) return { broswer: "Safari", version: sys.safari }

  return { broswer: "", version: "0" }
}
