import React, {useState, useEffect, useRef} from "react";
import {
  Form,
  Input,
  Select,
  Button,
  Space,
  Upload,
  Breadcrumb,
  message,
  Drawer,
  DatePicker,
  Tag,
  Modal
} from "antd";
import { SylEditor } from '@syllepsis/access-react';
import { SylEditorConfig, SylEventChannel } from "../../untils/SylEditorConfig";
import {UploadOutlined} from "@ant-design/icons";
import ArticleAdd from "../../models/article/add";
import {getProvinces} from "../../untils/citydata";
import {getToken} from "../../untils/tokenHelper";
import {GetUploadUrl} from "../../conf/app";
import {decodeContent, getBroswer} from "../../untils/commHelper";
import moment from "moment";
import "../../static/css/pages/article/add.css";
import "react-quill/dist/quill.snow.css";
import example from "../../static/images/example.png"
import Operation from "antd/lib/transfer/operation";
import ArticleEdit from "../../models/article/add";

const {Option} = Select;

function Add(props) {
  const formRef = React.createRef();
  const quillRef = useRef();
  const uploadRef = useRef();
  const [visible, setVisible] = useState(false);
  const [fileFlag, setFileFlag] = useState(true);
  const [fileFlagWap, setFileFlagWap] = useState(true);
  const [wapPicFlag, setWapPicFlag] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [form] = Form.useForm();
  const savebtn = useRef();
  const editor = useRef();
  const [editeData, changeEditoData] = useState(() => "");
  const [contentText, changeContentText] = useState(() => "");
  const {
    categoryTwo,
    article,
    addArticle,
    fields,
    imgUrl,
    imgUrlWap,
    imgRules,
    fileList,
    fileListWap,
    isUpload,
    areaRules,
    changeImgRules,
    changeAreaRules,
    changeIsUpload,
    setFileList,
    onChangeFileList,
    getCategoryTwo,
    onChangeImgUrl,
    commit,
    setImgUrl,
  } = ArticleEdit();
  useEffect(() => {
    setImgUrl("")
    setFileList([]);
    window.document.title = "新建图文";
  }, []);
  const getEditor = async (SylApi) => {
    SylApi.on(SylEventChannel.LocalEvent.TEXT_CHANGE, async () => {
      let content = SylApi.getHTML();
      let contentText = SylApi.text;
      changeContentText(contentText);
      changeEditoData(content);
    });
  };

  const formItemLayout = {
    labelCol: {span: 4},
    wrapperCol: {span: 18},
  };

  async function save() {
    if (!formRef.current.getFieldValue("title") || !formRef.current.getFieldValue("article_type") || !formRef.current.getFieldValue("cate") || !formRef.current.getFieldValue("article_source") || !formRef.current.getFieldValue("abstract") || !formRef.current.getFieldValue("public_date")) {
      message.error("请编辑必填内容！");
      return;
    }
    if (formRef.current.getFieldValue("cate") == "local_dynamic" && !formRef.current.getFieldValue("province_id")) {
      message.error("请编辑地方选择！");
      return
    }
    if (formRef.current.getFieldValue("article_type") == 1 && !imgUrl) {
      message.error("请上传图片摘要！");
      return
    }
    let params = {};
    params.title = formRef.current.getFieldValue("title");
    params.cate = formRef.current.getFieldValue("cate");
    params.img_url = imgUrl;
    params.h5_image = imgUrlWap;
    params.abstract = formRef.current.getFieldValue("abstract");
    params.article_type = formRef.current.getFieldValue("article_type");
    //params.key_words = vals.key_words
    params.article_source = formRef.current.getFieldValue("article_source");
    //params.author = vals.author
    //区域设置
    params.province_id = formRef.current.getFieldValue("province_id");

    params.public_date = moment(formRef.current.getFieldValue("public_date")).format("YYYY-MM-DD");
    if (formRef.current.getFieldValue("category_one") > 0) {
      params.category_one = formRef.current.getFieldValue("category_one");
      if (formRef.current.getFieldValue("category_one") > 0) {
        params.category_two = formRef.current.getFieldValue("category_two");
      } else {
        message.warn("请选择具体文章二级分类");
        // savebtn.current.disabled = false;
        return;
      }
    }

    if (!editeData) {
      message.warn("文章内容不能为空");
      // savebtn.current.disabled = false;
      return;
    }
    params.summary = contentText;
    params.content = encodeURI(editeData);
    console.log(params)
    let res = await addArticle(params);
    return res
  }

  return (
    <div className="main-article">
      <Breadcrumb style={{padding: "16px", background: "#F5F5F5"}}>
        <Breadcrumb.Item>资讯管理</Breadcrumb.Item>
        <Breadcrumb.Item>新建图文</Breadcrumb.Item>
      </Breadcrumb>
      <div className="main-add">
        <Form
          ref={formRef}
          fields={fields}
          {...formItemLayout}
          onFinish={async (vals) => {
            // savebtn.current.disabled = true;
            let res = await save();
            if (res && res.data.code === 0) {
              if (await commit(res.data.data.id) === 0) {
                // savebtn.current.disabled = false;
                props.history.push('/main/list')
              }
            }
            // savebtn.current.disabled = false;
          }}
        >
          <Form.Item
            label="标题"
            name="title"
            rules={[
              {
                required: true,
                message: "请填写标题",
                type: "string",
              },
            ]}
          >
            <Input autocomplete="off"/>
          </Form.Item>
          <Form.Item
            name="article_type"
            label="文章类型"
            rules={[
              {
                required: true,
                message: "选择文章类型",
                type: "string",
              },
            ]}
          >
            <Select
              onChange={(e) => {
                if (e == 2) {
                  changeIsUpload(true);
                  setFileList([]);
                  setFileFlag(true);
                } else {
                  changeIsUpload(false);
                }
                if (e == 1) {
                  changeImgRules([
                    {
                      required: true,
                      message: "请上传图片摘要",
                    },
                  ])
                } else {
                  changeImgRules([])
                }
              }}
              placeholder="请选择"
              style={{width: "240px"}}
            >
              <Option value="1">图文</Option>
              <Option value="2">文本</Option>
              {/* <Option value="3">文件</Option> */}
              {/*<Option value="4">视频</Option>*/}
            </Select>
          </Form.Item>
          <div
            style={{
              // marginLeft: "16px"
              position: "absolute",
              left: "650px",
              top: "210px",
              fontSize: "12px",
              color: "#9a9a9a"
            }}
          >
            如需添加“图片”摘要，请选择“图文”类型
          </div>
          <Form.Item
            name="cate"
            label="频道"
            rules={[
              {
                required: true,
                message: "请选择频道",
                type: "string",
              },
            ]}
          >
            <Select
              onChange={(e) => {
                // 当是首页或者官方咨询页时，出现wap图片上传功能
                if (e === "index_carousel" || e === "official_information") {
                  setWapPicFlag(true);
                } else {
                  setWapPicFlag(false);
                }

                if (e === "local_dynamic") {
                  changeAreaRules([
                    {
                      required: true,
                      message: "请选择地方频道",
                      type: "string",
                    },
                  ]);
                } else {
                  changeAreaRules([]);
                }
              }}
              placeholder="请选择"
              style={{width: "240px"}}
            >
              <Option value="health_life">健康科普-健康科普</Option>
              <Option value="local_dynamic">地方动态</Option>
              <Option value="index_carousel">首页-头图</Option>
              <Option value="official_information">权威信息-官方资讯</Option>
               <Option value="china_article">重大行动</Option>
            </Select>
          </Form.Item>

          <Form.Item
            rules={[
              {
                required: true,
                message: "请填写资讯时间",
              },
            ]}
            label="资讯时间"
            name="public_date"
          >
            <DatePicker/>
          </Form.Item>
          <Form.Item label="文章分类">
            <Space>
              <Form.Item name="category_one">
                <Select
                  placeholder="请选择"
                  onChange={(e) => {
                    console.log("EDDD:", e);
                    getCategoryTwo(e);
                  }}
                  style={{width: "240px"}}
                >
                  <Option value="0">不设置分类</Option>
                  <Option value="1">健康生活因素</Option>
                  <Option value="2">重点人群</Option>
                  <Option value="3">重大疾病</Option>
                  <Option value="4">其它</Option>
                </Select>
              </Form.Item>

              <Form.Item name="category_two">
                <Select placeholder="请选择" style={{width: "240px"}}>
                  {categoryTwo.map((citem, cindex) => {
                    return (
                      <Option key={"op_" + cindex} value={citem.value}>
                        {citem.lable}
                      </Option>
                    );
                  })}
                </Select>
              </Form.Item>
            </Space>
            {categoryTwo.length > 0 && (
              <Tag style={{marginLeft: "16px"}} color="#f50">
                请完成二级分类选择
              </Tag>
            )}
          </Form.Item>
          <Form.Item name="province_id" label="地方选择" rules={areaRules}>
            {/* <Space>
              <Form.Item> */}
            <Select placeholder="省（直辖市）" style={{width: "240px"}}>
              {getProvinces().map((litem) => {
                return (
                  <Option value={litem.value} key={litem.value}>
                    {litem.label}
                  </Option>
                );
              })}
            </Select>
            {/* </Form.Item> */}
            {/* <Form.Item name="category_two">
                <Select
                  placeholder="市（区）"
                  style={{ width: "240px" }}
                ></Select>
              </Form.Item> */}
            {/* </Space> */}
          </Form.Item>

          <Form.Item name="picture" label="图片摘要"
                     rules={imgRules}
          >
            <Upload
              disabled={isUpload}
              name="file"
              headers={{token: getToken()}}
              fileList={fileList}
              listType="picture-card"
              className="avatar-uploader"
              action={GetUploadUrl()}
              onChange={(e) => {
                onChangeFileList(e);
                try {
                  let ret = e.fileList[0].response;
                  if (ret.code == 0) {
                    onChangeImgUrl(ret.data);
                    setFileFlag(false);
                  } else {
                    message.error("图片上传失败");
                  }
                } catch (ex) {
                  // message.error("图片上传失败")
                }
              }}
              onPreview={async (file) => {
                let src = file.url;
                if (!src) {
                  src = await new Promise((resolve) => {
                    const reader = new FileReader();
                    reader.readAsDataURL(file.originFileObj);
                    reader.onload = () => resolve(reader.result);
                  });
                }
                const image = new Image();
                image.src = src;
                const imgWindow = window.open(src);
                imgWindow.document.write(image.outerHTML);
              }}
              onRemove={(e) => {
                onChangeImgUrl("");
                setFileFlag(true);
              }}
            >
              {fileFlag && (
                <div className={isUpload == true ? "disableupload" : ""}>
                  <UploadOutlined/>
                  <div className="ant-upload-text">上传</div>
                </div>
              )}
            </Upload>
          </Form.Item>
          <div
            style={{
              position: "absolute",
              left: "500px",
              top: "595px",
              fontSize: "12px",
              color: "#9a9a9a"
            }}
          >
            <span>首页头图建议尺寸为1080*360且尾部326*360需要做模糊处理、官方资讯头图建议尺寸为710*340</span>
            <span style={{marginLeft: "15px", color: "rgb(24,144,255)", cursor: "pointer"}} onClick={() => {
              setModalVisible(true)
            }}>查看首页头图示例</span>
          </div>
          {/*{wapPicFlag && (*/}
          {/*    <Form.Item label="移动端图片摘要">*/}
          {/*        <Tag*/}
          {/*            style={{*/}
          {/*                marginBottom: "16px",*/}
          {/*            }}*/}
          {/*            color="#f50"*/}
          {/*        >*/}
          {/*            移动端首页和官方咨询页头图，建议长宽比（2:1）*/}
          {/*        </Tag>*/}
          {/*        <Upload*/}
          {/*            disabled={isUpload}*/}
          {/*            name="file"*/}
          {/*            headers={{token: getToken()}}*/}
          {/*            fileList={fileListWap}*/}
          {/*            listType="picture-card"*/}
          {/*            className="avatar-uploader"*/}
          {/*            action={GetUploadUrl()}*/}
          {/*            onChange={(e) => {*/}
          {/*                onChangeFileList(e, true);*/}
          {/*                try {*/}
          {/*                    let ret = e.fileList[0].response;*/}
          {/*                    if (ret.code == 0) {*/}
          {/*                        onChangeImgUrl(ret.data, true);*/}
          {/*                        setFileFlagWap(false);*/}
          {/*                    } else {*/}
          {/*                        message.error("图片上传失败");*/}
          {/*                    }*/}
          {/*                } catch (ex) {*/}
          {/*                    // message.error("图片上传失败")*/}
          {/*                }*/}
          {/*            }}*/}
          {/*            onPreview={async (file) => {*/}
          {/*                let src = file.url;*/}
          {/*                if (!src) {*/}
          {/*                    src = await new Promise((resolve) => {*/}
          {/*                        const reader = new FileReader();*/}
          {/*                        reader.readAsDataURL(file.originFileObj);*/}
          {/*                        reader.onload = () => resolve(reader.result);*/}
          {/*                    });*/}
          {/*                }*/}
          {/*                const image = new Image();*/}
          {/*                image.src = src;*/}
          {/*                const imgWindow = window.open(src);*/}
          {/*                imgWindow.document.write(image.outerHTML);*/}
          {/*            }}*/}
          {/*            onRemove={(e) => {*/}
          {/*                onChangeImgUrl("", true);*/}
          {/*                setFileFlagWap(true);*/}
          {/*            }}*/}
          {/*        >*/}
          {/*            {fileFlagWap && (*/}
          {/*                <div className={isUpload == true ? "disableupload" : ""}>*/}
          {/*                    <UploadOutlined/>*/}
          {/*                    <div className="ant-upload-text">上传</div>*/}
          {/*                </div>*/}
          {/*            )}*/}
          {/*        </Upload>*/}
          {/*    </Form.Item>*/}
          {/*)}*/}

          <Form.Item
            rules={[
              {
                required: true,
                message: "请填写文章来源机构",
              },
            ]}
            label="文章来源机构"
            name="article_source"
          >
            {/* <Select placeholder="请选择文章来源" style={{ width: "240px" }}>
              <Option value="健康报社">健康报社</Option>
              <Option value="国家卫生健康委">国家卫生健康委</Option>
              <Option value="人民网">人民网</Option>
              <Option value="国务院">国务院</Option>
            </Select> */}
            <Input style={{width: "240px"}}/>
          </Form.Item>

          <Form.Item label="文章来源URL" name="article_source_url">
            <Input/>
          </Form.Item>
          <Form.Item
            label="文字摘要"
            rules={[
              {
                required: true,
                message: "请填写文字摘要",
                type: "string",
              },
            ]}
            name="abstract"
          >
            <Input.TextArea style={{height: "80px"}}/>
          </Form.Item>

          <Form.Item
            label="内容"
            rules={[{required: true, message: "文章内容不能为空"}]}
          >
            <div className="edit-box">
              {/* <ReactQuill
                ref={quillRef}
                theme="snow"
                modules={modules}
                // formats={this.formats}
                value={decodeContent(article.content)}
                className="txt-edit"
                onChange={(content, delta, source, editor) => {
                  let txt = editor.getText(0, editor.getLength());
                  //onChangeSummary(txt)
                  onChangeArticle(content, txt);
                }}
              /> */}
              <SylEditor
                getEditor={getEditor}
                onToolClick={(SylApi, toolName) => {
                  console.log("工具栏按钮触发", SylApi, toolName);
                }}
                ref={editor}
                autoFocus={true}
                placeholder="请输入正文内容..."
                plugins={SylEditorConfig.Plugins}
                module={SylEditorConfig.Modules}
              />
            </div>
          </Form.Item>
          <Form.Item
            style={{marginTop: "16px"}}
            wrapperCol={{
              span: 12,
              offset: 4,
            }}
          >
            <div>
              <Space>
                {/* disabled={saveDisabled} */}
                <Button ref={savebtn} htmlType="submit" type="primary">
                  提交
                </Button>
                <Button type="primary" onClick={async () => {
                  console.log(imgUrl)
                  let res = await save();
                  if (res && res.data.code === 0) {
                    props.history.push('/main/list')
                  }
                }}>
                  保存
                </Button>
                <Button
                  type="primary"
                  onClick={() => {
                    setVisible(true);
                  }}
                >
                  预览
                </Button>
              </Space>
            </div>
          </Form.Item>
        </Form>
      </div>

      <Drawer
        destroyOnClose={true}
        key={"hhhss"}
        title={
          <span>
            <b>文章预览</b>
          </span>
        }
        width={1080}
        onClose={() => {
          setVisible(false);
        }}
        visible={visible}
      >
        <div className="preview-box" style={{padding: "16px 40px"}}>
          <div
            className="preview-title"
            style={{
              fontWeight: "700",
              fontSize: "20px",
              padding: "16px 0",
              textAlign: "center",
            }}
          >
            {article.title}
          </div>
          <div
            style={{marginTop: "16px"}}
            className="preview-content"
            // article.content
            dangerouslySetInnerHTML={{__html: decodeContent(editeData)}}
          ></div>
        </div>
      </Drawer>

      <Modal
        title="查看首页头图示例"
        visible={modalVisible}
        onOk={() => {
          setModalVisible(false)
        }}
        onCancel={() => {
          setModalVisible(false)
        }}
        okText="我知道了"
      >
        <img style={{width: "100%", height: "100%", display: "block"}} src={example} alt=""/>
      </Modal>
    </div>
  );
}

export default Add;
