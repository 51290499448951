import { GetApiHost, GetUploadUrl } from "../conf/app";
import axios from "axios";
import qs from "qs";
import { message } from "antd";
import CryptoJS from "crypto-js";
function aseEncrypt(msg, key) {
  key = PaddingLeft(key, 16); //保证key的长度为16byte,进行'0'补位
  key = CryptoJS.enc.Utf8.parse(key);
  var encrypted = CryptoJS.AES.encrypt(msg, key, {
    iv: key,
    mode: CryptoJS.mode.CBC, // CBC算法
    padding: CryptoJS.pad.Pkcs7, //使用pkcs7 进行padding 后端需要注意
  });
  return encrypted.ciphertext.toString(CryptoJS.enc.Hex); //后端必须进行相反操作
}
export const getToken = () => {
  let timestamp = Math.round(new Date().getTime() / 1000);
  let msg = timestamp + ";e22674779c464244";
  return aseEncrypt(msg, "186133958051");
};
function PaddingLeft(key, length) {
  let pkey = key.toString();
  let l = pkey.length;
  if (l < length) {
    pkey = new Array(length - l + 1).join("0") + pkey;
  } else if (l > length) {
    pkey = pkey.slice(length);
  }
  return pkey;
}
export const reqestApi = async (url, method, params) => {
  try {
    let authorization = localStorage.getItem("authorization");
    let httpDefault = {
      method: method,
      baseURL: GetApiHost(),
      url: url,
      params: method === "GET" || method === "DELETE" ? params : null,
      data: method === "POST" || method === "PUT" ? qs.stringify(params) : null,
      timeout: 1000 * 60 * 10,
      withCredentials: true,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization: authorization ? authorization : "",
        Origin: 'https://api-test.jkzgxd.cn/',
      },
    };
    if (url === "/pub/uploadimage" || url === "/pub/uploadvideo") {
      httpDefault.headers.token = getToken();
      httpDefault.data = params;
      delete httpDefault.headers.Authorization;
      httpDefault.url =
        "https://healthchina-doctor-mis-api.xiaohe-jiankang.com" + url;
    }
    let ret = await axios(httpDefault);

    // 400001     请登录
    // 400002     无权限

    console.log("中间间拦截：", ret.data);
    if (ret.data.code == "400001") {
      window.location.href = "/login";
      return;
    } else if (ret.data.code == "400002") {
      message.info("当前无权限操作");
    } else if(ret.data.code=="400003"){
      message.error(ret.data.msg);
    }
    return ret;
  } catch (ex) {
    // message.warn("登录已经过期", 1.5, () => {
    //   window.location.href = "/login";
    // });
  }
};

export const UploadFile = async (formData) => {
  return axios.post(GetUploadUrl(), formData, {
    withCredentials: true,
    headers: {
      "Content-Type": "multipart/form-data;charset=UTF-8",
      token: getToken(),
    },
  });
};
