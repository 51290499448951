import React, { useState, useEffect } from "react";
import {
    Breadcrumb,
    Form,
    Input,
    Select,
    Button, Table,
    Modal,
    Pagination
} from "antd";
import PeopleModel from "../../models/system/people";

const { Column } = Table;
const { Option } = Select;

function People(props){
    const formRef = React.createRef();
    const resetRef = React.createRef();
    const [form,setForm]=useState({
        role_name:props.location.state?props.location.state.role:""
    })
    const [resetVisible,setResetVisible]=useState(false)
    const [removeVisible,setRemoveVisible]=useState(false)
    const [selectedUser,setSelectedUser]=useState()
    const [loading, setLoading] = useState(false);

    const {
        list,
        page,
        totalCount,
        mechanismList,
        search,
        remove,
        resetPassword,
        deactive,
        enable,
        getMechanismList,
        roleList,
        getRoleList
    }=PeopleModel()

    async function searchList(page,val){
        setForm(val);
        setLoading(true)
        await search(page, val);
        setLoading(false)
    }

    useEffect(()=>{
        document.title = "人员管理"
        getRoleList()
        getMechanismList()
        searchList(parseInt(props.location.state?props.location.state.page:1),form);
    },[])

    return(
        <div>
            <Breadcrumb style={{ padding: "16px", background: "#F5F5F5" }}>
                <Breadcrumb.Item>系统管理</Breadcrumb.Item>
                <Breadcrumb.Item>机构管理</Breadcrumb.Item>
            </Breadcrumb>
            <div>
                <div style={{padding:"30px 0 0 30px"}}>
                    <Button onClick={()=>{
                        props.history.push({pathname:"/main/addpeople",state:{list:JSON.stringify(mechanismList)}})
                    }} type="primary">添加人员</Button>
                </div>
                <div style={{padding:"30px"}}>
                    <Form ref={formRef} layout="inline" onFinish={(val)=>{searchList(1,val)}}>
                        <Form.Item
                            label="手机号"
                            name="mobile"
                            style={{width:"240px",marginBottom:"10px"}}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="姓名"
                            name="name"
                            style={{width:"240px",marginBottom:"10px"}}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            style={{width:"240px",marginBottom:"10px"}}
                            label="机构名称"
                            name="mechanism_name"
                        >
                            <Select>
                                {
                                    mechanismList.map(item=>{
                                        return <Option key={item.name} value={item.name}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            style={{width:"240px",marginBottom:"10px"}}
                            initialValue={props.location.state?props.location.state.role:""}
                            label="角色名称"
                            name="role_name"
                        >
                            <Select style={{
                                width: 170
                            }}>
                                {
                                    roleList.map(item => {
                                        return <Option value={item.name}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            style={{width:"240px",marginBottom:"10px"}}
                            label="人员状态"
                            name="status"
                        >
                            <Select>
                                <Option value={1}>启用</Option>
                                <Option value={2}>停用</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <div>
                                <Button type="primary" htmlType="submit">
                                    查询
                                </Button>
                            </div>
                        </Form.Item>
                        <Form.Item>
                            <div>
                                <Button onClick={()=>{
                                    formRef.current.resetFields();
                                    formRef.current.setFieldsValue({role_name:""})
                                }}>
                                    重置查询条件
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
                <div style={{paddingBottom:"30px"}}>
                    <Table dataSource={list} pagination={false} bordered={true} scroll={{x:1300}} loading={loading}>
                        <Column
                            align="center"
                            title="手机号"
                            dataIndex="mobile"
                        />
                        <Column
                            align="center"
                            title="姓名"
                            dataIndex="user_name"
                        />
                        <Column
                            align="center"
                            title="角色"
                            dataIndex="role_name"
                        />
                        <Column
                            align="center"
                            title="所属机构"
                            dataIndex="mechanism_name"
                        />
                        <Column
                            align="center"
                            title="状态"
                            dataIndex="status"
                            render={(status)=>{
                                return(
                                    <div>{status===1?"启用":"停用"}</div>
                                )
                            }}
                        />
                        <Column
                            align="center"
                            title="操作"
                            render={(item,record)=>{
                                return(
                                    <div>
                                        <span style={{marginRight:'15px',color:"rgb(135,199,241)",cursor:"pointer"}} onClick={()=>{
                                            props.history.push({pathname:"/main/addpeople",state:{info:JSON.stringify(record),list:JSON.stringify(mechanismList),page:page}})
                                        }}>编辑</span>
                                        <span style={{marginRight:'15px',color:"rgb(135,199,241)",cursor:"pointer"}} onClick={()=>{
                                            if(record.status===1){
                                                deactive(record.user_id,form)
                                            }else{
                                                enable(record.user_id,form)
                                            }
                                        }}>{record.status===1?"停用":"启用"}</span>
                                        <span style={{marginRight:'15px',color:"rgb(135,199,241)",cursor:"pointer"}} onClick={()=>{
                                            setSelectedUser(record);
                                            setRemoveVisible(true);
                                        }}>移除</span>
                                        <span style={{color:"rgb(135,199,241)",cursor:"pointer"}} onClick={()=>{
                                            setSelectedUser(record);
                                            setResetVisible(true)
                                        }}>重置密码</span>
                                    </div>
                                )
                            }}
                        />
                    </Table>
                    <Pagination
                        pageSizeOptions={["10"]}
                        pageSize={10}
                        defaultCurrent={1}
                        current={page}
                        total={totalCount}
                        showTotal={total => `共 ${total} 条`}
                        style={{textAlign:"right",paddingRight:"30px",marginTop:"20px"}}
                        onChange={(e) => {
                            searchList(e, form);
                        }}
                    />
                </div>
            </div>
            <Modal
                title="重置密码"
                visible={resetVisible}
                onCancel={()=>{setResetVisible(false);resetRef.current.resetFields();}}
                footer=""
            >
                <p style={{marginBottom:"20px"}}>你正在为{selectedUser?selectedUser.mechanism_name:""}的{selectedUser?selectedUser.user_name:""}重置密码</p>
                <Form
                    ref={resetRef}
                    name="basic"
                    initialValues={{ remember: true }}
                    onFinish={(val)=>{
                        resetPassword(selectedUser?selectedUser.user_id:"",val);
                        resetRef.current.resetFields();
                        setResetVisible(false);
                    }}
                >
                    <Form.Item
                        label="请输入新密码"
                        name="password"
                        initialValue={""}
                        rules={[{ required: true }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="请再次输入新密码"
                        name="confirm_pwd"
                        initialValue={""}
                        rules={[{ required: true}]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item style={{textAlign:"center"}}>
                        <Button style={{marginRight:"20px"}} type="primary" htmlType="submit">
                            确定
                        </Button>
                        <Button onClick={()=>{setResetVisible(false);resetRef.current.resetFields();}}>
                            取消
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            <Modal
                title="移除人员"
                visible={removeVisible}
                onCancel={()=>{setRemoveVisible(false)}}
                onOk={()=>{remove(selectedUser?selectedUser.user_id:"",form);setRemoveVisible(false)}}
            >
                <p>你正在移除{selectedUser?selectedUser.mechanism_name:""}的{selectedUser?selectedUser.user_name:""}</p>
            </Modal>
        </div>
    )

}

export default People