import React, {useState} from "react";
import {createModel} from "hox";
import {reqestApi} from "../../untils/requestHelper";
import {message} from "antd";

function useList() {
    const [page, setPage] = useState([]);
    const [list, setList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [loading, setLoading] = useState(false);

    function getOptions(status) {
        switch (status) {
            case 3:
                return ["审核", "删除"];
            case 4:
                return ["查看", "下线"];
            case 1:
                return ["审核", "删除"];
            case 6:
                return ["审核", "删除"];
            default:
                return [];
        }
    }

    const search = async (page, searchParams, status) => {
        let params = {};
        params.page = page;
        if (status) {
            params.check_status = status;
        }
        for (let key in searchParams) {
            if (searchParams[key]) {
                params[key] = searchParams[key]
            }
        }

        setLoading(true);
        let ret = await reqestApi("/api/mis/nethosp/search", "GET", params);
        setLoading(false)
        if (ret.data.code === 0) {
            setPage(page);
            setTotalCount(ret.data.totalCount)
            const data = ret.data.data.filter(item => {
                return item.check_status !== 9
            })
            data.forEach((item, index) => {
                item.optionList = getOptions(item.check_status);
                item.key = item.id;
                item.index = (page - 1) * 10 + index + 1;
            })
            setList(data)
        }
    }

    const getHospitalInfo = async (id) => {
        let ret = await reqestApi(`/api/mis/nethosp/detail/${id}`, "GET");
        if (ret.data && ret.data.code === 0) {
            return ret.data.data;
        } else {
            message.error("获取案例内容失败！");
            return false;
        }
    }

    const deleteInfo = async (id) => {
        let ret = await reqestApi(`/api/mis/nethosp/delete/${id}`, "GET");
        if (ret.data && ret.data.code === 0) {
            message.success("删除成功");
            return true;
        } else {
            return false;
        }
    }

    const offline = async (id) => {
        let ret = await reqestApi(`/api/mis/nethosp/downline/${id}`, "GET");
        if (ret.data && ret.data.code === 0) {
            message.success("下线成功");
            return true;
        } else {
            return false;
        }
    }

    return {
        page,
        list,
        totalCount,
        loading,
        search,
        getHospitalInfo,
        deleteInfo,
        offline
    }
}

export default createModel(useList)