import React, {useEffect, useState} from "react"
import {
    Breadcrumb,
    Button,
    DatePicker,
    Form,
    Input,
    Select,
    Table,
    Cascader,
    Row,
    Col,
    Pagination,
    message,
    Modal
} from "antd";

import {findDistrictName, areasArray, findLabel} from "../../untils/citydata";
import ListModal from "../../models/covid19/pov"
import {ExclamationCircleOutlined} from "@ant-design/icons";

const {Option} = Select
const {Column} = Table
const {confirm} = Modal

function PovList(props){
    const formRef = React.createRef();

    const [params, setParams] = useState({});

    const {
        page,
        list,
        totalCount,
        search,
        offline,
        deleteCase,
        getInfo,
        online,
        loading
    } = ListModal()

    useEffect(() => {
        document.title = "pov管理"
        search(1, params)
    }, [])

    return (
        <div>
            <Breadcrumb style={{padding: "16px", background: "#F5F5F5"}}>
                <Breadcrumb.Item>新冠疫苗专题管理</Breadcrumb.Item>
                <Breadcrumb.Item>POV管理</Breadcrumb.Item>
            </Breadcrumb>
            <div>
                <div  style={{padding: "32px 16px 8px 16px"}}>
                    <Form
                        ref={formRef}
                        layout="inline"
                        onFinish={(val) => {
                            if(val.location){
                                const labelList = findLabel(val.location)
                                val.province = labelList[0];
                                val.city = labelList[1];
                                val.district = labelList[2];
                            }
                            setParams(val);
                            search(1, val)
                        }}
                    >
                        <Form.Item name="pov_name" label="机构名" style={{width: "240px",marginBottom:"10px"}}>
                            <Input/>
                        </Form.Item>
                        <Form.Item name="location" label="地方" style={{width: "240px", marginBottom: "10px"}}>
                            <Cascader options={areasArray}/>
                        </Form.Item>
                        <Form.Item name="is_crash" label="是否临时接种点" style={{width: "240px", marginBottom: "10px"}}>
                            <Select>
                                <Option value={1}>未知</Option>
                                <Option value={2}>是</Option>
                                <Option value={3}>否</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item name="status" label="上线状态" style={{width: "240px", marginBottom: "10px"}}>
                            <Select>
                                <Option value={1}>草稿</Option>
                                <Option value={2}>上线</Option>
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <Button htmlType="submit" type="primary">
                                查找
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" onClick={() => {
                                props.history.push({
                                    pathname:"/main/povedit",
                                    state:{caseInfo: JSON.stringify({})}
                                })
                            }}>
                                新建
                            </Button>
                        </Form.Item>
                        <Form.Item>
                            <div>
                                <Button onClick={() => {
                                    formRef.current.resetFields();
                                }}>
                                    重置查询条件
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
                <div style={{marginTop:30}}>
                    <Table
                        dataSource={list}
                        scroll={{x:  1650, y: "50vh"}}
                        pagination={false}
                        bordered={true}
                        loading={loading}
                    >
                        <Column
                            align="center"
                            title="序号"
                            dataIndex="index"
                            width="80px"
                        />
                        <Column
                            align="center"
                            title="机构名"
                            dataIndex="pov_name"
                            width="200px"
                        />
                        <Column
                            align="center"
                            title="城市"
                            dataIndex="city"
                            width="150px"
                            render={(item, record) => {
                                return <div>{item === "市辖区" ? record.province : item}</div>
                            }}
                        />
                        <Column
                            align="center"
                            title="区（县）"
                            dataIndex="district"
                            width="150px"
                        />
                        <Column
                            align="center"
                            title="地址"
                            dataIndex="adrress"
                            width="200px"
                        />
                        <Column
                            align="center"
                            title="开诊时间"
                            dataIndex="open_time"
                            width="200px"
                        />
                        <Column
                            align="center"
                            title="机构电话"
                            dataIndex="phone_num"
                            width="200px"
                        />
                        <Column
                            align="center"
                            title="上线状态"
                            dataIndex="status"
                            width="100px"
                            render={item => {
                                switch (item){
                                    case 1 :
                                        return <div>草稿</div>
                                    case 2 :
                                        return <div>上线</div>
                                    case 3 :
                                        return <div>删除</div>
                                    default :
                                        return <div>--</div>
                                }
                            }}
                        />
                        <Column
                            align="center"
                            title="是否紧急使用接种点"
                            dataIndex="is_crash"
                            width="100px"
                            render={item => {
                                switch (item){
                                    case 1 :
                                        return <div>未知</div>
                                    case 2 :
                                        return <div>是</div>
                                    case 3 :
                                        return <div>否</div>
                                    default :
                                        return <div>--</div>
                                }
                            }}
                        />
                        <Column
                            align="center"
                            title="操作"
                            dataIndex="mode"
                            width={270}
                            fixed="right"
                            render={(item, record) => {
                                return (
                                    <div
                                        style={{width: "100%", display: "flex", justifyContent: "space-around"}}
                                    >
                                        {record.status === 1 && <Button
                                            onClick={async () => {
                                                if (await online(record.id)) {
                                                    search(page, params);
                                                } else {
                                                    message.error("上线失败");
                                                }
                                            }}
                                        >
                                            上线
                                        </Button>}
                                        {record.status === 2 && <Button
                                            type="primary"
                                            onClick={() => {
                                                confirm({
                                                    title: "提示",
                                                    icon: <ExclamationCircleOutlined/>,
                                                    content: "确定要下线该案例么？",
                                                    async onOk() {
                                                        if (await offline(record.id)) {
                                                            search(page, params);
                                                        } else {
                                                            message.error("下线失败");
                                                        }
                                                    },
                                                    onCancel() {
                                                        console.log("Cancel");
                                                    },
                                                });
                                            }}
                                        >
                                            下线
                                        </Button>}
                                        <Button
                                            onClick={async () => {
                                                const caseInfo = await getInfo(record.id);
                                                if (caseInfo) {
                                                    props.history.push({
                                                        pathname: "/main/povedit",
                                                        state: {caseInfo: JSON.stringify(caseInfo)}
                                                    })
                                                }
                                            }}
                                        >
                                            编辑
                                        </Button>
                                        <Button
                                            danger
                                            type="primary"
                                            onClick={() => {
                                                confirm({
                                                    title: "提示",
                                                    icon: <ExclamationCircleOutlined/>,
                                                    content: "确定要删除该案例么？",
                                                    async onOk() {
                                                        if (await deleteCase(record.id)) {
                                                            search(page, params);
                                                        } else {
                                                            message.error("删除失败");
                                                        }
                                                    },
                                                    onCancel() {
                                                        console.log("Cancel");
                                                    },
                                                });
                                            }}
                                        >
                                            删除
                                        </Button>
                                    </div>
                                )
                            }}
                        />
                    </Table>
                    {totalCount > 0 && (
                        <Row>
                            <Col xs={0} ms={24} md={24} lg={24} xl={24} xxl={24}>
                                <div className="tab-pages">
                                    <Pagination
                                        pageSizeOptions={["10"]}
                                        pageSize={10}
                                        defaultCurrent={1}
                                        current={page}
                                        total={totalCount}
                                        onChange={(e) => {
                                            // setPage(e);
                                            search(e, params);
                                        }}
                                    />
                                </div>
                            </Col>
                        </Row>
                    )}
                </div>
            </div>
        </div>
    )
}

export default PovList