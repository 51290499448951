import React, {useState} from "react";
import {createModel} from "hox";
import {reqestApi, UploadFile} from "../../untils/requestHelper";
import {message} from "antd";

function useAdd() {
    const [categoryTwo, setCategoryTwo] = useState([]);
    const [title, setTitle] = useState("");
    const [contentTxt, setContentTxt] = useState("");
    const [fields, setFields] = useState([]);
    const [imgUrl, setImgUrl] = useState();
    const [imgUrlWap, setImgUrlWap] = useState();
    const [isUpload, changeIsUpload] = useState(() => true);
    const [fileList, setFileList] = useState(() => []);
    const [fileListWap, setFileListWap] = useState(() => []);

    const onChangeFileList = async ({fileList: newFileList}, isWap) => {
        if (isWap) {
            setFileListWap(newFileList);
        } else {
            setFileList(newFileList);
        }
    };

    const [article, setArticle] = useState({
        id: 0,
        title: "",
        cate: "",
        img_url: null,
        abstract: null,
        article_type: 2,
        key_words: [],
        article_source: "",
        author: null,
        public_date: "",
        content: "",
        category_one: null,
        category_two: null,
        summary: "",
    });
    const [anchorJson, setAnchorJson] = useState([]);
    const [areaRules, changeAreaRules] = useState(() => []);
    const [imgRules, changeImgRules] = useState(() => []);
    const categorys = {
        healthLife: [
            {lable: "健康知识普及行动", value: "1"},
            {lable: "合理膳食行动", value: "2"},
            {lable: "全民健身行动", value: "3"},
            {lable: "控烟行动", value: "4"},
            {lable: "心理健康促进行动", value: "5"},
            {lable: "健康环境促进行动", value: "6"},
        ],

        keyGroups: [
            {lable: "妇幼健康促进行动", value: "7"},
            {lable: "中小学健康促进行动", value: "8"},
            {lable: "职业健康保护行动", value: "9"},
            {lable: "老年健康促进行动", value: "10"},
        ],

        disease: [
            {lable: "心脑血管疾病防治行动", value: "11"},
            {lable: "癌症防治行动", value: "12"},
            {lable: "慢性呼吸系统疾病防治行动", value: "13"},
            {lable: "糖尿病防治行动", value: "14"},
            {lable: "传染病及地方病防控行动", value: "15"},
        ],
    };

    const onChangeSummary = async (txt) => {
        //setSummary(txt)
    };

    const eidtImgeUpload = async (formData) => {
        return UploadFile(formData);
    };

    const getCategoryTwo = async (num) => {
        if (num == 1) {
            setCategoryTwo(categorys["healthLife"]);
        } else if (num == 2) {
            setCategoryTwo(categorys["keyGroups"]);
        } else if (num == 3) {
            setCategoryTwo(categorys["disease"]);
        } else {
            setCategoryTwo([]);
        }
    };

    const getArticle = async (id) => {
        let tmpUrl = "/api/mis/article/detail/" + id;
        let ret = await reqestApi(tmpUrl, "GET", {});
        if (ret.data.code == 0) {
            let mode = ret.data.data;
            console.log("MODE:", mode);
            setImgUrl(mode.img_url);
            setImgUrlWap(mode.h5_image);
            setArticle(ret.data.data);
            let tmpArr = [];
            let tmpMode1 = {
                name: ["title"],
                value: mode.title,
            };
            tmpArr.push(tmpMode1);

            let tmpMode2 = {
                name: ["article_type"],
                value: mode.article_type + "",
            };
            tmpArr.push(tmpMode2);

            let tmpMode3 = {
                name: ["cate"],
                value: mode.cate,
            };
            tmpArr.push(tmpMode3);

            let tmpMode4 = {
                name: ["category_one"],
                value: mode.category_one ? mode.category_one + "" : null,
            };
            tmpArr.push(tmpMode4);
            let tmpMode5 = {
                name: ["category_two"],
                value: mode.category_two ? mode.category_two + "" : null,
            };
            tmpArr.push(tmpMode5);

            let tmpMode6 = {
                name: ["abstract"],
                value: mode.abstract,
            };
            tmpArr.push(tmpMode6);

            setFields(tmpArr);
        }
    };

    const addArticle = async (params) => {
        let ret = await reqestApi("/api/mis/article/store", "POST", params);
        console.log("++", params, ret);
        if (ret.data.code === 0) {
            message.success("保存成功", 1.5, () => {
                // window.location.reload(true);
            });
        }
        return ret;
    };

    const commit = async (id) => {
        let ret = await reqestApi(`/api/mis/article/commitcheck/${id}`, "GET");
        if (ret.data.code === 0) {
            message.success("提交成功")
        } else {
            message.error("提交失败")
        }
        return ret.data.code;
    }

    const onChangeArticle = async (content, txt) => {
        let tmp = article;
        tmp.content = content;
        tmp.summary = txt;
        setArticle(tmp);
    };
    const onChangeImgUrl = async (url, isWap) => {
        if (isWap) {
            setImgUrlWap(url);
        } else {
            setImgUrl(url);
        }
    };

    const onAddAnchor = async (paragName, anchorName) => {
        let mode = {};
        mode.lable = paragName;
        mode.value = anchorName;
        let tmpArr = anchorJson;
        tmpArr.push(mode);
        setAnchorJson(tmpArr);
    };

    return {
        categoryTwo,
        article,
        title,
        fields,
        imgUrl,
        imgUrlWap,
        contentTxt,
        anchorJson,
        isUpload,
        fileList,
        areaRules,
        imgRules,
        changeAreaRules,
        changeImgRules,
        onChangeFileList,
        setFileList,
        setFileListWap,
        changeIsUpload,
        onAddAnchor,
        eidtImgeUpload,
        onChangeImgUrl,
        getArticle,
        getCategoryTwo,
        addArticle,
        onChangeArticle,
        commit,
        setImgUrl,
    };
}

export default createModel(useAdd);
