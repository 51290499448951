import React, {useState, useRef} from "react";
import {createModel} from "hox";
import {reqestApi} from "../../untils/requestHelper";
import {message} from "antd";

function useList() {
    const [article, setArticle] = useState({});
    const [page, setPage] = useState([]);
    const [list, setList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [detail, setDetail] = useState({});
    const [fixedWidth, setFixedWidth] = useState(0);
    const [loading, setLoading] = useState(false);

    const role = useRef(localStorage.getItem("role"));

    function getOptions(articleStatus) {
        switch (articleStatus) {
            case 9:
                return ["提交", "编辑", "删除"];
            case 3:
                return ["提交", "编辑", "删除"];
            case 4:
                return ["下线"];
            case 1:
                return role.current == 7 ? [] : ["审核"];
            default:
                return [];
        }
    }

    const search = async (page, searchParams, status) => {
        let params = {};
        params.page = page;
        if (status) {
            params.check_status = status;
        }
        for (let key in searchParams) {
            if (searchParams[key]) {
                params[key] = searchParams[key]
            }
        }
        setLoading(true);
        let ret = await reqestApi("/api/mis/vaccine_article/search", "GET", params);
        setLoading(false)
        if (ret.data.code === 0) {
            setPage(page);
            setTotalCount(ret.data.totalCount)
            // let maxWidth = 0;
            ret.data.data.forEach((item, index) => {
                item.optionList = getOptions(item.check_status);
                item.key = item.id;
                item.index = (page - 1) * 10 + index + 1;
                // maxWidth = item.optionList.length > maxWidth ? item.optionList.length : maxWidth;
            })
            // setFixedWidth(maxWidth * 64 + 144)
            setList(ret.data.data)
        }
    }

    const offline = async (id) => {
        let ret = await reqestApi(`/api/mis/vaccine_article/downline/${id}`, "GET");
        return !!(ret.data && ret.data.code === 0);
    }

    const commit = async (id) => {
        let ret = await reqestApi(`/api/mis/vaccine_article/commitcheck/${id}`, "GET");
        return !!(ret.data && ret.data.code === 0);
    }

    const deleteCase = async (id) => {
        let ret = await reqestApi(`/api/mis/vaccine_article/delete/${id}`, "GET");
        return !!(ret.data && ret.data.code === 0);
    }

    const getInfo = async (id) => {
        let ret = await reqestApi(`/api/mis/vaccine_article/detail/${id}`, "GET");
        if (ret.data && ret.data.code === 0) {
            setArticle(ret.data.data)
            return ret.data.data;
        } else {
            message.error("获取案例内容失败！");
            return false;
        }
    }

    return {
        article,
        page,
        list,
        totalCount,
        search,
        offline,
        commit,
        deleteCase,
        getInfo,
        detail,
        fixedWidth,
        loading
    }
}

export default createModel(useList)