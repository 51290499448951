import React, {useEffect, useState} from "react";
import {
    Layout,
    Menu,
    Breadcrumb,
    Row,
    Col,
    Avatar,
    Space,
    Drawer,
    Dropdown,
    Modal,
    Form,
    Input,
    Button
} from "antd";
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    ReconciliationOutlined,
    LoginOutlined,
    UserOutlined,
    MobileOutlined,
    SettingOutlined,
    DesktopOutlined,
} from "@ant-design/icons";
import layoutModel from "../models/layout";
import {Route} from "react-router-dom";
import {getMenuKey} from "../untils/system";
import Index from "../pages/index";
import ArticleAdd from "../pages/article/add";
import ArticleEdit from "../pages/article/edit";
import ArticleList from "../pages/article/list";
import Department from "../pages/system/department";
import Roles from "../pages/system/roles";
import People from "../pages/system/people";
import ArticleDetial from "../pages/article/detial";
import Test from "../pages/test/index";
import TEdit from "../pages/test/edit";
import OperateLog from "../pages/system/operateLog";
import AddPeople from "../pages/system/addPeople";
import Review from "../pages/article/review";
import Check from "../pages/article/check";
import HealthPlatform from "../pages/platform";
import PlatformCase from "../pages/platform/detail";
import InternetHospital from "../pages/internet_hospital";
import HospitalDetail from "../pages/internet_hospital/detail";
import CovidNews from "../pages/covid19/news";
import PovList from "../pages/covid19/pov";
import NewsEdit from "../pages/covid19/newsEdit";
import PovEdit from "../pages/covid19/povEdit";
import EpidemicDetail from "../pages/epidemic/detail";
import EpidemicList from "../pages/epidemic";
import NewVideo from "../pages/article/newVideo";
import CheckVideo from "../pages/article/checkVideo";
import "../static/css/pages/layout.css";
import "../static/css/pages/comm.css";
import menu_logo_0 from "../static/images/menu_logo_0.png"
import menu_logo_1 from "../static/images/menu_logo_1.png"
import menu_logo_2 from "../static/images/menu_logo_2.png"
import menu_logo_3 from "../static/images/menu_logo_3.png"
import menu_logo_4 from "../static/images/menu_logo_4.png"
import menu_logo_5 from "../static/images/menu_logo_5.png"

const logoList = {
    1: menu_logo_0,
    2: menu_logo_1,
    10: menu_logo_2,
    13: menu_logo_3,
    16: menu_logo_4,
    17: menu_logo_5
}

const {Header, Content, Footer, Sider} = Layout;
const {SubMenu} = Menu;

function LayAdmin(props) {
    const {logout, init, userInfo, getRoute, menuList, changePassword} = layoutModel();
    const [broken, setBroken] = useState(false);
    const [point, setPoint] = useState(false); //隐藏
    const [collapsed, setCollapsed] = useState(false); //显
    const [modalVisible, setModalVisible] = useState(false)//重置密码弹窗
    // const [confirmModal, setConfirmModal] = useState(false);

    const onCollapse = (e) => {
        e.stopPropagation();
        if (!broken) {
            setCollapsed(!collapsed);
        } else {
            setPoint(!point);
        }
    };
    const layout = {
        labelCol: {span: 6},
        wrapperCol: {span: 18},
    };
    useEffect(() => {
        getRoute();
        init(props);
        console.log("地址：", props.location.pathname);
    }, []);

    const onMenu = async (e) => {
        props.history.push(e.key);
    };

    const menu = (
        <Menu>
            <Menu.Item key="mobileOne" icon={<UserOutlined/>}>
                {userInfo.user_role}
            </Menu.Item>
            <Menu.Item key="mobileTwo" icon={<MobileOutlined/>}>
                {userInfo.mobile}
            </Menu.Item>
            <Menu.Item key="mobileThree" icon={<SettingOutlined/>}
                       onClick={() => {
                           setModalVisible(true)
                       }}
            >
                修改密码
            </Menu.Item>
            <Menu.Item
                onClick={() => {
                    logout(props);
                }}
                key="lg"
                icon={<LoginOutlined/>}
            >
                退出登录
            </Menu.Item>
        </Menu>
    );
    return (
        <Layout style={{minHeight: "100vh"}}>
            <Sider
                span={0}
                trigger={null}
                collapsible
                collapsedWidth={0}
                collapsed={collapsed}
            >
                <div className="logo">
                    <img src={require("../static/images/logo.png")}/>
                </div>
                <Menu
                    onClick={onMenu}
                    theme="dark"
                    mode="inline"
                    selectedKeys={getMenuKey(props.location.pathname)}
                >
                    {
                        menuList.map((item, index) => {
                            return (
                                item.sub_menu.length > 0 ? <SubMenu key={item.menu_name} title={item.menu_name}
                                         icon={<img src={logoList[item.id]} style={{
                                             width: 16,
                                             position: "relative",
                                             left: -5,
                                             top: -2,
                                             marginRight: 0
                                         }} alt=""/>}>
                                    {
                                        item.sub_menu.map(subItem => {
                                            return (
                                                <Menu.Item key={subItem.menu_path}  onClick={() => {
                                                    if (window.location.pathname.search(subItem.menu_path) !== -1) {
                                                        // this.props.history.push(subItem.menu_path);
                                                        window.location.pathname = subItem.menu_path
                                                    }
                                                }}>
                                                    {subItem.menu_name}
                                                </Menu.Item>
                                            )
                                        })
                                    }
                                </SubMenu> : <Menu.Item key={item.menu_path} icon={<img style={{
                                    width: 16,
                                    position: "relative",
                                    left: -5,
                                    top: -2,
                                    marginRight: 0
                                }} src={logoList[item.id]} alt=""/>}>
                                    {item.menu_name}
                                </Menu.Item>
                            )
                        })
                    }
                </Menu>
            </Sider>

            <Layout>
                <Header className="layout-head">
                    <Row style={{width: "100%"}} justify="space-between">
                        <Col
                            xs={10}
                            ms={2}
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "space-between",
                            }}
                        >
                            {broken && (
                                <div className="logo" style={{width: "120px"}}>
                                    <img src={require("../static/images/logo-m.png")}/>
                                </div>
                            )}
                            {React.createElement(
                                collapsed ? MenuUnfoldOutlined : MenuFoldOutlined,
                                {
                                    className: "trigger",
                                    onClick: onCollapse,
                                }
                            )}
                        </Col>

                        <Col xs={6} sm={10} md={6} lg={6} xl={4} xxl={3}>
                            <Dropdown
                                overlay={menu}
                                overlayClassName={broken ? "full-width" : ""}
                            >
                                <div>
                                    <Space>
                                        <Avatar
                                            style={{marginRight: "10px"}}
                                            size="large"
                                            shape="square"
                                            src={
                                                userInfo.avatar ||
                                                require("../static/images/avatar.png")
                                            }
                                        />
                                        <div>
                                            {!broken && (
                                                <span style={{cursor: "pointer"}}>
                          {userInfo.name}
                        </span>
                                            )}
                                        </div>
                                    </Space>
                                </div>
                            </Dropdown>
                        </Col>
                    </Row>
                </Header>

                <Content style={{background: "#fff"}}>
                    <div>
                        <div>
                            <Route path="/main/index" exact component={Index}/>
                            <Route path="/main/list" exact component={ArticleList}/>
                            <Route path="/main/list/:tag" exact component={ArticleList}/>
                            <Route path="/main/add" exact component={ArticleAdd}/>
                            <Route path="/main/edit/:id?" exact component={ArticleEdit}/>
                            <Route path="/main/detial" exact component={ArticleDetial}/>
                            <Route path="/main/test" exact component={Test}/>
                            <Route path="/main/testedit" exact component={TEdit}/>
                            <Route path="/main/department" exact component={Department}/>
                            <Route path="/main/roles" exact component={Roles}/>
                            <Route path="/main/people" exact component={People}/>
                            <Route path="/main/addpeople" exact component={AddPeople}/>
                            <Route path="/main/review" exact component={Review}/>
                            <Route path="/main/check/:id" exact component={Check}/>
                            <Route path="/main/operatelog" exact component={OperateLog}/>
                            <Route path="/main/healthplatform" exact component={HealthPlatform}/>
                            <Route path="/main/case" exact component={PlatformCase}/>
                            <Route path="/main/covidnews" exact component={CovidNews}/>
                            <Route path="/main/povlist" exact component={PovList}/>
                            <Route path="/main/newsedit" exact component={NewsEdit}/>
                            <Route path="/main/povedit" exact component={PovEdit}/>
                            <Route path="/main/internethospital" exact component={InternetHospital}/>
                            <Route path="/main/hospitalcheck" exact component={HospitalDetail}/>
                            <Route path="/main/epidemiclist" exact component={EpidemicList}/>
                            <Route path="/main/epidemicdetail" exact component={EpidemicDetail}/>
                            <Route path="/main/add/video/:id?/:mode?" exact component={NewVideo}/>
                            <Route path="/main/checkvideo" exact component={CheckVideo}/>
                        </div>
                    </div>
                </Content>
                <Footer className="normal-font" style={{textAlign: "center"}}>
                    Copyright©2020 健康报
                </Footer>
            </Layout>
            <Modal
                title="修改密码"
                visible={modalVisible}
                footer={null}
                onCancel={() => {
                    setModalVisible(false)
                }}
            >
                <Form
                    {...layout}
                    name="basic"
                    initialValues={{remember: true}}
                    onFinish={async (val) => {
                        if (await changePassword(val) === 0) {
                            setModalVisible(false);
                            props.history.replace("/login")
                            // setConfirmModal(true);
                        }
                    }}
                >
                    <Form.Item
                        label="旧密码"
                        name="oldpassword"
                        initialValue=""
                        rules={[{ required: true, message: '请输入旧密码', }]}
                    >
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item
                        label="新密码"
                        name="password"
                        initialValue=""
                        rules={[{ required: true, message: '请输入新密码' }]}
                    >
                        <Input.Password/>
                    </Form.Item>

                    <Form.Item
                        label="确认新密码"
                        name="confirm_pwd"
                        initialValue=""
                        rules={[{ required: true, message: '请再次输入新密码' }]}
                    >
                        <Input.Password/>
                    </Form.Item>
                    <Form.Item style={{width: "100%", textAlign: "right"}}>
                        <Button style={{marginRight: "15px"}} type="primary" htmlType="submit">
                            确定
                        </Button>
                        <Button onClick={() => {
                            setModalVisible(false)
                        }}>
                            取消
                        </Button>
                    </Form.Item>
                </Form>
            </Modal>
            {/*<Modal*/}
            {/*    title=""*/}
            {/*    width={300}*/}
            {/*    visible={confirmModal}*/}
            {/*    footer={null}*/}
            {/*    onCancel={()=>{*/}
            {/*        setConfirmModal(false)*/}
            {/*    }}*/}
            {/*>*/}
            {/*    <p style={{textAlign:"center",marginTop:"50px",fontSize:"20px"}}>密码修改成功</p>*/}
            {/*    <Button style={{width:"150px",display:"block",margin:"50px auto 40px", color:"white",backgroundColor:"rgb(87,156,246)",border:0,borderRadius:"4px"}} onClick={()=>{*/}
            {/*        setConfirmModal(false);*/}
            {/*    }}>返回</Button>*/}
            {/*</Modal>*/}
        </Layout>
    );
}

export default LayAdmin;
