import React, { useState } from "react";
import { createModel } from "hox";
import { reqestApi } from "../../untils/requestHelper";
import { message } from "antd";

function useSearch(){
    const [list, setList] = useState([]);
    const [loading, setLoading] = useState(false);

    const search = async (searchParams) => {
        let params = {};
        // params.pagenum = page;
        // params.pagesize = limit;

        for(let key in searchParams){
            if(searchParams[key]||searchParams[key]===0){
                params[key]=searchParams[key];
            }
        }
        setLoading(true);
        let ret = await reqestApi("/api/mis/role/search", "GET", params);
        setLoading(false)
        if (ret.data.code === 0) {
            setList(ret.data.data);
        }
    };

    return{
        list,
        search,
        loading
    }
}

export default createModel(useSearch);