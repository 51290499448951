import {
    HrPlugin,
    HeaderPlugin,
    ItalicPlugin,
    OrderedListPlugin,
    ListItemPlugin,
    StrikePlugin,
    UnderlinePlugin,
    FormatClearPlugin,
    BlockQuotePlugin,
    BoldPlugin,
    BulletListPlugin,
    // FontFamilyPlugin, 开源版本暂不支持
    FontSizePlugin,
    AlignLeftPlugin,
    AlignCenterPlugin,
    AlignRightPlugin,
    AlignJustifyPlugin,
    FormatPainterPlugin,
    LineHeightPlugin,
    SubPlugin,
    SupPlugin,
    UndoPlugin,
    RedoPlugin,
    SpaceBeforePlugin,
    SpaceAfterPlugin,
    SpaceBothPlugin,
    ParagraphPlugin,
} from '@syllepsis/plugin-basic';
import { CodeBlockPlugin } from '@syllepsis/plugin-code-block';
import { ToolbarLoader } from '@syllepsis/editor';
import {
    Toolbar,
    EmojiPlugin,
    ImagePlugin,
    LinkPlugin,
    BackgroundPlugin,
    ColorPlugin,
} from '@syllepsis/access-react';
import { reqestApi } from "./requestHelper";
import { message } from 'antd';
import TTUploader from 'tt-uploader';
import Service from 'xgplayer-service';

const uploadPhoto = async (params) => {
    let ret = await reqestApi("/pub/uploadimage", "POST", params);
    return ret;
};

const getUploadToken = async () => {
    const res = await reqestApi('/api/mis/video/upload_token', 'GET');
    if (res.status === 200 && res.data) {
        const { data } = res.data;
        const { token } = data || {};
        return token;
    }
}

const getPlayerToken = async (vid) => {
    const res = await reqestApi(`/api/mis/video/play_token?vid=${vid}`, 'GET');
    if (res.status === 200 && res.data) {
        const { data } = res.data;
        const { token } = data || {};
        return token;
    }
}

export const SylEditorConfig = {
    Plugins: [
        new ParagraphPlugin({
            addMatchTags: ['section'],
            canMatch: dom => {
                if (
                    dom.getAttribute('code_block') ||
                    /<img|<image|<iframe|<tt-/.test(dom.innerHTML) ||
                    dom.querySelector('p')
                ) {
                    return false;
                }
                if (dom.tagName === 'section' && !dom.querySelector('br') && !dom.innerText.length) {
                    // 空内容并且没有br不做处理
                    return false;
                }
                let parent = dom.parentElement;
                let style = dom.getAttribute('style') || '';
                while (parent) {
                    const parentStyle = parent.getAttribute('style');
                    if (parentStyle) {
                        style = parentStyle + style;
                    }
                    parent = parent.parentElement;
                }
                style && dom.setAttribute('style', style);
                return true;
            },
        }),
        new ImagePlugin({
            // 图片上传方法，接受一个'blob'或'file'，返回图片地址或者对象Promise<string | { src: string, width: 200, height: 100 }>
            uploader: async (img) => {
                let formData = new FormData();
                formData.append("file", img);
                try {
                    const res = await uploadPhoto(formData);
                    if (res.data?.code === 0) {
                        return res.data.data;
                    }
                } catch (error) {
                    message.warn('图片上传失败' , 5);
                    console.error('图片上传失败: ' + error);
                }
            },
            uploadType: "blob" | "file", // 可选，uploader接受的文件类型， 默认'blob'
            listenDrop: true, // 可选，监听外部文件drop事件，默认true
            listenPaste: true, // 可选，监听外部文件粘贴事件，默认true
            maxLength: 10, // 可选，图片描述最大长度，默认20
            uploadBeforeInsert: true, // 可选，上传完成后插入，默认false
        }),
        // {
        //     plugin: VideoPlugin,
        //     controllerProps: {
        //         // 视频上传，接受一个'blob'或'file'，返回图片地址或者对象Promise<string | { src: string, width: 200, height: 100 }>
        //         uploader: async (file) => {
        //             const stsToken = await getUploadToken();
        //             try {
        //                 const uploader = new TTUploader({
        //                   userId: '2000002569',
        //                   appId: '225820',
        //                   videoConfig: {
        //                     spaceName: 'jiguangtog',
        //                     processAction: [
        //                       {
        //                         name: 'GetMeta'
        //                       },
        //                     ]
        //                   }
        //                 });
        //                 const fileKey = uploader.addFile({
        //                   file: file,
        //                   stsToken: stsToken,
        //                   type: 'video',
        //                 });              
        //                 return new Promise((resolve, reject) => {
        //                     uploader.on('complete', (info) => {
        //                         const { uploadResult } = info || {};
        //                         const { Vid = '' } = uploadResult || {};
        //                         getPlayerToken(Vid).then((playToken) => {
        //                             setTimeout(() => {
        //                                 Service.url(playToken, '//vod.volcengineapi.com').then((res) => {
        //                                     resolve({
        //                                             height: 250,
        //                                             width: 380,
        //                                             src: res && Array.isArray(res.PlayInfoList) && res.PlayInfoList[0] && `${res.PlayInfoList[0].MainPlayUrl}&vid=${Vid}`,
        //                                     });
        //                                 }, (err) => {});
        //                             }, 1000); //点播延时
        //                         })
        //                     });
        //                     uploader.on('err', (err) => {
        //                         message.error('视频上传失败，请重新上传！');
        //                         reject();
        //                     });
        //                     uploader.start(fileKey);
        //                 });
        //             } catch (ex) {
        //                 message.error('视频上传失败，请重新上传！');
        //             }
        //         },
        //     },
        // },
        new CodeBlockPlugin(),
        new BackgroundPlugin(),
        new ColorPlugin(),
        new BlockQuotePlugin(),
        new BoldPlugin(),
        new BulletListPlugin(),
        // new FontFamilyPlugin(), 开源版本暂不支持
        new FontSizePlugin({
        values: [12, 14, 16, 18, 20, 24, 28],
        }),
        new FormatClearPlugin(),
        new HeaderPlugin(),
        new HrPlugin(),
        new ItalicPlugin(),
        new OrderedListPlugin(),
        new ListItemPlugin(),
        new StrikePlugin(),
        new UnderlinePlugin(),
        new LinkPlugin(),
        new LineHeightPlugin({
        values: [1, 1.5, 1.75, 1.88, 2, 3],
        }),
        new SubPlugin(),
        new SupPlugin(),
        new AlignLeftPlugin(),
        new AlignCenterPlugin(),
        new AlignRightPlugin(),
        new AlignJustifyPlugin(),
        new EmojiPlugin(),
        new UndoPlugin(),
        new RedoPlugin(),
        new FormatPainterPlugin(),
        new ParagraphPlugin({
        defaultFontSize: 16, // 默认字体大小
        addMatchTags: [], // 增加匹配的tagName, 如['section']
        allowedAligns: [], // 允许的对齐方式, false为不解析对齐
        allowedClass: [], // 允许的类名，false为不解析class
        allowedLineHeights: [], // 允许的行高，false为不解析line-height，[]为允许所有
        allowedLineIndents: [], // 允许的首行缩进值，false为不解析text-indent，[]为允许所有
        allowedSpaceBefores: [], // 允许的段前距值，false为不解析margin-top，[]为允许所有
        allowedSpaceAfters: [], // 允许的段后距值，false为不解析margin-bottom，[]为允许所有
        allowedSpaceBoths: [], // 允许的两端缩进值，false为不解析margin-left，margin-right，[]为允许所有
        }),
        new SpaceBeforePlugin({
        values: [0, 4, 8, 16],
        }),
        new SpaceAfterPlugin({
        values: [0, 4, 8, 16],
        }),
        new SpaceBothPlugin({
        values: [0, 4, 8, 16],
        }),
    ],
    Modules: {
        toolbar: {
            Ctor: ToolbarLoader,
            option: {
                Component: Toolbar,
                tools: [
                    HeaderPlugin.getName(),
                    OrderedListPlugin.getName(),
                    BulletListPlugin.getName(),
                    '|',
                    LineHeightPlugin.getName(),
                    AlignLeftPlugin.getName(),
                    AlignCenterPlugin.getName(),
                    AlignRightPlugin.getName(),
                    AlignJustifyPlugin.getName(),
                    SpaceBeforePlugin.getName(),
                    SpaceAfterPlugin.getName(),
                    SpaceBothPlugin.getName(),
                    '|',
                    FontSizePlugin.getName(),
                    SubPlugin.getName(),
                    SupPlugin.getName(),
                    ColorPlugin.getName(),
                    BackgroundPlugin.getName(),
                    '|',
                    HrPlugin.getName(),
                    BoldPlugin.getName(),
                    ItalicPlugin.getName(),
                    StrikePlugin.getName(),
                    UnderlinePlugin.getName(),
                    LinkPlugin.getName(),
                    '|',
                    BlockQuotePlugin.getName(),
                    CodeBlockPlugin.getName(),
                    '|',
                    ImagePlugin.getName(),
                    EmojiPlugin.getName(),
                    RedoPlugin.getName(),
                    UndoPlugin.getName(),
                    FormatPainterPlugin.getName(),
                    FormatClearPlugin.getName(),
                ],
            },
        },
    },
};

// 编辑器监听事件列表
export const SylEventChannel = {
    LocalEvent: {
        EDITOR_CREATED: 'editor-created', // 编辑器实例化完成
        TEXT_CHANGE: 'text-change', // 内容发生变化
        SELECTION_CHANGED: 'selection-change', // 编辑器选区变化
        ON_BLUR: 'blur', // 失去焦点
        ON_FOCUS: 'focus', // 获得焦点
        EDITOR_WILL_UNMOUNT: 'editor-will-unmount', // 编辑器开始卸载
        LOCALE_CHANGE: 'locale-change' // 语言配置改变
    }
}
export default SylEditorConfig;
