import React, {useState, useEffect, useRef} from "react";
import {
    Form,
    Input,
    Select,
    Button,
    Upload,
    Breadcrumb,
    message,
    Drawer,
    Modal, Radio
} from "antd";

import "../../static/css/pages/article/add.css";
import {getProvinces} from "../../untils/citydata";
import {getToken} from "../../untils/tokenHelper";
import {GetUploadUrl} from "../../conf/app";
import {ExclamationCircleOutlined, UploadOutlined} from "@ant-design/icons";
import { SylEditor } from '@syllepsis/access-react';
import { SylEditorConfig, SylEventChannel } from "../../untils/SylEditorConfig";

import DetailModel from "../../models/platform/detail";
import {decodeContent} from "../../untils/commHelper";

const {confirm} = Modal;
const {Option} = Select;

function PlatformCase(props) {
    const mode = {
        NEW: 0,
        EDIT: 1,
        CHECK: 2,
        VIEW: 3
    }
    const currentMode = props.location.state.mode;
    const currentYear = new Date().getFullYear().toString();
    const buttonList = getButtonList();

    const [detail, setDetail] = useState(JSON.parse(props.location.state.caseInfo));
    const [editeData, changeEditData] = useState(detail.content ? detail.content : "");
    const [contentText, changeContentText] = useState(() => "");
    const [inputValue, setInputValue] = useState(123123)
    const [visible, setVisible] = useState(false);
    const [checkRes, setCheckRes] = useState("");
    const [fileFlag, setFileFlag] = useState(true);
    const [currentTitle, setCurrentTitle] = useState(detail.title ? detail.title : "")

    const editor = useRef();
    const formRef = useRef();
    const formItemLayout = {
        labelCol: {span: 4},
        wrapperCol: {span: 18},
    };

    const {
        isUpload,
        save,
        commit,
        check,
        changeIsUpload,
        fileList,
        setFileList,
        onChangeFileList,
        changeImgRules,
        imgRules,
        onChangeImgUrl,
        imgUrl,
        setImgUrl,
    } = DetailModel()

    useEffect(() => {
        setFileList([]);
        if (detail.img_url) {
            setImgUrl(detail.img_url);
            setFileList([
                {
                    uid: "-1",
                    name: "image",
                    status: "done",
                    url: detail.img_url,
                },
            ]);
        } else {
            setFileList([]);
            setImgUrl();
        }
        if (detail.article_type === 1) {
            changeImgRules([
                {
                    required: true,
                    message: "请上传图片摘要",
                },
            ])
        } else {
            changeImgRules([])
        }
        if (detail.article_type === 2 || !detail.article_type) {
            changeIsUpload(true);
        } else {
            changeIsUpload(false);
        }
    }, [])

    function getButtonList() {
        switch (currentMode) {
            case mode.VIEW:
                return ["预览"];
            case mode.NEW:
                return ["提交", "保存", "预览"];
            case mode.CHECK:
                return ["提交审核", "取消"];
            case mode.EDIT:
                return ["提交", "保存", "预览"];
            default:
                return []
        }
    }

    const getEditor = async (SylApi) => {

        SylApi.setHTML(detail.content);
        if (currentMode === mode.VIEW || currentMode === mode.CHECK) {
            SylApi.disable()
        } else {
            SylApi.enable()
        }
        const textChangeHandler = async () => {
            let content = SylApi.getHTML();
            let contentText = SylApi.text;
            changeContentText(contentText);
            changeEditData(content);
            setInputValue(content)
        }
        textChangeHandler() // 初始化富文本内容
        SylApi.on(SylEventChannel.LocalEvent.TEXT_CHANGE, textChangeHandler);
    };

    return (
        <div>
            <Breadcrumb style={{padding: "16px", background: "#F5F5F5"}}>
                <Breadcrumb.Item>创新案例管理</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{minHeight: 1028, position: "relative"}} className="main-add">
                <Form
                    ref={formRef}
                    {...formItemLayout}
                    onFinish={""}
                >
                    <Form.Item
                        label="标题"
                        name="title"
                        initialValue={detail.title}
                        rules={[
                            {
                                required: true,
                                message: "请填写标题",
                                type: "string",
                            },
                        ]}
                    >
                        <Input value={currentTitle} onChange={(e) => {
                            setCurrentTitle(e.target.value)
                        }} autocomplete="off" disabled={currentMode === mode.VIEW || currentMode === mode.CHECK}/>
                    </Form.Item>
                    <Form.Item
                        name="article_type"
                        label="案例类型"
                        initialValue={detail.article_type}
                        rules={[
                            {
                                required: true,
                                message: "选择案例类型",
                            },
                        ]}
                    >
                        <Select
                            onChange={(e) => {
                                if (e == 2) {
                                    changeIsUpload(true);
                                    setFileList([]);
                                    setImgUrl();
                                    setFileFlag(true);
                                } else {
                                    changeIsUpload(false);
                                }
                                if (e == 1) {
                                    changeImgRules([
                                        {
                                            required: true,
                                            message: "请上传图片摘要",
                                        },
                                    ])
                                } else {
                                    changeImgRules([])
                                }
                            }}
                            placeholder="请选择"
                            style={{width: "240px"}}
                            disabled={currentMode === mode.VIEW || currentMode === mode.CHECK}
                        >
                            <Option value={1}>图文</Option>
                            <Option value={2}>文本</Option>
                            {/* <Option value="3">文件</Option> */}
                            <Option value={4}>视频</Option>
                        </Select>
                    </Form.Item>
                    <div
                        style={{
                            // marginLeft: "16px"
                            position: "absolute",
                            left: "450px",
                            top: "64px",
                            fontSize: "12px",
                            color: "#9a9a9a"
                        }}
                    >
                        如需添加“图片”摘要，请选择“图文”类型
                    </div>
                    <Form.Item
                        name="exchange_year"
                        label="案例评选年度"
                        initialValue={detail.exchange_year ? detail.exchange_year : currentYear}
                        rules={[
                            {
                                required: true,
                                message: "选择评选年度",
                            },
                        ]}
                    >
                        <Select
                            placeholder="请选择"
                            style={{width: "240px"}}
                            disabled={currentMode === mode.VIEW || currentMode === mode.CHECK}
                        >
                            <Option value={currentYear}>{currentYear}</Option>
                            <Option value={(currentYear - 1).toString()}>{currentYear - 1}</Option>
                            <Option value={(currentYear - 2).toString()}>{currentYear - 2}</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="exchange_cate"
                        label="案例分类"
                        initialValue={detail.exchange_cate}
                        rules={[
                            {
                                required: true,
                                message: "选择案例分类",
                            },
                        ]}
                    >
                        <Select
                            placeholder="请选择"
                            style={{width: "240px"}}
                            disabled={currentMode === mode.VIEW || currentMode === mode.CHECK}
                        >
                            <Option value={2}>十五项行动</Option>
                            <Option value={3}>综合</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        name="province_id"
                        label="地方选择"
                        initialValue={detail.province_id}
                        rules={[
                            {
                                required: true,
                                message: "请选择地方",
                                type: "string",
                            },
                        ]}
                    >
                        <Select
                            placeholder="请选择"
                            style={{width: "240px"}}
                            disabled={currentMode === mode.VIEW || currentMode === mode.CHECK}
                        >
                            {getProvinces().map((litem) => {
                                return (
                                    <Option value={litem.value} key={litem.value}>
                                        {litem.label}
                                    </Option>
                                );
                            })}
                        </Select>
                    </Form.Item>
                    <Form.Item name="picture" label="图片摘要"
                               rules={imgRules}
                               initialValue={detail.img_url ? "1" : ""}
                    >
                        <Upload
                            disabled={isUpload || currentMode === mode.VIEW || currentMode === mode.CHECK}
                            fileList={fileList}
                            headers={{token: getToken()}}
                            name="file"
                            method="POST"
                            listType="picture-card"
                            className="avatar-uploader"
                            action={GetUploadUrl()}
                            onChange={(e) => {
                                onChangeFileList(e);
                                try {
                                    let ret = e.fileList[0].response;
                                    if (ret.code == 0) {
                                        onChangeImgUrl(ret.data);
                                        setFileFlag(false);
                                    } else {
                                        message.error("图片上传失败");
                                    }
                                } catch (ex) {
                                    // message.error("图片上传失败")
                                }
                            }}
                            onPreview={async (file) => {
                                let src = file.url;
                                if (!src) {
                                    src = await new Promise((resolve) => {
                                        const reader = new FileReader();
                                        reader.readAsDataURL(file.originFileObj);
                                        reader.onload = () => resolve(reader.result);
                                    });
                                }
                                const image = new Image();
                                image.src = src;
                                const imgWindow = window.open(src);
                                imgWindow.document.write(image.outerHTML);
                            }}
                            onRemove={(e) => {
                                onChangeImgUrl("");
                                setFileFlag(true);
                            }}
                        >
                            {((currentMode === mode.NEW && fileFlag) || !imgUrl) && (
                                <div className={isUpload == true ? "disableupload" : ""}>
                                    <UploadOutlined/>
                                    <div className="ant-upload-text">上传</div>
                                </div>
                            )}
                        </Upload>
                    </Form.Item>
                    <Form.Item
                        label="文字摘要"
                        name="abstract"
                        initialValue={detail.abstract}
                        rules={[
                            {
                                required: true,
                                message: "请填写文字摘要",
                                type: "string",
                            },
                        ]}
                    >
                        <Input.TextArea disabled={currentMode === mode.VIEW || currentMode === mode.CHECK}
                                        style={{height: "80px"}}/>
                    </Form.Item>
                    <Form.Item
                        name="content"
                        label="内容"
                        initialValue={"123123"}
                        rules={[{required: true, message: "文章内容不能为空"}]}
                    >
                        <Input type="text"/>

                    </Form.Item>
                    <div style={{
                        width: 810,
                        position: "absolute",
                        left: 180,
                        top: 520,
                        backgroundColor: "white",
                        zIndex: "999"
                    }}
                         className="edit-box">
                        <SylEditor
                            getEditor={getEditor}
                            onToolClick={(SylApi, toolName) => {
                                console.log("工具栏按钮触发", SylApi, toolName);
                            }}
                            ref={editor}
                            autoFocus={true}
                            placeholder="请输入正文内容..."
                            plugins={SylEditorConfig.Plugins}
                            module={SylEditorConfig.Modules}
                        />
                    </div>
                    {currentMode === mode.CHECK && <div style={{marginTop: 520}}>
                        <Form.Item
                            label="审核结果"
                            name="check_status"
                            rules={[{required: true}]}
                        >
                            <Radio.Group value={""} onChange={(e) => {
                                setCheckRes(e.target.value)
                            }}>
                                <Radio value={2}>审核通过</Radio>
                                <Radio value={3}>审核驳回</Radio>
                            </Radio.Group>
                        </Form.Item>
                        <Form.Item
                            label="审核意见"
                            name="fail_reason"
                            rules={[{required: parseInt(checkRes) === 3}]}
                        >
                            <Input.TextArea rows={4} maxLength={1000}/>
                        </Form.Item>
                    </div>}
                </Form>
            </div>
            <div style={{paddingLeft: 180, marginBottom: 40, marginTop: 20}}>
                {buttonList.indexOf("预览") > -1 && <Button
                    style={{marginRight: 10}}
                    type="primary"
                    onClick={() => {
                        setVisible(true)
                    }}
                >
                    预览
                </Button>}
                {buttonList.indexOf("保存") > -1 && <Button
                    style={{marginRight: 10}}
                    type="primary"
                    onClick={() => {
                        formRef.current.validateFields().then(async val => {
                            if (!editeData) {
                                message.warn("文章内容不能为空");
                                return;
                            }
                            val.content = encodeURI(editeData);
                            if (imgUrl) {
                                val.img_url = imgUrl;
                            }
                            const id = await save(val, detail.id)
                            if (id !== false) {
                                val.id = id;
                                setDetail({
                                    ...detail,
                                    id: id
                                })
                                message.success("保存成功！");
                                props.history.push({
                                    pathname: "/main/case",
                                    state: {mode: mode.EDIT, caseInfo: JSON.stringify(val)}
                                })
                            }
                        }).catch(error => {
                            message.error("请填写必要信息！");
                        })
                    }}
                >
                    保存
                </Button>}
                {buttonList.indexOf("提交") > -1 && <Button
                    style={{marginRight: 10}}
                    type="primary"
                    onClick={() => {
                        formRef.current.validateFields().then(async val => {
                            if (!editeData) {
                                message.warn("文章内容不能为空");
                                return;
                            }
                            val.content = encodeURI(editeData);
                            if (imgUrl) {
                                val.img_url = imgUrl;
                            }
                            const id = await save(val, detail.id);
                            if (id !== false) {
                                detail.id = id
                                confirm({
                                    title: "提示",
                                    icon: <ExclamationCircleOutlined/>,
                                    content: "确定要提交该案例么？",
                                    async onOk() {
                                        if (await commit(id)) {
                                            props.history.push("/main/healthplatform")
                                        } else {
                                            message.error("提交失败");
                                        }
                                    },
                                    onCancel() {
                                        console.log("Cancel");
                                    },
                                });
                            }
                        }).catch(error => {
                            message.error("请填写必要信息！");
                        })
                    }}
                >
                    提交
                </Button>}
                {buttonList.indexOf("提交审核") > -1 && <Button
                    style={{marginRight: 10}}
                    type="primary"
                    onClick={() => {
                        formRef.current.validateFields().then(async val => {
                            if (!editeData) {
                                message.warn("文章内容不能为空");
                                return;
                            }
                            val.content = encodeURI(editeData);
                            if (imgUrl) {
                                val.img_url = imgUrl;
                            }
                            if (await check(detail.id, val)) {
                                props.history.push("/main/healthplatform")
                            }
                        }).catch(error => {
                            message.error("请填写必要信息！");
                        })
                    }}
                >
                    提交审核
                </Button>}
                {buttonList.indexOf("取消") > -1 && <Button
                    style={{marginRight: 10}}
                    onClick={() => {
                        props.history.push('/main/healthplatform')
                    }}
                >
                    取消
                </Button>}
            </div>

            <Drawer
                destroyOnClose={true}
                key={"hhhss"}
                title={
                    <span>
                        <b>文章预览</b>
                    </span>
                }
                width={1080}
                onClose={() => {
                    setVisible(false);
                }}
                visible={visible}
            >
                <div className="preview-box" style={{padding: "16px 40px"}}>
                    <div
                        className="preview-title"
                        style={{
                            fontWeight: "700",
                            fontSize: "20px",
                            padding: "16px 0",
                            textAlign: "center",
                        }}
                    >
                        {currentTitle}
                    </div>
                    <div
                        style={{marginTop: "16px"}}
                        className="preview-content"
                        // article.content
                        dangerouslySetInnerHTML={{__html: decodeContent(editeData)}}
                    ></div>
                </div>
            </Drawer>
        </div>
    )
}

export default PlatformCase
