import React, {useState, useEffect, useRef} from "react";
import {
    Form,
    Input,
    Select,
    Button,
    Space,
    Upload,
    Breadcrumb,
    message,
    Drawer,
    DatePicker,
    Tag, Modal,
} from "antd";
import { SylEditor } from '@syllepsis/access-react';
import { SylEditorConfig, SylEventChannel } from "../../untils/SylEditorConfig";
import {LoadingOutlined, UploadOutlined} from "@ant-design/icons";
import ArticleEdit from "../../models/article/edit";
import {GetUploadUrl} from "../../conf/app";
import {decodeContent, getBroswer} from "../../untils/commHelper";
import {getProvinces} from "../../untils/citydata";
import {getToken} from "../../untils/tokenHelper";
import moment from "moment";
import "../../static/css/pages/article/add.css";
import example from "../../static/images/example.png"
// import Video from "./video";

const {Option} = Select;

function Edit(props) {
    const formRef = React.createRef();
    const {
        categoryTwo,
        article,
        addArticle,
        fields,
        imgUrl,
        imgUrlWap,
        fileList,
        isUpload,
        areaRules,
        setImgUrl,
        changeAreaRules,
        changeIsUpload,
        setFileList,
        onChangeFileList,
        getArticle,
        imgRules,
        changeImgRules,
        getCategoryTwo,
        onChangeImgUrl,
        commit
    } = ArticleEdit();
    const quillRef = useRef();
    const editor = useRef();
    const [editData, changeEditData] = useState(() => "");
    const [contentText, changeContentText] = useState(() => "");
    const [wapPicFlag, setWapPicFlag] = useState(true);
    const [visible, setVisible] = useState(false);
    const [fileFlag, setFileFlag] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);
    const [form] = Form.useForm();

    useEffect(() => {
        window.document.title = "编辑文章";
        if (imgUrl) {
            setFileFlag(false);
        }
    }, []);
    const getEditor = async (SylApi) => {
        const res = await getArticle(props.match.params.id);
        SylApi.setHTML(res);
        const textChangeHandler = async () => {
            let content = SylApi.getHTML();
            let contentText = SylApi.text; 
            changeContentText(contentText);
            changeEditData(content);
        }
        textChangeHandler() // 初始化富文本内容
        SylApi.on(SylEventChannel.LocalEvent.TEXT_CHANGE, textChangeHandler);
    };
    const formItemLayout = {
        labelCol: {span: 4},
        wrapperCol: {span: 18},
    };

    async function save(){
        if(!formRef.current.getFieldValue("title")||!formRef.current.getFieldValue("article_type")||!formRef.current.getFieldValue("cate")||!formRef.current.getFieldValue("article_source")||!formRef.current.getFieldValue("abstract")||!formRef.current.getFieldValue("public_date")){
            message.error("请编辑必填内容！");
            return;
        }
        if(formRef.current.getFieldValue("cate")=="local_dynamic"&&!formRef.current.getFieldValue("province_id")){
            message.error("请编辑地方选择！");
            return
        }
        if(formRef.current.getFieldValue("article_type")==1&&!imgUrl){
            message.error("请上传图片摘要！");
            return
        }
        let params = {};
        params.id = props.match.params.id;
        params.title = formRef.current.getFieldValue("title");
        params.cate = formRef.current.getFieldValue("cate");
        params.img_url = imgUrl;
        params.h5_image = imgUrlWap;
        params.abstract = formRef.current.getFieldValue("abstract");
        params.article_type = formRef.current.getFieldValue("article_type");
        //params.key_words = vals.key_words
        params.article_source = formRef.current.getFieldValue("article_source");
        //params.author = vals.author
        params.public_date = moment(formRef.current.getFieldValue("public_date")).format("YYYY-MM-DD");
        //  地方区域
        params.province_id = formRef.current.getFieldValue("province_id");

        if (formRef.current.getFieldValue("category_one") > 0) {
            params.category_one = formRef.current.getFieldValue("category_one");
            console.log("TWO:", formRef.current.getFieldValue("category_two"));
            if (formRef.current.getFieldValue("category_two") > 0) {
                params.category_two = formRef.current.getFieldValue("category_two");
            } else {
                message.warn("请选择具体文章二级分类");
                return;
            }
        }
        if (!article.content) {
            message.warn("文章内容不能为空");
            return;
        }
        if (article.summary) {
            params.summary = contentText;
        }
        params.content = encodeURI(editData);
        console.log("提交：", params);
        return await addArticle(params);
    }

    return (
        <div className="main-article">
            <a href="https://bytedance.feishu.cn/docs/doccnL1s0na1AYvv9VfjyLoWXSe"></a>
            <Breadcrumb style={{padding: "16px", background: "#F5F5F5"}}>
                <Breadcrumb.Item>后台管理</Breadcrumb.Item>
                <Breadcrumb.Item>编辑文章</Breadcrumb.Item>
            </Breadcrumb>
            <div className="main-add">
                <Form
                    ref={formRef}
                    fields={fields}
                    {...formItemLayout}
                    onFinish={async (vals) => {
                        if(await save()===0){
                            let res=await commit(props.match.params.id);
                            if(res===0){
                                props.history.push({pathname:"/main/list",state:{page:props.location.state.page}})
                            }
                        }
                    }}
                >
                    <Form.Item
                        label="标题"
                        name="title"
                        rules={[
                            {
                                required: true,
                                message: "请填写标题",
                                type: "string",
                            },
                        ]}
                    >
                        <Input autocomplete = "off"/>
                    </Form.Item>
                    {/* <Space> */}
                    <Form.Item
                        name="article_type"
                        label="文章类型"
                        rules={[
                            {
                                required: true,
                                message: "选择文章类型",
                                type: "string",
                            },
                        ]}
                    >
                        <Select
                            onChange={(e) => {
                                if (e == 2) {
                                    changeIsUpload(true);
                                    setFileList([]);
                                    setImgUrl(false);
                                    setFileFlag(true);
                                } else {
                                    changeIsUpload(false);
                                }
                                if(e==1){
                                    changeImgRules([
                                        {
                                            required: true,
                                            message: "请上传图片摘要",
                                        },
                                    ])
                                }else{
                                    changeImgRules([])
                                }
                            }}
                            placeholder="请选择"
                            style={{width: "240px"}}
                        >
                            <Option value="1">图文</Option>
                            <Option value="2">文本</Option>
                            {/* <Option value="3">文件</Option> */}
                            {/* <Option value="4">视频</Option> */}
                        </Select>
                    </Form.Item>
                    {/* </Space> */}
                    <div
                        style={{
                            // marginLeft: "16px"
                            position: "absolute",
                            left: "630px",
                            top: "210px",
                            fontSize: "12px",
                            color:"#9a9a9a"
                        }}
                    >
                        如需添加“图片”摘要，请选择“图文”类型
                    </div>
                    <Form.Item
                        name="cate"
                        label="频道"
                        rules={[
                            {
                                required: true,
                                message: "请选择频道",
                                type: "string",
                            },
                        ]}
                    >
                        <Select
                            onChange={(e) => {
                                // 当是首页或者官方咨询页时，出现wap图片上传功能
                                if (e === "index_carousel" || e === "official_information") {
                                    setWapPicFlag(true);
                                } else {
                                    setWapPicFlag(false);
                                }

                                if (e === "local_dynamic") {
                                    changeAreaRules([
                                        {
                                            required: true,
                                            message: "请选择地方频道",
                                            type: "string",
                                        },
                                    ]);
                                } else {
                                    changeAreaRules([]);
                                }
                            }}
                            placeholder="请选择"
                            style={{width: "240px"}}
                        >
                            <Option value="health_life">健康科普-健康科普</Option>
                            <Option value="local_dynamic">地方动态</Option>
                            <Option value="index_carousel">首页-头图</Option>
                            <Option value="official_information">权威信息-官方资讯</Option>
                            <Option value="china_article">重大行动</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "请填写文章来源机构",
                            },
                        ]}
                        label="资讯时间"
                        name="public_date"
                    >
                        <DatePicker/>
                    </Form.Item>
                    <Form.Item label="文章分类">
                        <Space>
                            <Form.Item name="category_one">
                                <Select
                                    placeholder="请选择"
                                    onChange={(e) => {
                                        console.log("EDDD:", e);
                                        getCategoryTwo(e);
                                    }}
                                    style={{width: "240px"}}
                                >
                                    <Option value="0">不设置分类</Option>
                                    <Option value="1">健康生活因素</Option>
                                    <Option value="2">重点人群</Option>
                                    <Option value="3">重大疾病</Option>
                                    {/* <Option value="4">其他</Option> */}
                                </Select>
                            </Form.Item>

                            <Form.Item name="category_two">
                                <Select placeholder="请选择" style={{width: "240px"}}>
                                    {categoryTwo.map((citem, cindex) => {
                                        return (
                                            <Option key={"op_" + cindex} value={citem.value}>
                                                {citem.lable}
                                            </Option>
                                        );
                                    })}
                                </Select>
                            </Form.Item>
                        </Space>

                        {categoryTwo.length > 0 && (
                            <Tag style={{marginLeft: "16px"}} color="#f50">
                                请完成二级分类选择
                            </Tag>
                        )}
                    </Form.Item>
                    <Form.Item name="province_id" label="地方选择" rules={areaRules}>
                        {/* <Space>
              <Form.Item> */}
                        <Select placeholder="省（直辖市）" style={{width: "240px"}}>
                            {getProvinces().map((litem) => {
                                return (
                                    <Option value={litem.value} key={litem.value}>
                                        {litem.label}
                                    </Option>
                                );
                            })}
                        </Select>
                        {/* </Form.Item> */}
                        {/* <Form.Item name="area">
                <Select
                  placeholder="市（区）"
                  style={{ width: "240px" }}
                ></Select>
              </Form.Item> */}
                        {/* </Space> */}
                    </Form.Item>

                    <Form.Item name="picture" label="图片摘要"  rules={imgRules}>
                        <Upload
                          disabled={isUpload}
                          fileList={fileList}
                          headers={{token: getToken()}}
                          name="file"
                          method="POST"
                          listType="picture-card"
                          className="avatar-uploader"
                          action={GetUploadUrl()}
                          // onChange={(e) => {
                          //     try {
                          //         onChangeFileList(e);
                          //         let ret = e.fileList[0].response;
                          //         if (ret.code == 0) {
                          //             onChangeImgUrl(ret.data);
                          //         } else {
                          //             onChangeImgUrl("");
                          //             onChangeFileList([]);
                          //             message.error("图片上传失败");
                          //         }
                          //     } catch (ex) {
                          //         onChangeImgUrl("");
                          //         onChangeFileList([]);
                          //         //message.error("图片上传失败");
                          //     }
                          // }}
                          onChange={(e) => {
                              onChangeFileList(e);
                              try {
                                  let ret = e.fileList[0].response;
                                  if (ret.code == 0) {
                                      onChangeImgUrl(ret.data);
                                      setFileFlag(false);
                                  } else {
                                      message.error("图片上传失败");
                                  }
                              } catch (ex) {
                                  // message.error("图片上传失败")
                              }
                          }}
                          onPreview={async (file) => {
                              let src = file.url;
                              if (!src) {
                                  src = await new Promise((resolve) => {
                                      const reader = new FileReader();
                                      reader.readAsDataURL(file.originFileObj);
                                      reader.onload = () => resolve(reader.result);
                                  });
                              }
                              const image = new Image();
                              image.src = src;
                              const imgWindow = window.open(src);
                              imgWindow.document.write(image.outerHTML);
                          }}
                          onRemove={(e) => {
                              onChangeImgUrl("");
                              setFileFlag(true);
                          }}
                        >
                            {fileFlag && fileList.length < 1 && (
                              <div className={isUpload == true ? "disableupload" : ""}>
                                  <UploadOutlined/>
                                  <div className="ant-upload-text">上传</div>
                              </div>
                            )}
                        </Upload>
                    </Form.Item>
                    <div
                        style={{
                            position: "absolute",
                            left: "490px",
                            top: "595px",
                            fontSize: "12px",
                            color:"#9a9a9a"
                        }}
                    >
                        <span>首页头图建议尺寸为1080*360且尾部326*360需要做模糊处理、官方资讯头图建议尺寸为710*340</span>
                        <span style={{marginLeft:"15px",color:"rgb(24,144,255)",cursor:"pointer"}} onClick={()=>{
                            setModalVisible(true)
                        }}>查看首页头图示例</span>
                    </div>
                    {/*{(article.cate == "index_carousel" ||*/}
                    {/*    article.cate == "official_information") &&*/}
                    {/*wapPicFlag && (*/}
                    {/*    <Form.Item label="移动端图片摘要">*/}
                    {/*        <Tag*/}
                    {/*            style={{*/}
                    {/*                marginBottom: "16px",*/}
                    {/*            }}*/}
                    {/*            color="#f50"*/}
                    {/*        >*/}
                    {/*            移动端首页和官方咨询页头图，建议长宽比（2:1）*/}
                    {/*        </Tag>*/}
                    {/*        <Upload*/}
                    {/*            disabled={isUpload}*/}
                    {/*            fileList={fileListWap}*/}
                    {/*            headers={{token: getToken()}}*/}
                    {/*            name="file"*/}
                    {/*            method="POST"*/}
                    {/*            listType="picture-card"*/}
                    {/*            className="avatar-uploader"*/}
                    {/*            action={GetUploadUrl()}*/}
                    {/*            onChange={(e) => {*/}
                    {/*                try {*/}
                    {/*                    onChangeFileList(e, true);*/}
                    {/*                    let ret = e.fileList[0].response;*/}
                    {/*                    if (ret.code == 0) {*/}
                    {/*                        onChangeImgUrl(ret.data, true);*/}
                    {/*                    } else {*/}
                    {/*                        onChangeImgUrl("", true);*/}
                    {/*                        onChangeFileList([], true);*/}
                    {/*                        message.error("图片上传失败");*/}
                    {/*                    }*/}
                    {/*                } catch (ex) {*/}
                    {/*                    onChangeImgUrl("", true);*/}
                    {/*                    onChangeFileList([], true);*/}
                    {/*                    //message.error("图片上传失败");*/}
                    {/*                }*/}
                    {/*            }}*/}
                    {/*            onPreview={async (file) => {*/}
                    {/*                let src = file.url;*/}
                    {/*                if (!src) {*/}
                    {/*                    src = await new Promise((resolve) => {*/}
                    {/*                        const reader = new FileReader();*/}
                    {/*                        reader.readAsDataURL(file.originFileObj);*/}
                    {/*                        reader.onload = () => resolve(reader.result);*/}
                    {/*                    });*/}
                    {/*                }*/}
                    {/*                const image = new Image();*/}
                    {/*                image.src = src;*/}
                    {/*                const imgWindow = window.open(src);*/}
                    {/*                imgWindow.document.write(image.outerHTML);*/}
                    {/*            }}*/}
                    {/*            onRemove={(e) => {*/}
                    {/*                onChangeImgUrl("");*/}
                    {/*                onChangeFileList(e);*/}
                    {/*            }}*/}
                    {/*        >*/}
                    {/*            {!imgUrlWap && (*/}
                    {/*                <div className={isUpload == true ? "disableupload" : ""}>*/}
                    {/*                    <UploadOutlined/>*/}
                    {/*                    <div className="ant-upload-text">上传</div>*/}
                    {/*                </div>*/}
                    {/*            )}*/}
                    {/*        </Upload>*/}
                    {/*    </Form.Item>*/}
                    {/*)}*/}

                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "请填写文章来源机构",
                            },
                        ]}
                        label="文章来源机构"
                        name="article_source"
                    >
                        {/* <Select placeholder="请选择文章来源" style={{ width: "240px" }}>
              <Option value="健康报社">健康报社</Option>
              <Option value="国家卫生健康委">国家卫生健康委</Option>
              <Option value="人民网">人民网</Option>
              <Option value="国务院">国务院</Option>
            </Select> */}
                        <Input style={{width: "240px"}}/>
                    </Form.Item>

                    <Form.Item label="文章来源URL" name="article_source_url">
                        <Input readOnly={true}/>
                    </Form.Item>
                    <Form.Item
                        label="文字摘要"
                        name="abstract"
                        rules={[
                            {
                                required: true,
                                message: "请填写文字摘要",
                                type: "string",
                            },
                        ]}
                    >
                        <Input.TextArea style={{height: "80px"}}/>
                    </Form.Item>

                    <Form.Item
                        label="内容"
                        rules={[{required: true, message: "文章内容不能为空"}]}
                    >
                        <div className="edit-box">
                            <SylEditor
                                getEditor={getEditor}
                                onToolClick={(SylApi, toolName) => {
                                    console.log("工具栏按钮触发", SylApi, toolName);
                                }}
                                ref={editor}
                                autoFocus={true}
                                placeholder="请输入正文内容..."
                                plugins={SylEditorConfig.Plugins}
                                module={SylEditorConfig.Modules}
                            />
                        </div>
                    </Form.Item>
                    <Form.Item
                        style={{marginTop: "16px"}}
                        wrapperCol={{
                            span: 12,
                            offset: 4,
                        }}
                    >
                        <div>
                            <Space>
                                <Button htmlType="submit" type="primary">
                                    提交
                                </Button>
                                <Button type="primary" onClick={save}>
                                    保存
                                </Button>
                                <Button
                                    type="primary"
                                    onClick={() => {
                                        setVisible(true);
                                    }}
                                >
                                    预览
                                </Button>

                                <Button
                                    onClick={() => {
                                        let url = "/main/list/1";
                                        props.history.push(url);
                                    }}
                                    type="primary"
                                >
                                    回到列表页
                                </Button>
                            </Space>
                        </div>
                    </Form.Item>
                </Form>
            </div>

            <Drawer
                destroyOnClose={true}
                key={"hhhss"}
                title={
                    <span>
            <b>文章预览</b>
          </span>
                }
                width={1080}
                onClose={() => {
                    setVisible(false);
                }}
                visible={visible}
            >
                <div className="preview-box" style={{padding: "16px 40px"}}>
                    <div
                        className="preview-title"
                        style={{
                            fontWeight: "700",
                            fontSize: "20px",
                            padding: "16px 0",
                            textAlign: "center",
                        }}
                    >
                        {article.title}
                    </div>
                    <div
                        style={{marginTop: "16px"}}
                        className="preview-content"
                        dangerouslySetInnerHTML={{__html: decodeContent(editData)}}
                    ></div>
                </div>
            </Drawer>
            <Modal
                title="查看首页头图示例"
                visible={modalVisible}
                onOk={()=>{
                    setModalVisible(false)
                }}
                onCancel={()=>{
                    setModalVisible(false)
                }}
                okText="我知道了"
            >
                <img style={{width:"100%",height:"100%",display:"block"}} src={example} alt=""/>
            </Modal>
        </div>
    );
}

export default Edit;
