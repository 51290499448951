import React, {useState, useEffect, useRef} from "react";
import {Breadcrumb, Form, Input, Select, Button, Upload, message, Radio, Drawer, DatePicker} from "antd";
import { CloseCircleFilled } from '@ant-design/icons';
import {UploadOutlined} from "@ant-design/icons";
import TTUploader from 'tt-uploader';
import Service from 'xgplayer-service';
import Player from 'xgplayer';
import moment from "moment";

import '../../static/css/pages/article/video.css'
import "../../static/css/pages/article/add.css";
import model from '../../models/article/newVideo'
import right from '../../static/images/right.png';

const { Option } = Select;

function NewVideo(props) {
  const [ stsToken, setToken ] = useState('');
  const [ poster, setPoster ] = useState('');
  const [ videoID, setVideoID ] = useState('');
  const [ articleID, setArticleID ] = useState('');
  const [ loading, setLoading ] = useState('');
  const [ isCheck, setCheck ] = useState(false);
  const [ passValue, setPassValue ] = useState(true);
  const [ drawerVisible, setVisible ] = useState(false);
  const [ content, setContent ] = useState({
    title: '',
    tiem: '',
    source: '',
  });

  const formRef = useRef();

  const {
    addArticle,
    commit,
    check,
    getInfo,
    getToken,
    getPlayerToken,
  } = model();

  const formItemLayout = {
    labelCol: {span: 4},
    wrapperCol: {span: 18},
  };

  useEffect(() => {
    const { match } = props;
    const { params } = match || {};
    const { id, mode = '' } = params || {};
    setCheck(mode === 'check');
    setArticleID(id);
    id && getInfo(id).then((data) => {
      const {
        title,
        cate,
        article_source,
        abstract,
        video_id = '',
        img_url = '',
        public_date,
      } = data || {};
      setVideoID(video_id);
      setPoster(img_url);
      formRef.current.setFieldsValue({
        title, cate, article_source, abstract,
        public_date: moment(public_date),
      });
    }, () => {
      message.error('获取资讯失败！');
    });
    getToken().then((token) => {
      setToken(token);
    });
  }, [])

  function removePoster() {
    setPoster('');
    setVideoID('');
  }

  function videoUpload(file) {
    const isSizeValid = file.size / 1024 / 1024 < 1536;
    if (!isSizeValid) {
      message.error('文件不能超过1.5G！');
      return false;
    }
    try {
      setLoading(0);
      const uploader = new TTUploader({
        userId: '2000002569',
        appId: '225820',
        videoConfig: {
          spaceName: 'jiguangtog',
          processAction: [
            {
              name: 'GetMeta'
            },
            {
              name: 'Snapshot',
              input: {
                SnapshotTime: 0.0,
              },
            }
          ]
        }
      });
      const fileKey = uploader.addFile({
        file: file,
        stsToken: stsToken,
        type: 'video',
      });              
      uploader.on('complete', (info) => {
        const { uploadResult } = info || {};
        const { PosterUri = '', Vid = '' } = uploadResult || {};
        const poster = PosterUri ? `http://image1.jkzgxd.cn/${PosterUri}~tplv-noop.jpeg` : '';
        setPoster(poster);
        setLoading('');
        setVideoID(Vid);
        formRef.current.validateFields(['video']);
      })
      uploader.on('err', (err) => {
        setLoading('');
        message.error('视频上传失败，请重新上传！');
      })
      uploader.on('progress', (infor) => {
        setLoading(infor.percent);
      });
      uploader.start(fileKey);
    } catch (ex) {
      setLoading('');
      message.error('视频上传失败，请重新上传！');
    }
    return false;
  }

  function saveForm() {
    return new Promise((resolve, reject) => {
      formRef.current.validateFields().then((data) => {
        const params = {};
        data && Object.keys(data).forEach((key) => {
          if (key === 'public_date') {
            params.public_date = moment(data.public_date).format("YYYY-MM-DD");
          } else {
            params[key] = data[key];
          }
        });
        params.cate = 'health_talk';     // 健康大咖谈
        params.article_type = 4;         // 视频类型
        params.img_url = poster;
        params.content = data.title;
        params.video_id = videoID;
        delete params.video;
        addArticle(params, articleID).then((id) => {
          params.id = id;
          setArticleID(id);
          message.success("保存成功！");
          resolve(params);
        }, (err) => {
          message.error('保存失败！');
          reject();
        });
      }, (err) => {
        reject();
      });
    });
  }

  function commitForm() {
    saveForm().then((data) => {
      const { id } = data || {};
      if (id) {
        commit(id).then(() => {
          message.success('提交成功！');
          props.history.push('/main/list');
          
        }, () => {
          message.error('提交失败！');
        })
      }
    }, () => {});
  }

  function checkForm() {
    saveForm().then((params) => {
      const { id } = params || {};
      if (id) {
        check(id, params).then(() => {
          message.success('提交审核成功！');
          props.history.push('/main/list');
        }, () => {
          message.error('提交审核失败！');
        })
      }
    }, () => {})
  }

  function handleCancle() {
    props.history.goBack();
  }

  function showDrawer() {
    const date = formRef.current.getFieldValue('public_date');
    const time = moment(date).format("YYYY-MM-DD");
    const title = formRef.current.getFieldValue('title');
    const source = formRef.current.getFieldValue('article_source');
    setContent({ time, title, source });
    getPlayerToken(videoID).then((playToken) => {
      Service.url(playToken, '//vod.volcengineapi.com').then((res) => {
        console.log(res);
        let player = new Player({
            id: 'mse',
            url: res && Array.isArray(res.PlayInfoList) && res.PlayInfoList[0] && res.PlayInfoList[0].MainPlayUrl,
            poster: res.PosterUrl,
        });
      }, (err) => {});
    })
    setVisible(true);
  }

  function closeDrawer() {
    setVisible(false);
  }

  return (
    <div className="new-video-container">
      <Breadcrumb style={{padding: "16px", background: "#F5F5F5"}}>
        <Breadcrumb.Item>资讯管理</Breadcrumb.Item>
        <Breadcrumb.Item>新建大咖谈</Breadcrumb.Item>
      </Breadcrumb>
      <div className="content-box">
        <Form
          {...formItemLayout}
          ref={formRef}
          onFinish={async (vals) => {
            
          }}
        >
          <Form.Item
            label="标题"
            name="title"
            rules={[
              {
                required: true,
                message: "请填写标题",
                type: "string",
              },
              {
                max: 50,
                message: "标题不能超过50个字",
              },
            ]}
          >
            <Input autocomplete="off"/>
          </Form.Item>
          <Form.Item
            rules={[
              {
                required: true,
                message: "请填写资讯时间",
              },
            ]}
            label="资讯时间"
            name="public_date"
          >
            <DatePicker/>
          </Form.Item>
          <Form.Item
            label="视频来源机构"
            name="article_source"
            rules={[
              {
                required: true,
                message: "请填写视频来源机构",
                type: "string",
              },
              {
                max: 60,
                message: "视频来源机构不能多于60个字",
              },
            ]}
          >
            <Input autocomplete="off"/>
          </Form.Item>
          <Form.Item
            label="视频简介"
            rules={[
              {
                max: 100,
                message: "视频简介不能多于100个字",
                type: "string",
              },
            ]}
            name="abstract"
          >
            <Input.TextArea style={{height: "80px"}}/>
          </Form.Item>
          <Form.Item 
            name="video" 
            label="视频"
            rules={[
              {
                required: true,
                message: "请上传视频",
                validator: (_, value) =>
                  videoID ? Promise.resolve() : Promise.reject(new Error('请上传视频')),
              },
            ]}
          >
            {
              !poster ? loading === '' ? (
                <Upload
                  name="file"
                  accept="video/mp4, video/mov"
                  listType="picture-card"
                  className="avatar-uploader"
                  beforeUpload={videoUpload}
                >
                  <div className={poster ? "disableupload" : ""}>
                      <UploadOutlined/>
                      <div className="ant-upload-text">上传</div>
                    </div>
                </Upload>
              ) : (
                <div className="poster-box">
                  <div className="loading-process">{loading}%</div>
                  <div style={{
                    height: 10,
                    backgroundColor: "green",
                    width: `${loading}%`,
                  }}></div>
                </div>
              ) : (
                <div className="poster-box">
                  <img className="upload-poster" src={poster} onError={(e) => {
                    e.target.src = right;
                  }} alt="" /> 
                  <CloseCircleFilled onClick={removePoster} />
                </div>
              )
            }
          </Form.Item>
          {
            isCheck && (
              <Form.Item
                label="审核结果"
                rules={[
                  {
                    required: true,
                    message: "请选择审核结果",
                  },
                ]}
                name="check_status"
              >
                <Radio.Group onChange={(e) => {
                  setPassValue(e.target.value === 4);
                }}>
                  <Radio value={4}>审核通过</Radio>
                  <Radio value={3}>审核驳回</Radio>
                </Radio.Group>
              </Form.Item>
            )
          }
          {
            isCheck && (
              <Form.Item
                label="审核意见"
                rules={[
                  {
                    required: !passValue,
                    message: "请输入审核意见",
                    type: "string",
                  },
                ]}
                name="fail_reason"
              >
                <Input.TextArea style={{height: "80px"}}/>
              </Form.Item>
            )
          }
        </Form>
        <div className="button-box">
          {
            isCheck ? (
              <>
                <Button type="primary" onClick={checkForm}>提交审核</Button>
                <Button onClick={saveForm}>保存修改</Button>
                <Button onClick={handleCancle}>取消</Button>
              </>
            ) : (
              <>
                <Button type="primary" onClick={commitForm}>提交</Button>
                <Button type="primary" onClick={saveForm}>保存</Button>
                <Button type="primary" onClick={showDrawer}>预览</Button>
              </>
            )
          }
        </div>
        <div className='video-alert'>支持上传mov/mp4格式文件，文件不能超过1.5G</div>
      </div>
      <Drawer
        destroyOnClose={true}
        title={
          <span>
            <b>文章预览</b>
          </span>
        }
        width={1080}
        onClose={closeDrawer}
        visible={drawerVisible}
      >
        <div className="preview-box" style={{padding: "16px 40px"}}>
          <div id="mse"></div>
          <p className="video-title">{content.title}</p>
          <p className="video-content">{content.source}</p>
          <p className="video-content">{content.time}</p>
        </div>
      </Drawer>
    </div>
  )
}

export default NewVideo;