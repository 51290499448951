import React, { useState, useEffect } from "react";
import {
    Breadcrumb,
    Table,
    Pagination
} from "antd";

import RolesModel from "../../models/system/roles";

const { Column } = Table;

function Roles(props){
    const {
        list,
        search,
        loading
    } = RolesModel()

    useEffect(()=>{
        document.title = "角色管理"
        search({})
    },[])

    return(
        <div>
            <Breadcrumb style={{ padding: "16px", background: "#F5F5F5" }}>
                <Breadcrumb.Item>系统管理</Breadcrumb.Item>
                <Breadcrumb.Item>角色管理</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{paddingTop:"30px"}}>
                <Table dataSource={list} pagination={false} bordered={true} scroll={{x:1300}} loading={loading}>
                    <Column
                        align="center"
                        title="角色ID"
                        dataIndex="id"
                    />
                    <Column
                        align="center"
                        title="角色名称"
                        dataIndex="name"
                    />
                    <Column
                        align="center"
                        title="角色描述"
                        dataIndex="desc"
                    />
                    <Column
                        align="center"
                        title="使用机构数量"
                        dataIndex="mechanism_num"
                        render={(num,record)=>{
                                return(
                                    <p style={{color:"rgb(135,199,241)",cursor:"pointer"}} onClick={()=>{
                                        props.history.push({pathname:'/main/department',state:{role:record.id}})
                                    }}>{num}</p>
                                )
                            }
                        }
                    />
                    <Column
                        align="center"
                        title="使用人员数量"
                        dataIndex="user_num"
                        render={(num,record)=>{
                            return(
                                <p style={{color:"rgb(135,199,241)",cursor:"pointer"}} onClick={()=>{
                                    props.history.push({pathname:'/main/people',state:{role:record.name}})
                                }}>{num}</p>
                            )
                        }
                        }
                    />
                </Table>
                {/*<Pagination*/}
                {/*    pageSizeOptions={["10"]}*/}
                {/*    pageSize={10}*/}
                {/*    defaultCurrent={1}*/}
                {/*    current={page}*/}
                {/*    total={totalCount}*/}
                {/*    showTotal={total => `共 ${total} 条`}*/}
                {/*    style={{textAlign:"right",paddingRight:"30px",marginTop:"20px"}}*/}
                {/*    onChange={(e) => {*/}
                {/*        search(e,10,{});*/}
                {/*    }}*/}
                {/*/>*/}
            </div>
        </div>
    )

}

export default Roles