import React, { useState } from "react";
import { createModel } from "hox";
import { reqestApi, UploadFile } from "../../untils/requestHelper";
import { message } from "antd";
import moment from "moment";
function useEdit() {
    const [categoryTwo, setCategoryTwo] = useState([]);
    const [title, setTitle] = useState("");
    const [fileList, setFileList] = useState([]);
    const [fileListWap, setFileListWap] = useState([]);
    const [fields, setFields] = useState([]);
    const [imgUrl, setImgUrl] = useState();
    const [imgUrlWap, setImgUrlWap] = useState();
    const [isUpload, changeIsUpload] = useState(() => true);
    const [areaRules, changeAreaRules] = useState(() => []);
    const [imgRules, changeImgRules] = useState(() => []);
    const [article, setArticle] = useState({
        id: 0,
        title: "",
        cate: "",
        img_url: null,
        abstract: null,
        article_type: 2,
        key_words: [],
        article_source: "",
        author: null,
        public_date: "",
        content: "",
        category_one: null,
        category_two: null,
        summary: "",
    });

    const categorys = {
        healthLife: [
            { lable: "健康知识普及行动", value: "1" },
            { lable: "合理膳食行动", value: "2" },
            { lable: "全民健身行动", value: "3" },
            { lable: "控烟行动", value: "4" },
            { lable: "心理健康促进行动", value: "5" },
            { lable: "健康环境促进行动", value: "6" },
        ],

        keyGroups: [
            { lable: "妇幼健康促进行动", value: "7" },
            { lable: "中小学健康促进行动", value: "8" },
            { lable: "职业健康保护行动", value: "9" },
            { lable: "老年健康促进行动", value: "10" },
        ],

        disease: [
            { lable: "心脑血管疾病防治行动", value: "11" },
            { lable: "癌症防治行动", value: "12" },
            { lable: "慢性呼吸系统疾病防治行动", value: "13" },
            { lable: "糖尿病防治行动", value: "14" },
            { lable: "传染病及地方病防控行动", value: "15" },
        ],
        other: [
            { lable: "视频", value: "xxx0" },
            { lable: "健康大咖", value: "xxx1" },
        ],
    };

    const eidtImgeUpload = async (formData) => {
        return UploadFile(formData);
    };
    const getCategoryTwo = async (num) => {
        if (num == 1) {
            setCategoryTwo(categorys["healthLife"]);
        } else if (num == 2) {
            setCategoryTwo(categorys["keyGroups"]);
        } else if (num == 3) {
            setCategoryTwo(categorys["disease"]);
        } else if (num == 4) {
            setCategoryTwo(categorys["other"]);
        } else {
            setCategoryTwo([]);
        }
    };

    const getArticle = async (id) => {
        setFileList([]);
        setFileListWap([]);
        let tmpUrl = "/api/mis/article/detail/" + id;
        let ret = await reqestApi(tmpUrl, "GET", {});
        if (ret.data.code == 0) {
            if (ret.data.data.cate === "local_dynamic") {
                changeAreaRules([
                    {
                        required: true,
                        message: "请选择地方频道",
                        type: "string",
                    },
                ]);
            } else {
                changeAreaRules([]);
            }
            let mode = ret.data.data;
            console.log("MODE:", mode);
            if (mode.img_url && mode.img_url!=="false") {
                setImgUrl(mode.img_url);
                setFileList([
                    {
                        uid: "-1",
                        name: "image",
                        status: "done",
                        url: mode.img_url,
                    },
                ]);
            }else{
                setImgUrl();
                setFileList([])
            }

            setImgUrlWap(mode.h5_image);
            if (mode.h5_image) {
                setFileListWap([
                    {
                        uid: "-1",
                        name: "image",
                        status: "done",
                        url: mode.h5_image,
                    },
                ]);
            }

            setArticle(ret.data.data);
            if (ret.data.data.article_type === 2) {
                changeIsUpload(true);
            } else {
                changeIsUpload(false);
            }
            let tmpArr = [];
            let tmpMode1 = {
                name: ["title"],
                value: mode.title,
            };
            tmpArr.push(tmpMode1);

            let tmpMode2 = {
                name: ["article_type"],
                value: mode.article_type + "",
            };
            tmpArr.push(tmpMode2);

            let tmpMode3 = {
                name: ["cate"],
                value: mode.cate,
            };
            tmpArr.push(tmpMode3);
            let tmpMode4 = {
                name: ["category_one"],
                value: mode.category_one ? mode.category_one.toString() : "",
            };
            tmpArr.push(tmpMode4);

            let tmpMode5 = {
                name: ["category_two"],
                value: mode.category_two ? mode.category_two + "" : null,
            };
            tmpArr.push(tmpMode5);

            let tmpMode6 = {
                name: ["abstract"],
                value: mode.abstract,
            };
            tmpArr.push(tmpMode6);

            let tmpMode7 = {
                name: ["public_date"],
                value: moment(mode.public_date),
            };
            tmpArr.push(tmpMode7);

            let tmpMode8 = {
                name: ["article_source"],
                value: mode.article_source,
            };
            tmpArr.push(tmpMode8);
            let tmpMode9 = {
                name: ["province_id"],
                value: mode.province_id ? mode.province_id : "",
            };
            tmpArr.push(tmpMode9);
            setFields(tmpArr);

            if (mode.category_one) {
                let ct = mode.category_one.toString();
                if (ct == "1") {
                    setCategoryTwo(categorys.healthLife);
                } else if (ct == "2") {
                    setCategoryTwo(categorys.keyGroups);
                } else if (ct == "3") {
                    setCategoryTwo(categorys.disease);
                }
            }
            return mode.content;
        }
    };

    const editArticle = async (id,params) => {
        let ret = await reqestApi(`/api/mis/article/store/${id}`, "POST", params);
        if (ret.data.code == 0) {
            message.success("修改成功");
        }else{
            message.success("修改失败")
        }
        return ret.data.code;
    };

    const commit = async (id,val)=>{
        // param.id=id;
        let ret=await reqestApi(`/api/mis/article/check/${id}`,"POST",val);
        if(ret.data.code===0){
            message.success("提交成功");
            return 0;
        }else{
            message.error("提交失败")
        }
    }

    const onChangeArticle = async (content, txt) => {
        let tmp = article;
        tmp.content = content;
        tmp.summary = txt;
        setArticle(tmp);
    };
    const onChangeImgUrl = async (url, isWap) => {
        if (isWap) {
            setImgUrlWap(url);
        } else {
            setImgUrl(url);
        }
    };
    const onChangeFileList = async ({ fileList: newFileList }, isWap) => {
        if (isWap) {
            setFileListWap(newFileList);
        } else {
            setFileList(newFileList);
        }
    };
    return {
        categoryTwo,
        article,
        title,
        fields,
        imgUrl,
        imgUrlWap,
        fileList,
        fileListWap,
        isUpload,
        areaRules,
        setImgUrl,
        imgRules,
        changeImgRules,
        changeAreaRules,
        setFileList,
        setFileListWap,
        changeIsUpload,
        onChangeFileList,
        eidtImgeUpload,
        onChangeImgUrl,
        getArticle,
        getCategoryTwo,
        editArticle,
        onChangeArticle,
        commit
    };
}

export default createModel(useEdit);
