import React, { useState } from "react";
import { createModel } from "hox";
import { reqestApi } from "../../untils/requestHelper";
import { message } from "antd";

function useSearch(){
    const [page, setPage] = useState(1);
    const [list, setList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [mechanismList,setMechanismList]=useState([]);
    const [roleList, setRoleList] = useState([])

    const getMechanismList=async ()=>{
        let ret=await reqestApi("/api/mis/mechanism/list");
        if(ret.data.code===0){
            setMechanismList(ret.data.data);
        }
    }

    const search = async (page, limit, searchParams) => {
        let params = {};
        params.pagenum = page;
        params.pagesize = limit;

        for(let key in searchParams){
            if(searchParams[key]||searchParams[key]===0){
                params[key]=searchParams[key];
            }
        }

        let ret = await reqestApi("/api/mis/mechanism/search", "GET", params);
        if (ret.data.code === 0) {
            setList(ret.data.data);
            setTotalCount(ret.data.totalCount);
            setPage(page);
        }
    };

    const getRoleList = async () => {
        let ret = await reqestApi("/api/mis/role/search", "GET", {});
        if (ret.data && ret.data.code === 0) {
            setRoleList(ret.data.data);
        }
    }

    return{
        list,
        page,
        setPage,
        totalCount,
        mechanismList,
        roleList,
        getRoleList,
        search,
        getMechanismList,
    }
}

export default createModel(useSearch);