import React, { useState, useEffect } from "react";
import {
    Breadcrumb,
    Form,
    Input,
    Select,
    Button,
    Table,
    Pagination
} from "antd";

import ListModel from "../../models/system/department";
import {findCityName,getProvinces} from "../../untils/citydata"

const { Column } = Table;
const {Option}=Select

function Department(props){
    const formRef = React.createRef();
    const [form,setForm]=useState({
            permission_id:props.location.state ? props.location.state.role : ""
        }
    )
    const [loading, setLoading] = useState(false);


    const {
        list,
        page,
        totalCount,
        mechanismList,
        search,
        getMechanismList,
        getRoleList,
        roleList,
    }=ListModel()

    async function searchList(page,val){
        setForm(val);
        setLoading(true);
        await search(page,10,val);
        setLoading(false)
    }

    useEffect(()=>{
        document.title = "机构管理"
        getRoleList();
        getMechanismList();
        searchList(1,form);
    },[])


    function getRoleID(role){
        let res;
        if(roleList){
            res = roleList.filter( item => item.name === role);
        }
        return res.length ? res[0] : "";
    }

    return(
        <div>
            <Breadcrumb style={{ padding: "16px", background: "#F5F5F5" }}>
                <Breadcrumb.Item>系统管理</Breadcrumb.Item>
                <Breadcrumb.Item>机构管理</Breadcrumb.Item>
            </Breadcrumb>
            <div>
                <div style={{padding:"30px"}}>
                    <Form  ref={formRef} layout="inline" onFinish={(val)=>{searchList(1,val)}}>
                        <Form.Item
                            label="机构ID"
                            name="id"
                            style={{width:"240px",marginBottom:"10px"}}
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item
                            label="机构名称"
                            name="name"
                            style={{width:"240px",marginBottom:"10px"}}
                        >
                            <Select>
                                {
                                    mechanismList.map(item=>{
                                        return <Option key={item.name} value={item.name}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            style={{width:"240px",marginBottom:"10px"}}
                            label="机构类型"
                            name="type"
                        >
                            <Select>
                                <Option value={0}>相关部委</Option>
                                <Option value={1}>承办机构</Option>
                                <Option value={2}>行动负责单位</Option>
                                <Option value={3}>省区市相关单位</Option>
                                <Option value={4}>技术提供商</Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            style={{width:"240px",marginBottom:"10px"}}
                            label="地区"
                            name="area"
                        >
                            <Select>
                                {
                                    getProvinces().map(item=>{
                                        return <Option key={item.value} value={item.value}>{item.label}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>

                        <Form.Item
                            style={{width:"240px",marginBottom:"10px"}}
                            label="角色"
                            name="permission_id"
                            initialValue={props.location.state ? props.location.state.role : ""}
                        >
                            <Select  style={{
                                width: 170
                            }}>
                                {/*<Option value={2}>资讯编辑员</Option>*/}
                                {/*<Option value={1}>系统管理员</Option>*/}
                                {/*<Option value={6}>案例编辑员</Option>*/}
                                {/*<Option value={5}>通用审核员</Option>*/}
                                {
                                    roleList.map(item => {
                                        return <Option value={item.id}>{item.name}</Option>
                                    })
                                }
                            </Select>
                        </Form.Item>
                        <Form.Item>
                            <div>
                                <Button type="primary" htmlType="submit">
                                    查询
                                </Button>
                            </div>
                        </Form.Item>
                        <Form.Item>
                            <div>
                                <Button onClick={()=>{
                                    formRef.current.resetFields();
                                    formRef.current.setFieldsValue({permission_id:""})
                                }}>
                                    重置查询条件
                                </Button>
                            </div>
                        </Form.Item>
                    </Form>
                </div>
                <div>
                    <Table dataSource={list} pagination={false} bordered={true} scroll={{x:1300}} loading={loading}>
                        <Column
                            align="center"
                            title="机构ID"
                            dataIndex="id"
                            fixed="left"
                        />
                        <Column
                            align="center"
                            title="机构名称"
                            dataIndex="name"
                            fixed="left"
                        />
                        <Column
                            align="center"
                            title="机构联系人1"
                            dataIndex="main_contact"
                            render={(name,record)=>{
                                return(
                                    <div>
                                        <p>{name}</p>
                                        <div>{record.main_contact_mobile}</div>
                                    </div>
                                )
                            }}
                        />
                        <Column
                            align="center"
                            title="机构联系人2"
                            dataIndex="secondary_contact"
                            render={(name,record)=>{
                                return(
                                    <div>
                                        <p>{name}</p>
                                        <div>{record.secondary_contact_mobile}</div>
                                    </div>
                                )
                            }}
                        />
                        <Column
                            align="center"
                            title="所属地区"
                            dataIndex="area"
                            render={(area)=>{
                                return (
                                    <div>{findCityName(area)}</div>
                                )
                            }}
                        />
                        <Column
                            align="center"
                            title="机构类型"
                            dataIndex="type"
                            render={(type)=>{
                                switch(type){
                                    case 0:
                                        return <div>相关部委</div>
                                    case 1:
                                        return <div>承办机构</div>
                                    case 2:
                                        return <div>行动负责单位</div>
                                    case 3:
                                        return <div>省区市相关单位</div>
                                    case 4:
                                        return <div>技术提供商</div>
                                    default:
                                        return <div>--</div>
                                }
                            }}
                        />
                        <Column
                            align="center"
                            title="机构角色"
                            dataIndex="role_names"
                        />
                        <Column
                            align="center"
                            title="创建时间"
                            dataIndex="created_at"
                        />
                    </Table>
                    <Pagination
                        pageSizeOptions={["10"]}
                        pageSize={10}
                        defaultCurrent={1}
                        current={page}
                        total={totalCount}
                        showTotal={total => `共 ${total} 条`}
                        style={{textAlign:"right",paddingRight:"30px",marginTop:"20px"}}
                        onChange={(e) => {
                            searchList(e, form);
                        }}
                    />
                </div>
            </div>
        </div>
    )

}

export default Department