import React, {useState} from "react";
import {createModel} from "hox";
import {reqestApi} from "../../untils/requestHelper";

function useNewVideo() {
    const getToken = async () => {
        const res = await reqestApi('/api/mis/video/upload_token', 'GET');
        if (res.status === 200 && res.data) {
            const { data } = res.data;
            const { token } = data || {};
            return token;
        }
    }

    const getPlayerToken = async (vid) => {
        const res = await reqestApi(`/api/mis/video/play_token?vid=${vid}`, 'GET');
        if (res.status === 200 && res.data) {
            const { data } = res.data;
            const { token } = data || {};
            return token;
        }
    }

    const addArticle = async (params, id) => {
        const res = await reqestApi(`/api/mis/article/store${id ? '/' + id : ''}`, 'POST', params);
        return res && res.data && res.data.code === 0 ? res.data.data.id : Promise.reject();
    }

    const commit = async (id) => {
        let res = await reqestApi(`/api/mis/article/commitcheck/${id}`, "GET");
        return res && res.data && res.data.code === 0 ? Promise.resolve() : Promise.reject();
    }

    const check = async (id, params) => {
        let res = await reqestApi(`/api/mis/article/check/${id}`, 'POST', params);
        return res && res.data && res.data.code === 0 ? Promise.resolve() : Promise.reject();
    }

    const getInfo = async (id) => {
        let res = await reqestApi(`/api/mis/article/detail/${id}`);
        return res && res.data && res.data.code === 0 ? res.data.data : Promise.reject();
    }

    return {
        addArticle,
        commit,
        check,
        getInfo,
        getToken,
        getPlayerToken,
    }
}

export default createModel(useNewVideo);