export const openUrl = (url) => {}
export const getRole = function () {
  return localStorage.getItem("user_role")
}

export const getMenuKey = (path) => {
  let arr = []
  arr.push(path)
  return arr
}
