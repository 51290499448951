import React from "react";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import { ConfigProvider } from "antd";
import LayAdmin from "../layout/index";
import Login from "../pages/login";
// import UpdatePage from "../pages/update";

import zhCN from "antd/es/locale/zh_CN";
import "../static/css/pages/index.css";
function AppRouter() {
  return (
    <Router forceRefresh={false}>
      <ConfigProvider locale={zhCN}>
        <Route path="/login" exact component={Login} />
        <Route path="/main" component={LayAdmin} />
        <Route path="/" exact component={Login} />
        {/* <Redirect path="/*" to='/update' /> */}
        {/* <Route path="/update" exact component={UpdatePage}/> */}
      </ConfigProvider>
    </Router>
  );
}

export default AppRouter;
