import React, {useState, useEffect, useRef} from "react";
import {Breadcrumb, Button, DatePicker, Drawer, Form, Input, message, Radio, Select, Upload, Modal} from "antd";
import { SylEditor } from '@syllepsis/access-react';
import { SylEditorConfig, SylEventChannel } from "../../untils/SylEditorConfig";
import moment from "moment";

import "../../static/css/pages/article/add.css";
import {getToken} from "../../untils/tokenHelper";
import {GetUploadUrl} from "../../conf/app";
import {ExclamationCircleOutlined, UploadOutlined} from "@ant-design/icons";
import {decodeContent} from "../../untils/commHelper";
import DetailModal from "../../models/covid19/newsEdit"
import example from "../../static/images/vaccine_example.png";

const {confirm} = Modal
const {Option} = Select

function NewsEdit(props) {
    const mode = {
        NEW: 0,
        EDIT: 1,
        CHECK: 2
    }
    const currentMode = props.location.state.mode;
    const buttonList = getButtonList();

    const [contentText, changeContentText] = useState(() => "");
    const [inputValue, setInputValue] = useState(1);
    const [checkRes, setCheckRes] = useState("");
    const [visible, setVisible] = useState(false);
    const [currentTitle, setCurrentTitle] = useState("");
    const [detail, setDetail] = useState(() => JSON.parse(props.location.state.caseInfo));
    const [editData, changeEditData] = useState(detail.content ? detail.content : "");
    const [fileFlag, setFileFlag] = useState(true);
    const [modalVisible, setModalVisible] = useState(false);

    const formRef = useRef();
    const editor = useRef();
    const formItemLayout = {
        labelCol: {span: 4},
        wrapperCol: {span: 18},
    };

    const {
        isUpload,
        save,
        commit,
        check,
        changeIsUpload,
        fileList,
        setFileList,
        onChangeFileList,
        changeImgRules,
        imgRules,
        onChangeImgUrl,
        imgUrl,
        setImgUrl,
    } = DetailModal()

    const getEditor = async (SylApi) => {
        SylApi.setHTML(detail.content);
        const textChangeHandler = async () => {
            let content = SylApi.getHTML();
            let contentText = SylApi.text;
            changeContentText(contentText);
            changeEditData(content);
            setInputValue(content);
        }
        textChangeHandler() // 初始化富文本内容
        SylApi.on(SylEventChannel.LocalEvent.TEXT_CHANGE, textChangeHandler);
    };

    function getButtonList() {
        switch (currentMode) {
            case mode.NEW:
                return ["提交", "保存", "预览"];
            case mode.CHECK:
                return ["确认审核结果","保存", "取消"];
            case mode.EDIT:
                return ["提交", "保存", "预览"];
            default:
                return []
        }
    }

    useEffect(() => {
        setFileList([]);
        if (detail.img_url) {
            setImgUrl(detail.img_url);
            setFileList([
                {
                    uid: "-1",
                    name: "image",
                    status: "done",
                    url: detail.img_url,
                },
            ]);
        } else {
            setFileList([]);
            setImgUrl();
        }
        if (detail.article_type === 1) {
            changeImgRules([
                {
                    required: true,
                    message: "请上传图片摘要",
                },
            ])
        } else {
            changeImgRules([])
        }
        if (detail.article_type === 2 || !detail.article_type) {
            changeIsUpload(true);
        } else {
            changeIsUpload(false);
        }
    }, [])

    return (
        <div>
            <Breadcrumb style={{padding: "16px", background: "#F5F5F5"}}>
                <Breadcrumb.Item>新冠疫苗专题管理</Breadcrumb.Item>
                <Breadcrumb.Item>疫苗资讯管理</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{minHeight: 1028, position: "relative"}} className="main-add">
                <Form
                    ref={formRef}
                    {...formItemLayout}
                    onFinish={""}
                >
                    <Form.Item
                        label="标题"
                        name="title"
                        initialValue={detail.title}
                        rules={[
                            {
                                required: true,
                                message: "请填写标题",
                                type: "string",
                            },
                        ]}
                    >
                        <Input
                            value={currentTitle}
                            onChange={(e) => {
                                setCurrentTitle(e.target.value)
                            }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="article_type"
                        label="资讯内容格式"
                        initialValue={detail.article_type}
                        rules={[
                            {
                                required: true,
                                message: "选择案例类型",
                            },
                        ]}
                    >
                        <Select
                            onChange={(e) => {
                                if (e == 2) {
                                    changeIsUpload(true);
                                    setFileList([]);
                                    setImgUrl();
                                    setFileFlag(true);
                                    formRef.current.setFields([{
                                        name: ["picture"],
                                        value: "",
                                    }])
                                } else {
                                    changeIsUpload(false);
                                }
                                if (e == 1) {
                                    changeImgRules([
                                        {
                                            required: true,
                                            message: "请上传图片摘要",
                                        },
                                    ])
                                } else {
                                    changeImgRules([])
                                }
                            }}
                            placeholder="请选择"
                            style={{width: "240px"}}
                        >
                            <Option value={1}>图文</Option>
                            <Option value={2}>文本</Option>
                        </Select>
                    </Form.Item>
                    <div
                        style={{
                            // marginLeft: "16px"
                            position: "absolute",
                            left: "450px",
                            top: "64px",
                            fontSize: "12px",
                            color: "#9a9a9a"
                        }}
                    >
                        如需添加“图片”摘要，请选择“图文”类型
                    </div>
                    <Form.Item
                        name="cate"
                        label="位置"
                        initialValue={detail.cate}
                        rules={[
                            {
                                required: true,
                                message: "选择案例类型",
                            },
                        ]}
                    >
                        <Select
                            placeholder="请选择"
                            style={{width: "240px"}}
                        >
                            <Option value={2}>疫苗专题头图</Option>
                            <Option value={1}>资讯列表</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item name="picture" label="图片摘要"
                               rules={imgRules}
                               initialValue={detail.img_url ? "1" : ""}
                    >
                        <div style={{height:113}}></div>
                    </Form.Item>
                    <div style={{position:"absolute", top:178,left:180}}>
                        <Upload
                            disabled={isUpload}
                            fileList={fileList}
                            headers={{token: getToken()}}
                            name="file"
                            method="POST"
                            listType="picture-card"
                            className="avatar-uploader"
                            action={GetUploadUrl()}
                            onChange={(e) => {
                                onChangeFileList(e);
                                try {
                                    let ret = e.fileList[0].response;
                                    if (ret.code == 0) {
                                        onChangeImgUrl(ret.data);
                                        setFileFlag(false);
                                        formRef.current.setFields([{
                                            name: ["picture"],
                                            value: "1",
                                        }])
                                    } else {
                                        message.error("图片上传失败");
                                    }
                                } catch (ex) {
                                    // message.error("图片上传失败")
                                }
                            }}
                            onPreview={async (file) => {
                                let src = file.url;
                                if (!src) {
                                    src = await new Promise((resolve) => {
                                        const reader = new FileReader();
                                        reader.readAsDataURL(file.originFileObj);
                                        reader.onload = () => resolve(reader.result);
                                    });
                                }
                                const image = new Image();
                                image.src = src;
                                const imgWindow = window.open(src);
                                imgWindow.document.write(image.outerHTML);
                            }}
                            onRemove={(e) => {
                                onChangeImgUrl("");
                                setFileFlag(true);
                                onChangeFileList([]);
                                formRef.current.setFields([{
                                    name: ["picture"],
                                    value: "",
                                }])
                            }}
                        >
                            {(
                                (currentMode === mode.NEW && fileFlag) ||
                                !imgUrl) && (
                                <div className={isUpload == true ? "disableupload" : ""}>
                                    <UploadOutlined/>
                                    <div className="ant-upload-text">上传</div>
                                </div>
                            )}
                        </Upload>
                    </div>
                    <div
                        style={{
                            position: "absolute",
                            left: 300,
                            top: 265,
                            fontSize: "12px",
                            color: "#9a9a9a"
                        }}
                    >
                        <span>疫苗专题头图建议尺寸为754*360</span>
                        <span style={{marginLeft: "15px", color: "rgb(24,144,255)", cursor: "pointer"}} onClick={() => {
                            setModalVisible(true)
                        }}>查看疫苗专题头图示例</span>
                    </div>
                    <Form.Item
                        rules={[
                            {
                                required: true,
                                message: "请填写文章来源机构",
                            },
                        ]}
                        label="资讯日期"
                        name="public_date"
                        initialValue={moment(detail.public_date)}
                    >
                        <DatePicker/>
                    </Form.Item>
                    <Form.Item
                        label="资讯来源机构"
                        name="article_source"
                        initialValue={detail.article_source}
                        rules={[
                            {
                                required: true,
                                message: "请填写资讯来源机构",
                                type: "string",
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="文字摘要"
                        name="abstract"
                        initialValue={detail.abstract}
                        rules={[
                            {
                                message: "请填写文字摘要",
                                type: "string",
                            },
                        ]}
                    >
                        <Input.TextArea
                            style={{height: "80px"}}/>
                    </Form.Item>
                    <Form.Item
                        name="content"
                        label="内容"
                        initialValue={"123123"}
                        rules={[{required: true, message: "文章内容不能为空"}]}
                    >
                        <Input type="text"/>

                    </Form.Item>
                    <div style={{
                        width: 810,
                        position: "absolute",
                        left: 180,
                        top: 520,
                        backgroundColor: "white",
                        zIndex: "999"
                    }}
                         className="edit-box">
                        <SylEditor
                            getEditor={getEditor}
                            onToolClick={(SylApi, toolName) => {
                                console.log("工具栏按钮触发", SylApi, toolName);
                            }}
                            ref={editor}
                            autoFocus={true}
                            placeholder="请输入正文内容..."
                            plugins={SylEditorConfig.Plugins}
                            module={SylEditorConfig.Modules}
                        />
                    </div>
                    {
                        currentMode === mode.CHECK && <div style={{marginTop: 500}}>
                            <Form.Item
                                label="审核结果"
                                name="check_status"
                                rules={[{required: true}]}
                            >
                                <Radio.Group value={""} onChange={(e) => {
                                    setCheckRes(e.target.value)
                                }}>
                                    <Radio value={2}>审核通过</Radio>
                                    <Radio value={3}>审核驳回</Radio>
                                </Radio.Group>
                            </Form.Item>
                            <Form.Item
                                label="审核意见"
                                name="fail_reason"
                                rules={[{required: parseInt(checkRes) === 3}]}
                            >
                                <Input.TextArea rows={4} maxLength={1000}/>
                            </Form.Item>
                        </div>}
                </Form>
            </div>
            <div style={{paddingLeft: 180, marginBottom: 40, marginTop: 20}}>
                {buttonList.indexOf("确认审核结果") > -1 && <Button
                    style={{marginRight: 10}}
                    type="primary"
                    onClick={() => {
                        formRef.current.validateFields().then(async val => {
                            if (!editData) {
                                message.warn("文章内容不能为空");
                                return;
                            }
                            val.content = encodeURI(editData);
                            if (imgUrl) {
                                val.img_url = imgUrl;
                            }
                            val.public_date = moment(val.public_date).format("YYYY-MM-DD");
                            if (await check(detail.id, val)) {
                                props.history.push("/main/covidnews")
                            }
                        }).catch(error => {
                            message.error("请填写必要信息！");
                        })
                    }}
                >
                    确认审核结果
                </Button>}
                {buttonList.indexOf("预览") > -1 && <Button
                    style={{marginRight: 10}}
                    type="primary"
                    onClick={() => {
                        setVisible(true)
                    }}
                >
                    预览
                </Button>}
                {buttonList.indexOf("保存") > -1 && <Button
                    style={{marginRight: 10}}
                    type="primary"
                    onClick={() => {
                        formRef.current.validateFields().then(async val => {
                            if (!editData) {
                                message.warn("文章内容不能为空");
                                return;
                            }
                            val.content = encodeURI(editData);
                            if (imgUrl) {
                                val.img_url = imgUrl;
                            }
                            val.public_date = moment(val.public_date).format("YYYY-MM-DD");
                            const id = await save(val, detail.id)
                            if (id !== false) {
                                val.id = id;
                                setDetail({
                                    ...detail,
                                    id: id
                                })
                                props.history.push({
                                    pathname: "/main/newsedit",
                                    state: {mode: currentMode === mode.NEW ? mode.EDIT : currentMode, caseInfo: JSON.stringify(val)}
                                })
                            }
                        }).catch(error => {
                            message.error("请填写必要信息！");
                        })
                    }}
                >
                    保存
                </Button>}
                {buttonList.indexOf("提交") > -1 && <Button
                    style={{marginRight: 10}}
                    type="primary"
                    onClick={() => {
                        formRef.current.validateFields().then(async val => {
                            if (!editData) {
                                message.warn("文章内容不能为空");
                                return;
                            }
                            val.content = encodeURI(editData);
                            if (imgUrl) {
                                val.img_url = imgUrl;
                            }
                            val.public_date = moment(val.public_date).format("YYYY-MM-DD");
                            const id = await save(val, detail.id);
                            if (id !== false) {
                                if (await commit(id)) {
                                    props.history.push("/main/covidnews")
                                }
                            }
                        }).catch(error => {
                            message.error("请填写必要信息！");
                        })
                    }}
                >
                    提交
                </Button>}
                {buttonList.indexOf("取消") > -1 && <Button
                    style={{marginRight: 10}}
                    onClick={() => {
                        props.history.goBack(-1)
                    }}
                >
                    取消
                </Button>}
            </div>

            <Drawer
                destroyOnClose={true}
                title={
                    <span>
                        <b>文章预览</b>
                    </span>
                }
                width={1080}
                onClose={() => {
                    setVisible(false);
                }}
                visible={visible}
            >
                <div className="preview-box" style={{padding: "16px 40px"}}>
                    <div
                        className="preview-title"
                        style={{
                            fontWeight: "700",
                            fontSize: "20px",
                            padding: "16px 0",
                            textAlign: "center",
                        }}
                    >
                        {currentTitle}
                    </div>
                    <div
                        style={{marginTop: "16px"}}
                        className="preview-content"
                        // article.content
                        dangerouslySetInnerHTML={{__html: decodeContent(editData)}}
                    ></div>
                </div>
            </Drawer>
            <Modal
                title="查看疫苗专题头图示例"
                visible={modalVisible}
                onOk={() => {
                    setModalVisible(false)
                }}
                onCancel={() => {
                    setModalVisible(false)
                }}
                okText="我知道了"
            >
                <img style={{width: "100%", height: "100%", display: "block"}} src={example} alt=""/>
            </Modal>
        </div>
    )
}

export default NewsEdit