import React, { useState } from "react";
import { createModel } from "hox";
import { reqestApi } from "../../untils/requestHelper";
import { message } from "antd";

function useSearch(){
    const [page, setPage] = useState(1);
    const [list, setList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [mechanismList,setMechanismList]=useState([]);
    const [roleList, setRoleList] = useState([]);

    const getRoleList = async () => {
        let ret = await reqestApi("/api/mis/role/search", "GET", {});
        if (ret.data && ret.data.code === 0) {
            setRoleList(ret.data.data);
        }
    }

    const getMechanismList=async ()=>{
        let ret=await reqestApi("/api/mis/mechanism/list");
        if(ret.data.code===0){
            setMechanismList(ret.data.data);
        }
    }

    const resetPassword=async (id,param)=>{
        let params = {};
        params.user_id=id;

        for(let key in param){
            if(param[key]||param[key]===0){
                params[key]=param[key];
            }
        }

        let ret=await reqestApi("/api/mis/user/resetpassword","POST",params);
        if(ret.data.code===0){
            message.success("修改成功")
        }else{
            message.error(ret.data.msg)
        }
    }

    const remove = async (id,form)=>{
        let param={}
        if(id){
            param.id=parseInt(id);
        }
        let ret=await reqestApi(`/api/mis/user/delete/${id}`,"GET");
        if(ret.data.code===0){
            search(page,form);
        }else{
            message.error("移除失败")
        }
    }

    const search = async (page, searchParams) => {
        let params = {};
        params.page = page;
        // params.pagesize = limit;

        for(let key in searchParams){
            if(searchParams[key]||searchParams[key]===0){
                params[key]=searchParams[key];
            }
        }

        let ret = await reqestApi("/api/mis/user/search", "GET", params);
        if (ret.data.code === 0) {
            setList(ret.data.data);
            setTotalCount(ret.data.totalCount);
            setPage(page);
        }else{
            message.error("查询错误")
        }
    };

    //停用
    const deactive=async (id,form)=>{
        let ret=await reqestApi(`/api/mis/user/putdown/${id}`,"GET");
        if(ret.data.code===0){
            message.success("操作成功");
            search(page,form);
        }else{
            message.error("操作失败")
        }
    }

    //启用
    const enable=async (id,form)=>{
        let ret=await reqestApi(`/api/mis/user/open/${id}`,"GET");
        if(ret.data.code===0){
            message.success("操作成功");
            search(page,form);
        }else{
            message.error("操作失败")
        }
    }



    return{
        list,
        page,
        setPage,
        totalCount,
        mechanismList,
        roleList,
        getRoleList,
        search,
        remove,
        resetPassword,
        deactive,
        enable,
        getMechanismList
    }
}

export default createModel(useSearch);