import React, {useEffect, useState} from "react"
import {
    Breadcrumb,
    Button,
    Col,
    DatePicker,
    Form,
    Input,
    message,
    Modal,
    Pagination,
    Radio,
    Row,
    Select,
    Table
} from "antd";

import {findCityName, getProvinces} from "../../untils/citydata";
import modal from "../../models/hospital/list"
import {ExclamationCircleOutlined} from "@ant-design/icons";

const {Option} = Select
const {RangePicker} = DatePicker;
const {Column} = Table;
const {confirm} = Modal;

function InternetHospital(props) {
    const mode = {
        CHECK: 2,
        VIEW: 3
    }


    const formRef = React.createRef();
    const [check_status, setStatus] = useState(0);
    const [moments, setMoments] = useState();
    const [params, setParams] = useState({});

    const {
        page,
        list,
        totalCount,
        loading,
        search,
        getHospitalInfo,
        deleteInfo,
        offline
    } = modal()

    useEffect(() => {
        document.title = "医院黄页管理"
        search(1, params, check_status)
    }, [])

    return (
        <div>
            <Breadcrumb style={{padding: "16px", background: "#F5F5F5"}}>
                <Breadcrumb.Item>医院黄页管理</Breadcrumb.Item>
            </Breadcrumb>
            <div style={{padding: "32px 16px 8px 16px"}}>
                <Form
                    ref={formRef}
                    layout="inline"
                    onFinish={(val, status) => {
                        // console.log(val);
                        if (moments) {
                            val.start_time = Math.floor(moments[0]._d.getTime() / 1000);
                            val.end_time = Math.floor(moments[1]._d.getTime() / 1000);
                        }
                        setParams(val);
                        search(1, val, status ? status : check_status);
                    }}
                >
                    <Form.Item name="hospital_name" label="医院名称" style={{width: "240px", marginBottom: "10px"}}>
                        <Input autocomplete="off"/>
                    </Form.Item>
                    <Form.Item name="province" label="地方" style={{width: "240px", marginBottom: "10px"}}>
                        <Select>
                            <Option value={0}>全部</Option>
                            {
                                getProvinces().map(item => {
                                    return <Option key={item.value} value={item.value}>{item.label}</Option>
                                })
                            }
                        </Select>
                    </Form.Item>
                    <Form.Item name="commit_name" label="申请人" style={{width: "240px", marginBottom: "10px"}}>
                        <Input autocomplete="off"/>
                    </Form.Item>
                    <Form.Item
                        style={{marginBottom: "10px"}}
                        label="提交时间"
                        name="time"
                        value={moments}
                    >
                        <div>
                            <RangePicker onChange={(dates) => {
                                // console.log(dates);
                                setMoments(dates);
                            }}/>
                        </div>
                    </Form.Item>
                    <Form.Item>
                        <Button htmlType="submit" type="primary">
                            查找
                        </Button>
                    </Form.Item>
                </Form>
                <div style={{marginTop: "20px"}}>
                    <Radio.Group value={check_status} onChange={(e) => {
                        setStatus(e.target.value);
                        formRef.current.submit(e.target.value)
                    }}>
                        <Radio.Button value={0}>全部</Radio.Button>
                        <Radio.Button value={1}>待审核</Radio.Button>
                        <Radio.Button value={3}>驳回</Radio.Button>
                        <Radio.Button value={4}>上线</Radio.Button>
                        <Radio.Button value={6}>下线</Radio.Button>
                    </Radio.Group>
                </div>
            </div>
            <div style={{marginTop: "30px"}}>
                <Table
                    dataSource={list}
                    scroll={{x: 1295, y: "50vh"}}
                    pagination={false}
                    bordered={true}
                    loading={loading}
                >
                    <Column
                        align="center"
                        title="序号"
                        dataIndex="index"
                        width="80px"
                    />
                    <Column
                        align="center"
                        title="医院名称"
                        dataIndex="hospital_name"
                        width="220px"
                    />
                    <Column
                        align="center"
                        title="医院等级"
                        width="150px"
                        dataIndex="hospital_rank"
                        render={(rank) => {
                            switch (rank) {
                                case 2:
                                    return <div>三甲医院</div>
                                case 3:
                                    return <div>三级医院</div>
                                case 4:
                                    return <div>二甲医院</div>
                                default:
                                    return <div>其他医院</div>
                            }
                        }}
                    />
                    <Column
                        align="center"
                        title="所在地区"
                        width="100px"
                        dataIndex="province"
                        render={(id) => {
                            return findCityName(id);
                        }}
                    />
                    <Column
                        align="center"
                        title="资质"
                        width="150px"
                        dataIndex="qualification"
                        render={(id) => {
                            switch (id) {
                                case 1:
                                    return <div>互联网医院</div>
                                case 2:
                                    return <div>互联网诊疗</div>
                                default:
                                    return <div>无</div>
                            }
                        }}
                    />
                    <Column
                        align="center"
                        title="申请人"
                        width="150px"
                        dataIndex="commit_name"
                    />
                    <Column
                        align="center"
                        title="提交时间"
                        width="150px"
                        dataIndex="commit_time"
                    />
                    <Column
                        align="center"
                        title="状态"
                        width="100px"
                        dataIndex="check_status"
                        render={(status) => {
                            switch (status) {
                                case 1:
                                    return <div>待审核</div>
                                case 4:
                                    return <div>上线</div>
                                case 3:
                                    return <div>驳回</div>
                                case 6:
                                    return <div>下线</div>
                                default:
                                    return <div>--</div>
                            }
                        }}
                    />
                    <Column
                        align="center"
                        title="操作"
                        dataIndex="index"
                        width="195px"
                        fixed="right"
                        render={(item, record) => {
                            return (
                                <div
                                    style={{width: "100%", display: "flex", justifyContent: "space-around"}}
                                >
                                    {record.optionList.indexOf("查看") > -1 && <Button
                                        onClick={async () => {
                                            const info = await getHospitalInfo(record.id);
                                            props.history.push({
                                                pathname: "/main/hospitalcheck",
                                                state: {mode: mode.VIEW, info: JSON.stringify(info)}
                                            })
                                        }}
                                    >
                                        查看
                                    </Button>}
                                    {record.optionList.indexOf("审核") > -1 && <Button
                                        onClick={async () => {
                                            const info = await getHospitalInfo(record.id);
                                            props.history.push({
                                                pathname: "/main/hospitalcheck",
                                                state: {mode: mode.CHECK, info: JSON.stringify(info)}
                                            })
                                        }}
                                    >
                                        审核
                                    </Button>}
                                    {record.optionList.indexOf("删除") > -1 && <Button
                                        onClick={() => {
                                            confirm({
                                                title: "提示",
                                                icon: <ExclamationCircleOutlined/>,
                                                content: "您确定要删除此条信息吗？信息一旦删除无法恢复。",
                                                async onOk() {
                                                    if (await deleteInfo(record.id)) {
                                                        search(page, params, check_status);
                                                    } else {
                                                        message.error("删除失败");
                                                    }
                                                },
                                                onCancel() {
                                                    console.log("Cancel");
                                                },
                                            });
                                        }}
                                    >
                                        删除
                                    </Button>}
                                    {record.optionList.indexOf("下线") > -1 && <Button
                                        onClick={() => {
                                            confirm({
                                                title: "提示",
                                                icon: <ExclamationCircleOutlined/>,
                                                content: "确定要下线该医院么？",
                                                async onOk() {
                                                    if (await offline(record.id)) {
                                                        search(page, params, check_status);
                                                    } else {
                                                        message.error("下线失败");
                                                    }
                                                },
                                                onCancel() {
                                                    console.log("Cancel");
                                                },
                                            });
                                        }}
                                    >
                                        下线
                                    </Button>}
                                </div>
                            )
                        }}
                    />
                </Table>
                {totalCount > 0 && (
                    <Row>
                        <Col xs={0} ms={24} md={24} lg={24} xl={24} xxl={24}>
                            <div className="tab-pages">
                                <Pagination
                                    pageSizeOptions={["10"]}
                                    pageSize={10}
                                    defaultCurrent={1}
                                    current={page}
                                    total={totalCount}
                                    onChange={(e) => {
                                        // setPage(e);
                                        search(e, params, check_status);
                                    }}
                                />
                            </div>
                        </Col>
                    </Row>
                )}
            </div>
        </div>
    )
}

export default InternetHospital