import React, {useState, useEffect, useRef} from "react";
import {Breadcrumb, Button, Cascader, Form, Input, Select, Upload, message} from "antd";

import "../../static/css/pages/article/add.css";
import {areasArray, findValue, findLabel} from "../../untils/citydata";
import {getToken} from "../../untils/tokenHelper";
import {GetUploadUrl} from "../../conf/app";
import {UploadOutlined} from "@ant-design/icons";
import DetailModal from "../../models/covid19/povEdit"

const {Option} = Select

function PovEdit(props) {
    const [detail, setDetail] = useState(() => {
        const obj = JSON.parse(props.location.state.caseInfo);
        obj.location = findValue([obj.province, obj.city, obj.district]);
        return obj
    });


    const formRef = useRef();
    const formItemLayout = {
        labelCol: {span: 4},
        wrapperCol: {span: 18},
    };

    const {
        save,
        fileList,
        setFileList,
        imgUrl,
        setImgUrl,
    } = DetailModal()

    useEffect(() => {
        setFileList([]);
        if (detail.pov_logo) {
            setImgUrl(detail.pov_logo);
            setFileList([
                {
                    uid: "-1",
                    name: "image",
                    status: "done",
                    url: detail.pov_logo,
                },
            ]);
        } else {
            setFileList([]);
            setImgUrl();
        }
    }, [])

    return (
        <div>
            <Breadcrumb style={{padding: "16px", background: "#F5F5F5"}}>
                <Breadcrumb.Item>新冠疫苗专题管理</Breadcrumb.Item>
                <Breadcrumb.Item>POV管理</Breadcrumb.Item>
            </Breadcrumb>
            <div  className="main-add">
                <Form
                    ref={formRef}
                    {...formItemLayout}
                    onFinish={""}
                >
                    <Form.Item
                        label="机构名"
                        name="pov_name"
                        initialValue={detail.pov_name}
                        rules={[
                            {
                                required: true,
                                message: "请填写标题",
                                type: "string",
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="location"
                        initialValue={detail.location}
                        label="省份/城市/区（县）"
                        rules={[
                            {
                                required: true,
                                message: "请选择地方",
                            },
                        ]}
                    >
                        <Cascader  style={{width:240}} options={areasArray}/>
                    </Form.Item>
                    <Form.Item
                        name="is_crash"
                        initialValue={detail.is_crash}
                        label="是否是紧急使用接种点"
                        rules={[
                            {
                                required: true,
                                message: "请选择地方",
                            },
                        ]}
                    >
                        <Select
                            placeholder="请选择"
                            style={{width: "240px"}}
                        >
                            <Option value={1}>未知</Option>
                            <Option value={2}>是</Option>
                            <Option value={3}>否</Option>
                        </Select>
                    </Form.Item>
                    <Form.Item
                        label="机构电话"
                        name="phone_num"
                        initialValue={detail.phone_num}
                        rules={[
                            {
                                required: true,
                                message: "请填写标题",
                                type: "string",
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="负责人"
                        name="principal"
                        initialValue={detail.principal}
                        rules={[
                            {
                                message: "请填写标题",
                                type: "string",
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="地址"
                        name="adrress"
                        initialValue={detail.adrress}
                        rules={[
                            {
                                message: "请填写标题",
                                type: "string",
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        label="开诊时间"
                        name="open_time"
                        initialValue={detail.open_time}
                        rules={[
                            {
                                message: "请填写标题",
                                type: "string",
                            },
                        ]}
                    >
                        <Input/>
                    </Form.Item>
                    <Form.Item
                        name="picture"
                        label="机构图片"
                    >
                        <Upload
                            fileList={fileList}
                            headers={{token: getToken()}}
                            name="file"
                            method="POST"
                            listType="picture-card"
                            className="avatar-uploader"
                            action={GetUploadUrl()}
                            onChange={(e) => {
                                setFileList(e.fileList)
                                try {
                                    let ret = e.fileList[0].response;
                                    if (ret.code == 0) {
                                        setImgUrl(ret.data)
                                    } else {
                                        message.error("图片上传失败");
                                    }
                                } catch (ex) {
                                    // message.error("图片上传失败")
                                }
                            }}
                            onPreview={async (file) => {
                                let src = file.url;
                                if (!src) {
                                    src = await new Promise((resolve) => {
                                        const reader = new FileReader();
                                        reader.readAsDataURL(file.originFileObj);
                                        reader.onload = () => resolve(reader.result);
                                    });
                                }
                                const image = new Image();
                                image.src = src;
                                const imgWindow = window.open(src);
                                imgWindow.document.write(image.outerHTML);
                            }}
                            onRemove={(e) => {
                                setImgUrl("")
                                setFileList([])
                            }}
                        >
                            {!imgUrl && (
                                <div className={"disableupload"}>
                                    <UploadOutlined/>
                                    <div className="ant-upload-text">上传</div>
                                </div>
                            )}
                        </Upload>
                    </Form.Item>
                </Form>
                <div style={{paddingLeft: 180, marginBottom: 40, marginTop: 20}}>
                    <Button
                        style={{marginRight: 10}}
                        type="primary"
                        onClick={() => {
                            formRef.current.validateFields().then(async val => {
                                if (imgUrl) {
                                    val.pov_logo = imgUrl;
                                }
                                const labelList = findLabel(val.location)
                                val.province = labelList[0];
                                val.city = labelList[1];
                                val.district = labelList[2];
                                const id = await save(val, detail.id);
                                if(id !== false){
                                    val.id = id;
                                    setDetail({
                                        ...detail,
                                        id: id
                                    })
                                    props.history.push({
                                        pathname: "/main/povedit",
                                        state: {caseInfo: JSON.stringify(val)}
                                    })
                                }
                            }).catch(error => {
                                message.error("请填写必要信息！");
                            })
                        }}
                    >
                        保存
                    </Button>
                </div>
            </div>
        </div>
    )
}

export default PovEdit